import type vtkActor from '@kitware/vtk.js/Rendering/Core/Actor';

import {cornerstone} from '@/library';
import {type VtkState} from '@/types';

export function getMeshActor({
	id,
	vtkState,
}: {
	id: string;
	vtkState: VtkState;
}): vtkActor {
	const actor = vtkState.meshActors.find((mesh) => mesh.id === id)?.actor;

	if (!actor) throw new Error(`Failed to find mesh actor entry`);

	return actor;
}

export function setVisibility({
	id,
	visibility,
	vtkState,
}: {
	id: string;
	visibility: boolean;
	vtkState: VtkState;
}): void {
	getMeshActor({id, vtkState}).setVisibility(visibility);
	cornerstone.renderViewports('volume');
}
