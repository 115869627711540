import Color from 'colorjs.io';

import {type PointCloudColorScheme} from '@/library';

export type CheckboxColors = {
	background: {active: string; hover: string; normal: string};
	foreground: string;
};

/**
 * Given a single color, generate accessible background and foreground colors
 * for styling a checkbox
 *
 * @param {Color} color - Color.js `Color` instance
 */
export function getCheckboxColors(color: Color): CheckboxColors {
	/** Copy of `color` with low lightness */
	const darkColor = color.clone().set('hsl.l', 5);
	/** Copy of `color` with high lightness */
	const lightColor = color.clone().set('hsl.l', 95);

	// Color is dark if contrast with light foreground is better than contrast
	// with dark foreground
	const isColorDark =
		Math.abs(color.contrastAPCA(lightColor)) >
		Math.abs(color.contrastAPCA(darkColor));

	const backgroundHoverColor = color
		.clone()
		.set('hsl.l', (l) => l + (isColorDark ? -10 : 10));
	const backgroundActiveColor = backgroundHoverColor
		.clone()
		.set('hsl.l', (l) => l + (isColorDark ? -10 : 10));
	const foregroundColor = isColorDark ? lightColor : darkColor;

	return {
		background: {
			active: backgroundActiveColor.toString(),
			hover: backgroundHoverColor.toString(),
			normal: color.toString(),
		},
		foreground: foregroundColor.toString(),
	};
}

export function pointCloudColorsToCssGradient({
	angle,
	colorScheme,
}: {
	angle: string;
	colorScheme: PointCloudColorScheme;
}): string {
	const colorStops: string = colorScheme.colors
		.map(({percentage, color: rgb}) => {
			const color = new Color('sRGB', rgb).toString();

			percentage *= 100;

			return `${color} ${percentage}%`;
		})
		.join(', ');

	return `linear-gradient(${angle}, ${colorStops})`;
}
