import {type ViewportElementKey} from './viewport-element-keys';

export type Elements = Record<ViewportElementKey, HTMLDivElement | undefined>;

export function areElementsDefined() {
	return Object.values(elements).every((element) => element !== undefined);
}

export const elements: Elements = {
	axial: undefined,
	coronal: undefined,
	sagittal: undefined,
	volume: undefined,
};
