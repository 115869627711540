import {useDensityMapStore} from '@/state/density-map';
import {useResectionPlanesStore} from '@/state/resection-planes';

import {isDefined} from '@/library';
import {type ResectionPlanesState} from '@/types';

import {type Settings} from './settings';

let initialDensityMapThreshold: {lower: number; upper: number};
let initialResectionPlanesAdjustments: ResectionPlanesState;

export default function getDefaultSettings(): Settings | undefined {
	const densityMap = useDensityMapStore.getState();
	const resectionPlanes = useResectionPlanesStore.getState();

	if (densityMap.state !== 'ready' || resectionPlanes.state !== 'ready') return;

	if (!isDefined(initialDensityMapThreshold)) {
		initialDensityMapThreshold = {
			lower: densityMap.threshold.lower,
			upper: densityMap.threshold.upper,
		};
	}

	if (!isDefined(initialResectionPlanesAdjustments)) {
		initialResectionPlanesAdjustments = resectionPlanes.adjustments;
	}

	return {
		densityMap: {
			colorScheme: 'nativeBone',
			opacity: 100,
			threshold: {
				lower: initialDensityMapThreshold.lower,
				upper: initialDensityMapThreshold.upper,
			},
		},
		resectionPlanes: {
			pair: 'anatomical',
			plane: 'femoral',
			adjustments: {
				anatomical: {
					femoral: {
						position: {
							z:
								initialResectionPlanesAdjustments.anatomical.femoral.position
									.z ?? 0,
						},
					},
					tibial: {
						position: {
							z:
								initialResectionPlanesAdjustments.anatomical.tibial.position
									.z ?? 0,
						},
					},
				},
				kinematic: {
					femoral: {
						position: {
							z:
								initialResectionPlanesAdjustments.kinematic.femoral.position
									.z ?? 0,
						},
					},
					tibial: {
						position: {
							z:
								initialResectionPlanesAdjustments.kinematic.tibial.position.z ??
								0,
						},
					},
				},
				mechanical: {
					femoral: {
						position: {
							z:
								initialResectionPlanesAdjustments.mechanical.femoral.position
									.z ?? 0,
						},
					},
					tibial: {
						position: {
							z:
								initialResectionPlanesAdjustments.mechanical.tibial.position
									.z ?? 0,
						},
					},
				},
			},
		},
		visibility: {
			landmarks: true,
			meshes: true,
			pointClouds: true,
			resectionPlanes: true,
			segmentationOverlays: true,
		},
	};
}
