// @ts-nocheck

import {utilities as csUtils} from '@cornerstonejs/core';

const scalingPerImageId = {};

function addInstance(imageId, scalingMetaData) {
	const imageUri = csUtils.imageIdToURI(imageId);
	scalingPerImageId[imageUri] = scalingMetaData;
}

function get(type, imageId) {
	if (type === 'scalingModule') {
		const imageUri = csUtils.imageIdToURI(imageId);
		return scalingPerImageId[imageUri];
	}
}

export default {addInstance, get};
