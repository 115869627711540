import {
	calculateSUVScalingFactors,
	type InstanceMetadata,
} from '@cornerstonejs/calculate-suv';
import {utilities} from '@cornerstonejs/core';
import {assert} from '@sindresorhus/is';
import cornerstoneWADOImageLoader from 'cornerstone-wado-image-loader';
import {api} from 'dicomweb-client';
import dcmjs from 'dcmjs';

import getPixelSpacingInformation from './getPixelSpacingInformation';
import {getPtImageIdInstanceMetadata} from './getPtImageIdInstanceMetadata';
import ptScalingMetaDataProvider from './ptScalingMetaDataProvider';
import WADORSHeaderProvider from './WADORSHeaderProvider';

/* eslint-disable-next-line @typescript-eslint/naming-convention --
 * we don't have control over this
 */
const {DicomMetaDictionary} = dcmjs.data;
const {calibratedPixelSpacingMetadataProvider} = utilities;

/**
 * Uses dicomweb-client to fetch metadata of a study, cache it in cornerstone,
 * and return a list of imageIds for the frames.
 *
 * Uses the app config to choose which study to fetch, and which
 * dicom-web server to fetch it from.
 *
 * @returns {Promise<string[]>} An array of imageIds for instances in the study.
 */
export default async function createImageIdsAndCacheMetaData({
	seriesInstanceUid,
	studyInstanceUid,
}: {
	seriesInstanceUid: string;
	studyInstanceUid: string;
}): Promise<string[]> {
	const wadoRsRoot = `${window.location.origin}/dcm4chee-arc/aets/DCM4CHEE/rs`;

	try {
		const client = new api.DICOMwebClient({
			// Set to default
			// See https://github.com/dcmjs-org/dicomweb-client/blob/v0.10.4/src/api.js#L120
			singlepart: '',
			url: wadoRsRoot,
		});
		const instances = await client.retrieveSeriesMetadata({
			/* eslint-disable @typescript-eslint/naming-convention --
			 * we don't have control over this
			 */
			seriesInstanceUID: seriesInstanceUid,
			studyInstanceUID: studyInstanceUid,
			// eslint-enable
		});

		const imageIds = instances.map((instanceMetadata) => {
			const seriesInstanceUid = instanceMetadata['0020000E']?.Value?.[0];
			const sopInstanceUid = instanceMetadata['00080018']?.Value?.[0];

			assert.string(seriesInstanceUid);
			assert.string(sopInstanceUid);

			const prefix = 'wadors:';

			const imageId =
				prefix +
				wadoRsRoot +
				'/studies/' +
				studyInstanceUid +
				'/series/' +
				seriesInstanceUid +
				'/instances/' +
				sopInstanceUid +
				'/frames/1';

			cornerstoneWADOImageLoader.wadors.metaDataManager.add(imageId, {
				...instanceMetadata,
			});

			WADORSHeaderProvider.addInstance(imageId, {...instanceMetadata});

			// Add calibrated pixel spacing
			const m = JSON.parse(JSON.stringify(instanceMetadata));
			const instance = DicomMetaDictionary.naturalizeDataset(m);
			const pixelSpacing = getPixelSpacingInformation(instance);

			if (!pixelSpacing) {
				throw new Error('Failed to get pixel spacing information');
			}

			let arrayToMap;

			if (Array.isArray(pixelSpacing)) {
				arrayToMap = pixelSpacing;
			} else if (
				typeof pixelSpacing === 'object' &&
				pixelSpacing.PixelSpacing
			) {
				arrayToMap = pixelSpacing.PixelSpacing;
			}

			if (arrayToMap && arrayToMap.length === 2) {
				calibratedPixelSpacingMetadataProvider.add(
					imageId,
					arrayToMap as [number, number],
				);
			}

			return imageId;
		});

		const modality = instances[0]['00080060']?.Value?.[0];

		assert.string(modality);

		// We don't want to add non-pet
		// Note: for 99% of scanners SUV calculation is consistent bw slices
		if (modality === 'PT') {
			const instanceMetadataArray: InstanceMetadata[] = [];

			imageIds.forEach((imageId: string) => {
				const instanceMetadata = getPtImageIdInstanceMetadata(imageId);

				// TODO: Temporary fix because static-wado is producing a string, not an array of values
				// (or maybe dcmjs isn't parsing it correctly?)
				// It's showing up like 'DECY\\ATTN\\SCAT\\DTIM\\RAN\\RADL\\DCAL\\SLSENS\\NORM'
				// but calculate-suv expects ['DECY', 'ATTN', ...]
				if (typeof instanceMetadata.CorrectedImage === 'string') {
					instanceMetadata.CorrectedImage =
						instanceMetadata.CorrectedImage.split('\\');
				}

				if (instanceMetadata) {
					instanceMetadataArray.push(instanceMetadata);
				}
			});
			if (instanceMetadataArray.length) {
				const suvScalingFactors = calculateSUVScalingFactors(
					instanceMetadataArray,
				);
				instanceMetadataArray.forEach((instanceMetadata, index) => {
					ptScalingMetaDataProvider.addInstance(
						imageIds[index],
						suvScalingFactors[index],
					);
				});
			}
		}

		return imageIds;
	} catch (error) {
		console.error('Unable to create image ids and cache metadata');
		console.error(error);

		return [];
	}
}
