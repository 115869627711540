import {metaData, utilities} from '@cornerstonejs/core';

import ptScalingMetaDataProvider from './ptScalingMetaDataProvider';
import WADORSHeaderProvider from './WADORSHeaderProvider';

const {calibratedPixelSpacingMetadataProvider} = utilities;

export default function initProviders() {
	metaData.addProvider(
		WADORSHeaderProvider.get.bind(WADORSHeaderProvider),
		9999,
	);
	metaData.addProvider(
		ptScalingMetaDataProvider.get.bind(ptScalingMetaDataProvider),
		10000,
	);
	metaData.addProvider(
		calibratedPixelSpacingMetadataProvider.get.bind(
			calibratedPixelSpacingMetadataProvider,
		),
		11000,
	);
}
