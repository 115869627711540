import {Button, type ButtonProps, Link, type LinkProps} from '@mui/joy';
import React from 'react';

export type Props = (
	| ({
			component: 'button';
	  } & ButtonProps)
	| ({component: 'link'} & LinkProps)
) & {capitalize?: boolean};

export default function ContactVent({capitalize, component, ...props}: Props) {
	let c = 'c';
	capitalize ??= component === 'button';
	if (capitalize) c = 'C';

	const Component = (
		component === 'button' ? Button : Link
	) as React.ComponentType<ButtonProps | LinkProps>;

	return (
		<Component component="a" href="/contact" {...props}>
			{c}ontact Vent
		</Component>
	);
}
