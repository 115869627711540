import {
	Button,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	Modal,
	ModalDialog,
	Stack,
	Typography,
} from '@mui/joy';
import is from '@sindresorhus/is';
import {SettingsIcon} from 'lucide-react';
import React, {useEffect} from 'react';

import {Alert} from '@/components';
import {useCustomDefaultsState} from '@/hooks';
import {isDefined, settings} from '@/library';
import {useGlobalState} from '@/state';
import {customDefaultsXstateActor as xstateActor} from '@/state/machines/custom-defaults';

import {Diff} from './components';

export default function CustomDefaults() {
	const state = useCustomDefaultsState();
	const {tools} = useGlobalState();

	const diff = settings.customDefault.getDiff();

	useEffect(() => {
		if (
			isDefined(diff) &&
			is.nonEmptyObject(diff) &&
			state === 'save succeeded'
		) {
			xstateActor.send({type: 'continue'});
		}
	}, [diff]);

	return (
		<>
			{state !== 'inactive' && (
				<Alert color="primary" startDecorator={<SettingsIcon />}>
					<Stack spacing={2}>
						<Typography level="title-lg">
							Editing Custom Default Settings
						</Typography>

						{!isDefined(tools.active) && (
							<Typography>
								Select a tool in the toolbar to edit its custom default
								settings.
							</Typography>
						)}

						<Diff />

						{state === 'save failed' && (
							<Alert color="danger" showIcon>
								Failed to save
							</Alert>
						)}

						{state === 'save succeeded' && (
							<Alert color="success" showIcon>
								Saved
							</Alert>
						)}

						<Stack direction="row" justifyContent="flex-end" spacing={2}>
							{state === 'save succeeded' ? (
								<Button
									onClick={() => {
										xstateActor.send({type: 'deactivate'});
									}}
								>
									Close
								</Button>
							) : (
								<>
									<Button
										onClick={() => {
											xstateActor.send({type: 'deactivate'});
										}}
										variant="outlined"
									>
										Cancel
									</Button>
									<Button
										disabled={
											!isDefined(diff) || Object.keys(diff).length === 0
										}
										loading={state === 'saving'}
										onClick={async () => {
											xstateActor.send({type: 'save'});

											try {
												await settings.customDefault.save();
												xstateActor.send({type: 'succeed'});
											} catch (error) {
												console.error(error);
												xstateActor.send({type: 'fail'});
											}
										}}
									>
										{state === 'save failed' ? 'Retry' : 'Save'}
									</Button>
								</>
							)}
						</Stack>
					</Stack>
				</Alert>
			)}

			{/* Info modal */}
			<Modal
				onClose={() => {
					xstateActor.send({type: 'cancel'});
				}}
				open={state === 'instructing'}
			>
				<ModalDialog>
					<DialogTitle>
						<SettingsIcon />
						Editing Custom Defaults
					</DialogTitle>

					<Divider />

					<DialogContent>
						<Typography>
							In this mode, adjustments you make to the current scan&rsquo;s
							visibility, resection planes, or density map can be saved as your
							Custom Default Settings, which will be applied when you load other
							scans.
						</Typography>
					</DialogContent>

					<DialogActions>
						<Button
							onClick={() => {
								xstateActor.send({type: 'continue'});
							}}
						>
							Continue
						</Button>
						<Button
							onClick={() => {
								xstateActor.send({type: 'cancel'});
							}}
							variant="outlined"
						>
							Cancel
						</Button>
					</DialogActions>
				</ModalDialog>
			</Modal>
		</>
	);
}
