import {type Scan} from '@/library/models';
import {type VtkStateRef} from '@/types';

import {useSetAdjustments, useSetPair, useSetPlane} from './hooks';

export default function useSetResectionPlanes({
	scan,
	vtkState,
}: {
	scan: Scan;
	vtkState: VtkStateRef;
}) {
	useSetAdjustments({scan, vtkState});
	useSetPair({vtkState});
	useSetPlane({vtkState});
}
