import {create} from 'zustand';
import {immer} from 'zustand/middleware/immer';

import {type SidePanelTool} from '@/library';

type LassoMode = 'multi' | 'single';

type State = {
	active: SidePanelTool | undefined;
	lasso: {
		enabled: boolean;
		mode: LassoMode;
		setEnabled: (isActive: boolean) => void;
		setMode: (mode: LassoMode) => void;
	};
	setActive: (tool: SidePanelTool | undefined) => void;
};

export const useToolsStore = create<State>()(
	immer((set) => ({
		active: undefined,
		setActive(tool) {
			set((state) => {
				state.active = tool;
			});
		},
		lasso: {
			enabled: false,
			mode: 'single',
			setEnabled(isActive) {
				set((state) => {
					state.lasso.enabled = isActive;
				});
			},
			setMode(mode) {
				set((state) => {
					state.lasso.mode = mode;
				});
			},
		},
	})),
);
