import {useEffect} from 'react';

import {
	ActorEntry,
	getCircumscribedBounds,
	setIsometricProjectionBy,
} from '@/library';
import {useGlobalState} from '@/state';
import {type Bounds, type VtkStateRef} from '@/types';

import {type Scan} from '../library/models';
import dracoPointCloudReader from '../utils/dracoPointCloudReader';

function useLoadPointClouds({
	scan,
	vtkState,
}: {
	scan: Scan;
	vtkState: VtkStateRef;
}) {
	const {
		densityMap,
		scan: {auxPointCloudObjects},
		viewports: {volumeViewport},
	} = useGlobalState();

	useEffect(() => {
		const loadAndSetPointCloudActors = async () => {
			if (
				auxPointCloudObjects.length === 0 ||
				densityMap.state !== 'loading' ||
				!scan.hasReachedMilestone('kneeSegmented') ||
				volumeViewport === undefined
			) {
				return;
			}

			// Extracting all the files into a single array
			const allFiles = auxPointCloudObjects.map((obj) => obj.file);

			// Feed all files into dracoPointCloudReader at once
			const {actors, backupActors, range, lut} =
				await dracoPointCloudReader(allFiles);

			if (!actors.length || !backupActors.length) {
				return;
			}

			let outerBounds: Bounds = actors[0].getBounds();
			const pointCloudActors = [];

			for (let index = 0; index < actors.length; index++) {
				const actor = actors[index];
				outerBounds = getCircumscribedBounds(outerBounds, actor.getBounds());

				const auxObject = auxPointCloudObjects[index];

				const actorEntry = new ActorEntry(actor, auxObject.id, auxObject.label);
				vtkState.current.pointCloudActors.push(actorEntry);
				pointCloudActors.push({uid: actorEntry.id, actor: actorEntry.actor});

				const backupActorEntry = new ActorEntry(
					backupActors[index],
					auxObject.id,
					auxObject.label,
				);
				vtkState.current.pointCloudActorsBackup.push(backupActorEntry);
			}

			volumeViewport.addActors(pointCloudActors);
			setIsometricProjectionBy(volumeViewport, outerBounds);
			volumeViewport.render();
			densityMap.setPointClouds(
				vtkState.current.pointCloudActors.map(({actor, id, label}) => ({
					id,
					isEditing: false,
					isVisible: actor.getVisibility(),
					label,
				})),
			);
			densityMap.updateThreshold({
				lut,
				lower: range.min,
				max: range.max,
				min: range.min,
				upper: range.max,
			});
			densityMap.setState('ready');
		};

		loadAndSetPointCloudActors().catch(console.error);
	}, [auxPointCloudObjects, densityMap.state, scan.state, volumeViewport]);
}

export default useLoadPointClouds;
