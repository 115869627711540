import {
	Button,
	Card,
	type ColorPaletteProp,
	Container,
	FormControl,
	FormLabel,
	Input,
	Stack,
	Textarea,
	Typography,
} from '@mui/joy';
import React, {useState} from 'react';
import {useForm} from 'react-hook-form';
import {useImmer} from 'use-immer';

import {Snackbar} from '@/components';
import {useFirebaseAuthState, useTitle} from '@/hooks';
import {api} from '@/library';

export default function Contact() {
	useTitle('Contact');

	const [firebaseAuthUser, isFirebaseAuthUserLoading] = useFirebaseAuthState();
	const form = useForm<{message: string; subject: string}>();
	const [snackbar, updateSnackbar] = useImmer<{
		color?: ColorPaletteProp;
		message?: string;
		open: boolean;
	}>({open: false});
	const [submitting, setSubmitting] = useState<boolean>(false);

	function closeSnackbar() {
		updateSnackbar({open: false});
	}

	const handleSubmit = form.handleSubmit(async ({message, subject}) => {
		if (!firebaseAuthUser) {
			throw new Error('User is not authenticated');
		}

		setSubmitting(true);

		const slackMessage = `--------------------------------\n
New Contact Message from: ${firebaseAuthUser.email ?? firebaseAuthUser.uid}\n
Subject: ${subject}\n
Message: ${message}`;

		try {
			if (!process.env.SLACK_CONTACT_WEBHOOK) {
				throw new Error('SLACK_CONTACT_WEBHOOK is not defined');
			}

			await api.slack.post({
				webhook: process.env.SLACK_CONTACT_WEBHOOK,
				message: slackMessage,
			});

			updateSnackbar({
				color: 'success',
				message: 'Message sent successfully!',
				open: true,
			});
		} catch (err) {
			updateSnackbar({
				color: 'danger',
				message: 'Error sending message!',
				open: true,
			});
			console.error(err);
		} finally {
			setSubmitting(false);
		}
	});

	if (isFirebaseAuthUserLoading) {
		return;
	}

	return (
		<>
			<Snackbar
				color={snackbar.color}
				onClose={closeSnackbar}
				open={snackbar.open}
				showIcon
			>
				{snackbar.message}
			</Snackbar>

			<Container maxWidth="xs">
				<Card>
					<Stack spacing={2}>
						<Typography level="title-lg">Contact Vent Creativity</Typography>

						<Stack component="form" onSubmit={handleSubmit} spacing={2}>
							<FormControl disabled={submitting} required>
								<FormLabel>Subject</FormLabel>
								<Input {...form.register('subject')} />
							</FormControl>

							<FormControl disabled={submitting} required>
								<FormLabel>Message</FormLabel>
								<Textarea minRows={2} {...form.register('message')} />
							</FormControl>

							<Button
								loading={submitting}
								loadingPosition="start"
								sx={{alignSelf: 'flex-start'}}
								type="submit"
							>
								{submitting ? 'Submitting' : 'Submit'}
							</Button>
						</Stack>
					</Stack>
				</Card>
			</Container>
		</>
	);
}
