import type vtkActor from '@kitware/vtk.js/Rendering/Core/Actor';

export default class ActorEntry {
	/* eslint-disable @typescript-eslint/parameter-properties --
	 * our babel setup doesn't support this
	 */
	public actor: vtkActor;
	public id: string;
	public label: string;
	// eslint-enable

	constructor(actor: vtkActor, id: string, label: string) {
		this.actor = actor;
		this.id = id;
		this.label = label;
	}
}
