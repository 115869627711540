import {
	AlertOctagonIcon,
	AlertTriangleIcon,
	CheckCircleIcon,
	InfoIcon,
	Loader2Icon as LoaderIcon,
	type LucideIcon,
	type LucideProps,
} from 'lucide-react';
import React from 'react';

export type Props = LucideProps & {
	loading?: boolean;
};

/**
 * Renders a [Lucide]{@link https://lucide.dev} icon based on the
 * `color` prop
 */
export default function StatusIcon({
	className,
	color,
	loading,
	...props
}: Props) {
	let Icon: LucideIcon;

	if (loading) Icon = LoaderIcon;
	else if (color === 'danger') Icon = AlertOctagonIcon;
	else if (color === 'info') Icon = InfoIcon;
	else if (color === 'success') Icon = CheckCircleIcon;
	else if (color === 'warning') Icon = AlertTriangleIcon;
	else return null;

	return <Icon className={loading ? 'animate-spin' : undefined} {...props} />;
}
