import {ListItem, Tooltip} from '@mui/joy';
import React from 'react';

import {
	AppBarListItemButton,
	type AppBarListItemButtonProps,
} from '@/components';

type Props = AppBarListItemButtonProps & {
	disabled?: boolean;
	title: string;
};

export default function Item({disabled, title, ...props}: Props) {
	return (
		<Tooltip color="primary" title={title}>
			<ListItem>
				<AppBarListItemButton
					sx={[
						{
							borderRadius: 'sm',
						},
						disabled
							? {
									color: 'primary.800',
									// Hover
									'&:not(.Mui-selected, [aria-selected="true"]):hover': {
										backgroundColor: 'primary.900',
									},
							  }
							: {
									// Brighter highlight for selected tool
									'&.Mui-selected': {
										backgroundColor: 'primary.500',
									},
							  },
					]}
					{...props}
				/>
			</ListItem>
		</Tooltip>
	);
}
