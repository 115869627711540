import {useDocumentData} from 'react-firebase-hooks/firestore';

import {firestore} from '@/library/firebase';
import {type User} from '@/library/models';

export default function useUser({
	id,
}: {
	id?: string;
}): ReturnType<typeof useDocumentData<User>> {
	const docRef = id ? firestore.getUserDocumentReference({id}) : undefined;

	return useDocumentData<User>(docRef);
}
