import {
	FileIcon as LucideFileIcon,
	FolderArchiveIcon,
	type LucideIcon,
	type LucideProps,
} from 'lucide-react';
import React from 'react';

export type Props = LucideProps & {
	file: File;
};

/**
 * Renders a [Lucide]{@link https://lucide.dev} icon based on the file type
 */
export default function FileIcon({file, ...props}: Props) {
	let Icon: LucideIcon;

	if (file.type === 'application/zip') Icon = FolderArchiveIcon;
	else Icon = LucideFileIcon;

	return <Icon {...props} />;
}
