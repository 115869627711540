import {segmentation} from '@cornerstonejs/tools';

import {cornerstone} from '@/library';

export function setVisibility(visibility: boolean) {
	const config = segmentation.config.getGlobalConfig();

	if (config.representations.LABELMAP) {
		config.representations.LABELMAP.renderFill = visibility;
		config.representations.LABELMAP.renderOutline = visibility;
	}

	segmentation.config.setGlobalConfig(config);

	cornerstone.renderViewports('2d');
}
