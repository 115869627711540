import delay from 'delay';
import {useEffect, useState} from 'react';

import {isDefined, updateResectionPlanesVisibility} from '@/library';
import {useGlobalState} from '@/state';
import {type VtkStateRef} from '@/types';

export default function useSetResectionPlanesVisibility({
	vtkState,
}: {
	vtkState: VtkStateRef;
}) {
	const {
		resectionPlanes,
		user: {user},
		visibility: globalVisibility,
	} = useGlobalState();
	const [applied, setApplied] = useState<boolean>(false);

	async function setVisibility(visibility: boolean) {
		resectionPlanes.setState('updating');

		// Render
		await delay(0);

		updateResectionPlanesVisibility({
			areDigitalTwinsVisible: globalVisibility.digitalTwins,
			isVisible: visibility,
			selectedPair: resectionPlanes.selectedPairKey,
			vtkState,
		});

		resectionPlanes.setVisibility(visibility);
		resectionPlanes.setState('ready');
	}

	useEffect(() => {
		async function apply() {
			if (applied || resectionPlanes.state !== 'ready' || !isDefined(user)) {
				return;
			}

			const visibility = user.customDefaults?.visibility?.resectionPlanes;

			if (isDefined(visibility) && visibility !== resectionPlanes.visibility) {
				await setVisibility(visibility);
			}

			setApplied(true);
		}

		apply().catch(console.error);
	}, [applied, resectionPlanes.state, user]);
}
