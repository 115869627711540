import makeIcon from './make-icon';
export type {Icon} from './make-icon';

// SVG files get converted to React components with SVGR + Webpack
// @ts-expect-error
import DensitySvg from './svgs/density.svg';
// @ts-expect-error
import DigitalTwinSvg from './svgs/digital-twin.svg';
// @ts-expect-error
import LassoModeMultipleSvg from './svgs/lasso-mode-multiple.svg';
// @ts-expect-error
import ResectionRemainingSvg from './svgs/resection-remaining.svg';
// @ts-expect-error
import ResectionRemovedSvg from './svgs/resection-removed.svg';
// @ts-expect-error
import SphereSvg from './svgs/sphere.svg';

// Custom icons
/* eslint-disable @typescript-eslint/naming-convention */
export const DensityIcon = makeIcon({
	name: 'density',
	Svg: DensitySvg,
});
export const DigitalTwinIcon = makeIcon({
	name: 'digital-twin',
	Svg: DigitalTwinSvg,
});
export const LassoModeMultipleIcon = makeIcon({
	name: 'lasso-mode-multiple',
	Svg: LassoModeMultipleSvg,
});
export const ResectionRemainingIcon = makeIcon({
	name: 'resection-remaining',
	Svg: ResectionRemainingSvg,
});
export const ResectionRemovedIcon = makeIcon({
	name: 'resection-removed',
	Svg: ResectionRemovedSvg,
});
export const SphereIcon = makeIcon({
	name: 'sphere',
	Svg: SphereSvg,
});
/* eslint-enable */

// Lucide icon aliases
export {
	RulerIcon as AnnotationsIcon,
	CircleIcon as LassoModeSingleIcon,
	Layers2Icon as ResectionPlanesIcon,
	EyeIcon as VisibilityIcon,
} from 'lucide-react';
