/**
 * Concatenates two Float32Array
 */
export default function float32Concat(
	first: Float32Array,
	second: Float32Array,
): Float32Array {
	const firstLength = first.length;
	const result = new Float32Array(firstLength + second.length);
	result.set(first);
	result.set(second, firstLength);

	return result;
}
