// @ts-nocheck
import {getEnabledElement} from '@cornerstonejs/core';
import {
	BaseTool,
	annotation,
	utilities,
	Enums,
	drawing,
} from '@cornerstonejs/tools';

import {cornerstone} from '@/library';

const {drawPolyline} = drawing;
/* eslint-disable-next-line @typescript-eslint/naming-convention --
 * we don't have control over this
 */
const {Events} = Enums;
const {addAnnotation, getAnnotations} = annotation.state;
const {triggerAnnotationRenderForViewportIds} = utilities;

/**
 * Tool for selecting cloud of points in a 3D view
 */

export default class CloudLassoTool extends BaseTool {
	static toolName = 'CloudLasso';
	editData = {
		annotation: undefined,
	};

	constructor(
		toolProps = {},
		defaultToolProps = {
			supportedInteractionTypes: ['Mouse'],
		},
	) {
		super(toolProps, defaultToolProps);
	}

	addNewAnnotation = (evt) => {
		const eventDetail = evt.detail;
		const {element} = eventDetail;
		const enabledElement = getEnabledElement(element);
		/* eslint-disable-next-line @typescript-eslint/naming-convention --
		 * we don't have control over this
		 */
		const {FrameOfReferenceUID, renderingEngine} = enabledElement;

		const annotation = {
			metadata: {
				/* eslint-disable-next-line @typescript-eslint/naming-convention --
				 * we don't have control over this
				 */
				FrameOfReferenceUID,
				toolName: this.getToolName(),
				skipPoint: 0,
			},
			data: {
				polylinePoints: [], // Polyline coordinates
				label: '',
			},
		};

		addAnnotation(element, annotation);

		this.editData = {
			annotation,
		};

		this._activateDraw(element);

		evt.preventDefault();

		triggerAnnotationRenderForViewportIds(renderingEngine, [
			cornerstone.viewportIds.volume,
		]);

		return annotation;
	};

	getHandleNearImagePoint = (element, annotation, canvasCoords, proximity) =>
		false;

	isPointNearTool = (
		element,
		annotation,
		canvasCoords,
		proximity,
		interactionType,
	) => false;

	_mouseUpCallback = (evt) => {
		const eventDetail = evt.detail;
		const {element} = eventDetail;

		this._deactivateDraw(element);

		// We remove it in the Lasso component after using the selection
		// it is not clean, but is there another way?
		// removeAnnotation(annotation.annotationUID, element);

		this.editData = null;
	};

	_mouseDragCallback = (evt) => {
		const eventDetail = evt.detail;
		const {element} = eventDetail;

		const {annotation} = this.editData;
		const {data, metadata} = annotation;

		if (metadata.skipPoint !== 3) {
			metadata.skipPoint++;
			return;
		}

		metadata.skipPoint = 0;

		const {currentPoints} = eventDetail;
		const canvasPos = currentPoints.canvas;

		data.polylinePoints.push(canvasPos);

		const enabledElement = getEnabledElement(element);
		const {renderingEngine} = enabledElement;

		triggerAnnotationRenderForViewportIds(renderingEngine, [
			cornerstone.viewportIds.volume,
		]);
	};

	renderAnnotation = (enabledElement, svgDrawingHelper) => {
		const {viewport} = enabledElement;
		const {element} = viewport;
		const annotations = getAnnotations(element, this.getToolName());

		if (!annotations?.length) {
			return;
		}

		// Draw SVG
		for (const annotation of annotations) {
			/* eslint-disable-next-line @typescript-eslint/naming-convention --
			 * we don't have control over this
			 */
			const {annotationUID, data} = annotation;
			const {polylinePoints} = data;

			const polylineUid = '1';
			drawPolyline(
				svgDrawingHelper,
				annotationUID,
				polylineUid,
				polylinePoints,
				{
					width: 2,
					connectLastToFirst: true,
				},
			);
		}
	};

	_activateDraw = (element) => {
		element.addEventListener(Events.MOUSE_UP, this._mouseUpCallback);
		element.addEventListener(Events.MOUSE_DRAG, this._mouseDragCallback);
		element.addEventListener(Events.MOUSE_MOVE, this._mouseDragCallback);
		element.addEventListener(Events.MOUSE_CLICK, this._mouseUpCallback);
	};

	_deactivateDraw = (element) => {
		element.removeEventListener(Events.MOUSE_UP, this._mouseUpCallback);
		element.removeEventListener(Events.MOUSE_DRAG, this._mouseDragCallback);
		element.removeEventListener(Events.MOUSE_MOVE, this._mouseDragCallback);
		element.removeEventListener(Events.MOUSE_CLICK, this._mouseUpCallback);
	};
}
