import {useEffect, useState} from 'react';

import {type ActorEntry, cornerstone} from '@/library';
import {useGlobalState} from '@/state';
import {type VtkStateRef} from '@/types';

import addLandmarksToCornerstone3dToolState from '../utils/addLandmarksToCornerstone3dToolState';
import {createLandmarkerActorEntries} from '../utils/landmarkReader';
import {type Scan} from '../library/models';

const useLoadLandmarks = ({
	arePointCloudsLoaded,
	scan,
	vtkState,
}: {
	arePointCloudsLoaded: boolean;
	scan: Scan;
	vtkState: VtkStateRef;
}) => {
	const {
		meshes,
		scan: {landmarks},
		viewports: {volumeViewport},
	} = useGlobalState();
	const [isLoaded, setIsLoaded] = useState<boolean>(false);

	useEffect(() => {
		if (
			!isLoaded &&
			volumeViewport &&
			meshes.loaded &&
			arePointCloudsLoaded &&
			scan.hasReachedMilestone('landmarked')
		) {
			if (!cornerstone.areElementsDefined()) {
				throw new Error('Invalid cornerstone elements');
			}

			const actorEntries = createLandmarkerActorEntries(landmarks.primary);

			if (!actorEntries.length) {
				return;
			}

			if (landmarks.primary.length) {
				const actorToSet: Array<{uid: string; actor: any}> = [];

				actorEntries.forEach((actorEntry: ActorEntry) => {
					vtkState.current.landmarkActors = [
						...vtkState.current.landmarkActors,
						actorEntry,
					];

					actorToSet.push({uid: actorEntry.id, actor: actorEntry.actor});
				});

				volumeViewport.addActors(actorToSet);
				volumeViewport.render();
			}

			addLandmarksToCornerstone3dToolState(landmarks.primary);
			setIsLoaded(true);
		}
	}, [
		isLoaded,
		setIsLoaded,
		meshes.loaded,
		arePointCloudsLoaded,
		volumeViewport,
		scan.state,
	]);

	return {isLoaded};
};

export default useLoadLandmarks;
