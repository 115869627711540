import {utilities as csUtils} from '@cornerstonejs/core';
import dcmjs from 'dcmjs';
import {type api as dicomWebClientApi} from 'dicomweb-client';

type InstanceMetadata = Awaited<
	ReturnType<dicomWebClientApi.DICOMwebClient['retrieveSeriesMetadata']>
>[0];

const metadataHeadersPerImageId: Record<string, string> = {};
const instance = 'instance';

/* eslint-disable-next-line @typescript-eslint/naming-convention --
 * we don't have control over this
 */
const {DicomMessage, DicomMetaDictionary} = dcmjs.data;

function addInstance(
	imageId: string,
	dicomJsonDatasetOrP10ArrayBuffer: ArrayBuffer | InstanceMetadata,
) {
	let dicomJsonDataset;

	// If Arraybuffer, parse to DICOMJSON before naturalizing.
	if (dicomJsonDatasetOrP10ArrayBuffer instanceof ArrayBuffer) {
		const dicomData = DicomMessage.readFile(dicomJsonDatasetOrP10ArrayBuffer);

		dicomJsonDataset = dicomData.dict;
	} else {
		dicomJsonDataset = dicomJsonDatasetOrP10ArrayBuffer;
	}

	// Check if dataset is already naturalized.
	let naturalizedDataset;

	if (dicomJsonDataset.SeriesInstanceUID === undefined) {
		naturalizedDataset =
			DicomMetaDictionary.naturalizeDataset(dicomJsonDataset);
	} else {
		naturalizedDataset = dicomJsonDataset;
	}

	const imageUri = csUtils.imageIdToURI(imageId);
	metadataHeadersPerImageId[imageUri] = naturalizedDataset;
}

function get(query: string, imageId: string) {
	const imageUri = csUtils.imageIdToURI(imageId);

	if (query === instance) {
		return metadataHeadersPerImageId[imageUri];
	}
}

export default {addInstance, get};
