import {Slider, type SliderProps, Stack} from '@mui/joy';
import {assert} from '@sindresorhus/is';
import React, {type ReactNode} from 'react';

import {NumberInput, type NumberInputProps} from '@/components';

type OnChange = (value: number) => void;

export type Props = {
	disabled?: boolean;
	endDecorator?: ReactNode;
	formatOptions?: NumberInputProps['formatOptions'];
	label: string;
	max?: number;
	min?: number;
	onNumberInputChange?: OnChange;
	onChange?: OnChange;
	onSliderChange?: OnChange;
	onSliderChangeCommitted?: OnChange;
	testId?: string;
	track?: SliderProps['track'];
	value?: number;
};

/**
 * Combined and synchronized MUI [Slider]{@link https://mui.com/joy-ui/react-slider/}
 * and [NumberInput]{@link NumberInput}
 */
export default function SliderAndNumberInput({
	disabled,
	endDecorator,
	formatOptions,
	label,
	max,
	min,
	onNumberInputChange,
	onChange,
	onSliderChange,
	onSliderChangeCommitted,
	testId,
	track,
	value,
}: Props) {
	const handleNumberInputChange: NumberInputProps['onChange'] = (value) => {
		onNumberInputChange?.(value);
		onChange?.(value);
	};

	const handleSliderChange: SliderProps['onChange'] = (event, value) => {
		assert.number(value);
		onSliderChange?.(value);
		onChange?.(value);
	};

	const handleSliderChangeCommitted: SliderProps['onChangeCommitted'] = (
		event,
		value,
	) => {
		assert.number(value);
		onSliderChangeCommitted?.(value);
	};

	return (
		<Stack alignItems="center" direction="row" spacing={2}>
			<Slider
				disabled={disabled}
				max={max}
				min={min}
				onChange={handleSliderChange}
				onChangeCommitted={handleSliderChangeCommitted}
				slotProps={{
					root: {'data-testid': `slider:${(testId ?? label).toLowerCase()}`},
				}}
				sx={{minWidth: 0}}
				track={track}
				value={value}
			/>

			<NumberInput
				disabled={disabled}
				endDecorator={endDecorator}
				formatOptions={formatOptions}
				label={label}
				maxValue={max}
				minValue={min}
				onChange={handleNumberInputChange}
				slotProps={{
					root: {
						'data-testid': `number-input:${(testId ?? label).toLowerCase()}`,
					},
				}}
				value={value}
			/>
		</Stack>
	);
}
