import {FirebaseError} from 'firebase/app';
import {ref, uploadBytesResumable, getDownloadURL} from 'firebase/storage';

import {type VtkStateRef} from '@/types';
import {storageInstance} from '../../../../../library/firebase';

// @ts-expect-error -- TODO: Convert to TS and fix TS errors
import {convertPolyDataToDracoBuffer} from '../../../../../utils/draco-encoder';

export const extractPointCloudData = (vtkState: VtkStateRef) => {
	const pointsCloudsState = vtkState.current.pointCloudActors;
	const data = [];

	for (const entry of pointsCloudsState) {
		const {actor, id, label} = entry;

		const pointsCloudMapper = actor.getMapper();
		const pointsCloudPolyData = pointsCloudMapper?.getInputData();
		data.push({
			polyData: pointsCloudPolyData,
			id,
			label,
		});
	}

	return data;
};

export const convertDataToDracoBuffers = async (
	data: Array<{polyData: any; id: string; label: string}>,
) => {
	const buffers = [];

	for (const item of data) {
		const {polyData, id, label} = item;
		// eslint-disable-next-line no-await-in-loop -- keeping it simple for now
		const fileBuffer = await convertPolyDataToDracoBuffer(polyData);

		if (fileBuffer) {
			buffers.push({fileBuffer, id, label});
		}
	}

	return buffers;
};

export const makeConvertAndUploadPointClouds =
	({
		handleError,
		handleUploadProgress,
		onSavingOutputs,
		scanId,
		scanSegmentationVersion,
		userId,
		vtkState,
	}: {
		handleError: (error: string) => void;
		handleUploadProgress: ({
			fileName,
			currentProgress,
		}: {
			fileName: string;
			currentProgress: number;
		}) => void;
		onSavingOutputs: () => void;
		scanId: string;
		scanSegmentationVersion: number;
		userId?: string;
		vtkState: VtkStateRef;
	}) =>
	async () => {
		if (!userId) {
			throw new Error('User ID is not defined');
		}

		// Extract PointCloud Data
		const data = extractPointCloudData(vtkState);

		// Convert Data to Draco Buffers
		const dracoBuffers = await convertDataToDracoBuffers(data);

		const uploadPromises: Array<Promise<void>> = [];

		for (const {fileBuffer, id, label} of dracoBuffers) {
			const fileName = `${label}_pointcloud.drc`;
			const metadata = {contentType: 'application/draco'};
			const scanStoragePath = `${userId}/scans/${scanId}/outputs/${
				scanSegmentationVersion + 1
			}`;
			const dracoStoragePath = `${scanStoragePath}/${fileName}`;
			const storageRef = ref(storageInstance, dracoStoragePath);
			const uploadTask = uploadBytesResumable(storageRef, fileBuffer, metadata);

			const uploadPromise = new Promise<void>((resolve, reject) => {
				uploadTask.on(
					'state_changed',
					(snapshot) => {
						const currentProgress: number =
							(snapshot.bytesTransferred / snapshot.totalBytes) * 100;
						handleUploadProgress({fileName, currentProgress});
					},
					async (error) => {
						console.error('Upload failed with error:', error);
						handleError(`Upload failed with error: ${error.code}`);
						reject(error);
					},
					async () => {
						try {
							await getDownloadURL(uploadTask.snapshot.ref);
							resolve();
						} catch (downloadError) {
							if (downloadError instanceof FirebaseError) {
								console.error('Failed to get download URL:', downloadError);
								handleError(
									`Failed to retrieve download URL: ${downloadError.code}`,
								);
							} else {
								console.error('An unexpected error occurred:', downloadError);
							}

							reject(downloadError);
						}
					},
				);
			});

			uploadPromises.push(uploadPromise);
		}

		try {
			await Promise.all(uploadPromises);
			onSavingOutputs();
		} catch (error) {
			console.error(
				'An error occurred during the upload or subsequent operations:',
				error,
			);
		}
	};
