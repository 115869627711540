import {
	IconButton,
	Input,
	type InputProps,
	ListItem,
	ListItemContent,
	ListItemDecorator,
	Tooltip,
} from '@mui/joy';
import {EyeIcon, EyeOffIcon, PenSquareIcon, SaveIcon} from 'lucide-react';
import React, {useEffect, useState} from 'react';

import {ToggleButton, type ToggleButtonProps} from '@/components';

export type Props = {
	editingDisabled: boolean;
	isVisible: boolean;
	label: string;
	onEditingChange: (editing: boolean) => void;
	onLabelChange: (label: string) => void;
	onVisibilityChange: (visible: boolean) => void;
};

export default function PointCloudListItem({
	editingDisabled,
	isVisible,
	label,
	onEditingChange,
	onLabelChange,
	onVisibilityChange,
}: Props) {
	const [editing, setEditing] = useState<boolean>(false);
	const [newLabel, setNewLabel] = useState<string>(label);

	useEffect(() => {
		onEditingChange(editing);
	}, [editing]);

	function handleClickEdit() {
		setNewLabel(label);
		setEditing(true);
	}

	function handleNewLabelSubmit() {
		setEditing(false);
		onLabelChange(newLabel);
	}

	const handleNewLabelInputBlur: InputProps['onBlur'] = () => {
		setNewLabel(label);
		setEditing(false);
	};

	const handleNewLabelInputChange: InputProps['onChange'] = ({
		target: {value: newLabel},
	}) => {
		setNewLabel(newLabel);
	};

	const handleVisibilityChange: ToggleButtonProps['onChange'] = (
		_event,
		checked,
	) => {
		onVisibilityChange(checked);
	};

	return (
		<ListItem data-testid={`point-cloud:${label}`}>
			{/*	Visibility toggle button */}
			<ListItemDecorator>
				<ToggleButton
					checked={isVisible}
					icon
					onChange={handleVisibilityChange}
					size="sm"
					tooltipTitle={`${isVisible ? 'Show' : 'Hide'} ${label}`}
				>
					{isVisible ? <EyeIcon /> : <EyeOffIcon />}
				</ToggleButton>
			</ListItemDecorator>

			<ListItemContent>
				{editing ? (
					<form
						onSubmit={(event) => {
							event.preventDefault();
							handleNewLabelSubmit();
						}}
					>
						<Input
							autoFocus
							onBlur={handleNewLabelInputBlur}
							onChange={handleNewLabelInputChange}
							size="sm"
							value={newLabel}
						/>
					</form>
				) : (
					label
				)}
			</ListItemContent>

			{/* Right-side button */}
			<ListItemDecorator>
				<Tooltip
					title={editingDisabled ? null : editing ? 'Save' : `Rename ${label}`}
				>
					<IconButton
						disabled={editingDisabled}
						onClick={() => {
							if (editing) handleNewLabelSubmit();
							else handleClickEdit();
						}}
						size="sm"
					>
						{editing ? <SaveIcon /> : <PenSquareIcon />}
					</IconButton>
				</Tooltip>
			</ListItemDecorator>
		</ListItem>
	);
}
