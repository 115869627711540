import {type Types, volumeLoader} from '@cornerstonejs/core';
import {cornerstoneStreamingImageVolumeLoader} from '@cornerstonejs/streaming-image-volume-loader';

export default function initVolumeLoader() {
	volumeLoader.registerUnknownVolumeLoader(
		cornerstoneStreamingImageVolumeLoader as Record<
			string,
			any
		> as Types.VolumeLoaderFn,
	);
	volumeLoader.registerVolumeLoader(
		'cornerstoneStreamingImageVolume',
		cornerstoneStreamingImageVolumeLoader as Record<
			string,
			any
		> as Types.VolumeLoaderFn,
	);
}
