import {type VtkState} from '@/types';

import {renderViewports} from '@/library/cornerstone';

export function setVisibility({
	visibility,
	vtkState,
}: {
	visibility: boolean;
	vtkState: VtkState;
}) {
	for (const actorEntry of vtkState.landmarkActors) {
		actorEntry.actor.setVisibility(visibility);
	}

	renderViewports('volume');
}
