import {
	Box,
	CssVarsProvider,
	Divider,
	Sheet,
	Stack,
	Table,
	Typography,
} from '@mui/joy';
import {capitalCase} from 'change-case';
import {ChevronUpIcon} from 'lucide-react';
import React, {type RefObject} from 'react';
import {useParams as useRouteParams} from 'react-router-dom';

import {toolTypeToIcon} from '@/components/Viewer/components/SidePanel/components/Annotations';
import {calculateLateralDistance} from '@/library';
import {useGlobalState} from '@/state';
import theme from '@/themes';
import {
	type Annotation,
	type AnnotationData,
	type ResectionPlanes,
} from '@/types';

const SurgicalPlan = ({
	componentRef,
	resectionPlanes,
}: {
	componentRef: RefObject<HTMLDivElement>;
	resectionPlanes: ResectionPlanes;
}) => {
	const {scanId} = useRouteParams<{scanId: string}>();
	const {
		annotations: {annotations, notes},
		resectionPlanes: {
			adjustments: resectionPlanesAdjustments,
			selectedPairKey: selectedResectionPlanePair,
			selectedPlaneKey: selectedResectionPlane,
		},
		scan: {landmarks},
	} = useGlobalState();

	const formatValue = (value: any) => {
		if (typeof value === 'number' && !Number.isInteger(value)) {
			return Math.abs(value).toFixed(2);
		}

		return value;
	};

	const renderAnnotationTable = (data: AnnotationData) =>
		Object.entries(data)
			.filter(([_, value]) => value !== undefined && value !== null)
			.filter(([key, _]) => key !== 'text')
			.map(([key, value]) => (
				<tr key={key}>
					<td>{capitalCase(key)}</td>
					<td>{formatValue(value)}</td>
				</tr>
			));

	const adjustmentData = resectionPlanesAdjustments[selectedResectionPlanePair];
	const femoralAdjustment = adjustmentData.femoral;
	const tibialAdjustment = adjustmentData.tibial;

	return (
		<CssVarsProvider theme={theme}>
			<Sheet className="surgical-plan" ref={componentRef} variant="plain">
				<style>
					{`
          .surgical-plan {
            bottom: 0;
            height: 100%;
            left: 0;
            overflow-y: scroll;
            position: absolute;
            right: 0;
            top: 0;
            width: 100%;
            z-index: 1000;
          }

          @media print {
            body {
              background-color: rgb(11, 13, 14);
              color: #fff; /* Light text */
              -webkit-print-color-adjust: exact; /* Ensures Chrome uses the background color */
              color-adjust: exact; /* Ensures other browsers use the background color */
              margin: 0;
              padding: 0;
            }

            @page {
              background-color: rgb(11, 13, 14);
              size: auto;
              margin: 20mm 0 10mm 0;
            }

            .surgical-plan {
              overflow-y: visible;
              position: static;
              z-index: 1000;
            }

            .page-break {
              page-break-before: always;
            }
          }`}
				</style>
				<Sheet sx={{padding: '40px'}}>
					<Stack spacing={2} divider={<Divider />}>
						<Stack direction="row" spacing={2}>
							<img alt="Vent Creativity logo" src="/logo-outlined.svg" />
							<Typography level="h3">Vent Creativity</Typography>
						</Stack>
						<Typography level="h1">Surgical Plan for {scanId}</Typography>
						{notes && (
							<>
								<Typography level="h2">Notes</Typography>

								<Typography>{notes}</Typography>
							</>
						)}
						{annotations.length > 0 && (
							<Box className="page-break">
								<Typography level="h2">Annotations</Typography>

								<ol style={{paddingLeft: '0.5rem'}}>
									{' '}
									{annotations.map((annotation: Annotation, index: number) => {
										let annotationDataWithoutText = [];

										if (annotation.data) {
											annotationDataWithoutText = Object.entries(
												annotation.data,
											).filter(
												([key, value]) =>
													key !== 'text' &&
													value !== undefined &&
													value !== null,
											);
										}

										return (
											<li
												className={index > 0 ? 'page-break' : undefined}
												key={index}
												style={{
													listStyleType: 'none',
													marginBottom: '2rem',
												}}
											>
												<Box sx={{ml: '1'}}>
													<Typography
														level="h3"
														sx={{
															display: 'flex',
															flexDirection: 'row',
															alignItems: 'center',
															gap: 1,
															p: 1,
														}}
														component="span"
													>
														{index + 1}. &nbsp;
														{React.createElement(
															toolTypeToIcon[annotation.toolType] ||
																ChevronUpIcon,
														)}{' '}
														{annotation.toolType}
														<Divider orientation="vertical" />
														<span style={{textTransform: 'capitalize'}}>
															{annotation.viewportId}
														</span>
														{annotation.imageIndex && (
															<Divider orientation="vertical" />
														)}
														{annotation.imageIndex}
													</Typography>
													{annotation.screenshotDataUrl && (
														<Box
															sx={{
																display: 'flex',
																justifyContent: 'center',
																alignItems: 'center',
															}}
														>
															<img
																src={annotation.screenshotDataUrl}
																alt={`Annotation ${index}`}
																style={{
																	borderRadius: '1rem',
																	width: '50%',
																	height: 'auto',
																}}
															/>
														</Box>
													)}
													{annotation.note && (
														<>
															<h4>Notes</h4>
															<p>{annotation.note}</p>
														</>
													)}
													{annotation.data &&
														annotationDataWithoutText.length > 0 && (
															<Table
																borderAxis="both"
																size="md"
																aria-label="Annotation Data"
															>
																<thead>
																	<tr>
																		<th>Attribute</th>
																		<th>Value</th>
																	</tr>
																</thead>
																<tbody>
																	{renderAnnotationTable(annotation.data)}
																</tbody>
															</Table>
														)}
												</Box>
											</li>
										);
									})}
								</ol>
							</Box>
						)}
						<Stack spacing={2}>
							<Typography level="h2">Resection Planes</Typography>

							<Typography level="h3">Position</Typography>

							<Table borderAxis="both" size="md">
								<thead>
									<tr>
										<th>Bone</th>
										<th>M/L</th>
										<th>A/P</th>
										<th>S/I Medial</th>
										<th>S/I Lateral</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>Femoral Position (mm)</td>
										<td>{formatValue(femoralAdjustment.position.x)}</td>
										<td>{formatValue(femoralAdjustment.position.y)}</td>
										<td>{formatValue(femoralAdjustment.position.z)}</td>
										<td>
											{formatValue(
												calculateLateralDistance({
													landmarks,
													normal:
														resectionPlanes[selectedResectionPlanePair].femoral
															.normal,
													plane: selectedResectionPlane,
												}),
											)}
										</td>
									</tr>
									<tr>
										<td>Tibial Position (mm)</td>
										<td>{formatValue(tibialAdjustment.position.x)}</td>
										<td>{formatValue(tibialAdjustment.position.y)}</td>
										<td>{formatValue(tibialAdjustment.position.z)}</td>
										<td>
											{formatValue(
												calculateLateralDistance({
													landmarks,
													normal:
														resectionPlanes[selectedResectionPlanePair].tibial
															.normal,
													plane: selectedResectionPlane,
												}),
											)}
										</td>
									</tr>
								</tbody>
							</Table>

							<Typography level="h3">Rotation</Typography>

							<Table borderAxis="both" size="md">
								<thead>
									<tr>
										<th>Bone</th>
										<th>Ext/Flex</th>
										<th>Var/Val</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>Femoral Rotation (degrees)</td>
										<td>{formatValue(femoralAdjustment.rotation.x)}</td>
										<td>{formatValue(femoralAdjustment.rotation.y)}</td>
									</tr>
									<tr>
										<td>Tibial Rotation (degrees)</td>
										<td>{formatValue(tibialAdjustment.rotation.x)}</td>
										<td>{formatValue(tibialAdjustment.rotation.y)}</td>
									</tr>
								</tbody>
							</Table>
						</Stack>
					</Stack>
				</Sheet>
			</Sheet>
		</CssVarsProvider>
	);
};

export default SurgicalPlan;
