import delay from 'delay';

import {updateResectionPlanesVisibility} from '@/library';
import {useGlobalState} from '@/state';
import {type CheckboxChangeHandler, type VtkStateRef} from '@/types';

export default function useResectionPlanesVisibility({
	vtkState,
}: {
	vtkState: VtkStateRef;
}): {
	handleVisibilityChange: CheckboxChangeHandler;
	ready: boolean;
	visibility: boolean;
} {
	const {
		resectionPlanes,
		visibility: {digitalTwins: digitalTwinsVisibility},
	} = useGlobalState();

	const ready = resectionPlanes.state === 'ready';

	const handleVisibilityChange: CheckboxChangeHandler = async (event) => {
		await setVisibility(event.target.checked);
	};

	async function setVisibility(visibility: boolean) {
		if (!ready) return;

		resectionPlanes.setState('updating');

		await delay(0);

		updateResectionPlanesVisibility({
			areDigitalTwinsVisible: digitalTwinsVisibility,
			isVisible: visibility,
			selectedPair: resectionPlanes.selectedPairKey,
			vtkState,
		});

		resectionPlanes.setState('ready');
		resectionPlanes.setVisibility(visibility);
	}

	return {
		handleVisibilityChange,
		ready,
		visibility: resectionPlanes.visibility,
	};
}
