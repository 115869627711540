import vtkActor from '@kitware/vtk.js/Rendering/Core/Actor';
import vtkCubeSource from '@kitware/vtk.js/Filters/Sources/CubeSource';
import vtkCylinderSource from '@kitware/vtk.js/Filters/Sources/CylinderSource';
import vtkMapper from '@kitware/vtk.js/Rendering/Core/Mapper';
import vtkSphereSource from '@kitware/vtk.js/Filters/Sources/SphereSource';
import {type mat4} from 'gl-matrix';

import {hidePointsInPointCloud, hidePointsInPointClouds} from '@/library';
import {useDigitalTwinsStore} from '@/state/digital-twins';
import {
	type BaseDigitalTwinObjectActorEntry,
	type Bone,
	type Coordinate,
	type DepthDirection,
	type DigitalTwinMode,
	type DigitalTwinPolygonData,
	type DigitalTwinPolygonType,
	type DigitalTwinObjectActorEntry,
	type DrillDigitalTwinObjectActorEntry,
	type HeightDirection,
	isDrillData,
	isReamData,
	isResectData,
	type Position,
	type ReamDigitalTwinObjectActorEntry,
	type ResectDigitalTwinObjectActorEntry,
	type Rotation,
	type RotationMatrix,
	type WidthDirection,
	type VtkStateRef,
	type XyzDirections,
} from '@/types';

const createDigitalTwin = ({
	areDigitalTwinsVisible,
	areResectionPlanesVisible,
	bone = 'femur',
	depth = 30,
	depthDirection = 'front',
	height = 30,
	heightDirection = 'down',
	id = String(Date.now()),
	label,
	opacity = 0.15,
	position,
	radius = 25,
	renderer,
	rotation = {x: 0, y: 0, z: 0},
	type,
	vtkState,
	width = 30,
	widthDirection = 'left',
}: {
	areDigitalTwinsVisible: boolean;
	areResectionPlanesVisible: boolean;
	bone?: Bone;
	depth?: number;
	depthDirection?: DepthDirection;
	height?: number;
	heightDirection?: HeightDirection;
	id?: string;
	label?: string;
	opacity?: number;
	position?: Position;
	radius?: number;
	renderer: any;
	rotation?: Rotation;
	type: DigitalTwinPolygonType;
	vtkState: VtkStateRef;
	width?: number;
	widthDirection?: WidthDirection;
}) => {
	vtkState.current.digitalTwinActors.forEach((digitalTwinActor) => {
		digitalTwinActor.actor.getProperty().setColor(1, 0, 1);
	});

	const {bounds} = vtkState.current;

	if (!bounds) {
		throw new Error('No bounds found');
	}

	const centerX = position?.x ?? Math.round((bounds[0] + bounds[1]) / 2);
	const centerY = position?.y ?? Math.round((bounds[2] + bounds[3]) / 2);
	const centerZ = position?.z ?? Math.round((bounds[4] + bounds[5]) / 2);

	let source;
	let newDigitalTwin: DigitalTwinPolygonData;

	if (type === 'drill') {
		source = vtkCylinderSource.newInstance({
			resolution: 20,
			radius,
			height,
		});

		newDigitalTwin = {
			bone,
			height,
			heightDirection: 'down',
			id,
			label: label ?? `drill-${vtkState.current.digitalTwinActors.length + 1}`,
			opacity,
			position: {x: centerX, y: centerY, z: centerZ},
			radius,
			rotation,
			type,
		};
	} else if (type === 'ream') {
		source = vtkSphereSource.newInstance({
			phiResolution: 20,
			thetaResolution: 20,
			radius,
		});

		newDigitalTwin = {
			bone,
			id,
			label: label ?? `ream-${vtkState.current.digitalTwinActors.length + 1}`,
			opacity,
			position: {x: centerX, y: centerY, z: centerZ},
			radius,
			type,
		};
	} else if (type === 'resect') {
		source = vtkCubeSource.newInstance({
			xLength: width,
			yLength: depth,
			zLength: height,
		});

		newDigitalTwin = {
			bone,
			depth,
			depthDirection,
			height,
			heightDirection,
			id,
			label: label ?? `resect-${vtkState.current.digitalTwinActors.length + 1}`,
			opacity,
			position: {x: centerX, y: centerY, z: centerZ},
			rotation,
			type,
			width,
			widthDirection,
		};
	} else {
		throw new Error('Unsupported digital twin type');
	}

	const mapper = vtkMapper.newInstance();
	const actor = vtkActor.newInstance();

	mapper.setInputConnection(source.getOutputPort());

	actor.setMapper(mapper);

	actor.getProperty().setColor(1, 1, 1);
	actor.setPosition(centerX, centerY, centerZ);
	actor.setOrientation(rotation.x, rotation.y, rotation.z);
	actor.getProperty().setOpacity(opacity);

	renderer.addActor(actor);

	const newDigitalTwinActorEntry: BaseDigitalTwinObjectActorEntry = {
		actor,
		bone,
		center: [centerX, centerY, centerZ],
		id: newDigitalTwin.id,
		label: newDigitalTwin.label,
		opacity,
		source,
	};

	if (isDrillData(newDigitalTwin)) {
		const drillDigitalTwin: DrillDigitalTwinObjectActorEntry = {
			...newDigitalTwinActorEntry,
			radius: newDigitalTwin.radius,
			height: newDigitalTwin.height,
			heightDirection: newDigitalTwin.heightDirection,
			rotation,
			source: source as vtkCylinderSource,
			type: 'drill' as const,
			directions: {
				x: [1, 0, 0],
				y: [0, 1, 0],
				z: [0, 0, 1],
			},
		};

		vtkState.current.digitalTwinActors.push(drillDigitalTwin);
	} else if (isReamData(newDigitalTwin)) {
		const reamDigitalTwin: ReamDigitalTwinObjectActorEntry = {
			...newDigitalTwinActorEntry,
			radius: newDigitalTwin.radius,
			source: source as vtkSphereSource,
			type: 'ream' as const,
		};

		vtkState.current.digitalTwinActors.push(reamDigitalTwin);
	} else if (isResectData(newDigitalTwin)) {
		const resectDigitalTwin: ResectDigitalTwinObjectActorEntry = {
			...newDigitalTwinActorEntry,
			depth: newDigitalTwin.depth,
			depthDirection: newDigitalTwin.depthDirection,
			height: newDigitalTwin.height,
			heightDirection: newDigitalTwin.heightDirection,
			rotation,
			source: source as vtkCubeSource,
			type: 'resect' as const,
			width: newDigitalTwin.width,
			widthDirection: newDigitalTwin.widthDirection,
		};

		vtkState.current.digitalTwinActors.push(resectDigitalTwin);
	}

	hidePointsInPointCloud({
		areDigitalTwinsVisible,
		areResectionPlanesVisible,
		bone,
		vtkState,
	});

	return newDigitalTwin;
};

const deleteDigitalTwin = ({
	areDigitalTwinsVisible,
	areResectionPlanesVisible,
	bone,
	id,
	renderer,
	vtkState,
}: {
	areDigitalTwinsVisible: boolean;
	areResectionPlanesVisible: boolean;
	bone: Bone;
	id: string;
	renderer: any;
	vtkState: VtkStateRef;
}) => {
	const digitalTwin = vtkState.current.digitalTwinActors.find(
		(digitalTwin) => digitalTwin.id === id,
	);

	if (!digitalTwin) {
		throw new Error('Digital twin not found');
	}

	vtkState.current.digitalTwinActors =
		vtkState.current.digitalTwinActors.filter(
			(digitalTwin) => digitalTwin.id !== id,
		);

	renderer.removeActor(digitalTwin.actor);

	hidePointsInPointCloud({
		areDigitalTwinsVisible,
		areResectionPlanesVisible,
		bone,
		vtkState,
	});
};

const updateDigitalTwinAffectedBone = ({
	areDigitalTwinsVisible,
	areResectionPlanesVisible,
	id,
	newBone,
	oldBone,
	vtkState,
}: {
	areDigitalTwinsVisible: boolean;
	areResectionPlanesVisible: boolean;
	id: string;
	newBone: Bone;
	oldBone: Bone;
	vtkState: VtkStateRef;
}) => {
	const digitalTwin = vtkState.current.digitalTwinActors.find(
		(digitalTwin) => digitalTwin.id === id,
	);

	if (!digitalTwin) {
		throw new Error('Digital twin not found');
	}

	digitalTwin.bone = newBone;

	hidePointsInPointCloud({
		areDigitalTwinsVisible,
		areResectionPlanesVisible,
		bone: oldBone,
		vtkState,
	});

	hidePointsInPointCloud({
		areDigitalTwinsVisible,
		areResectionPlanesVisible,
		bone: newBone,
		vtkState,
	});
};

const updateDigitalTwinColor = ({
	id,
	color,
	vtkState,
}: {
	id: string;
	color: [number, number, number]; // RGB color in the range [0, 1]
	vtkState: VtkStateRef;
}) => {
	const digitalTwinActorEntry = vtkState.current.digitalTwinActors.find(
		(entry) => entry.id === id,
	);

	if (!digitalTwinActorEntry) {
		throw new Error('Actor not found');
	}

	digitalTwinActorEntry.actor.getProperty().setColor(...color);
};

const updateDigitalTwinMode = ({
	areDigitalTwinsVisible,
	areResectionPlanesVisible,
	mode,
	vtkState,
}: {
	areDigitalTwinsVisible: boolean;
	areResectionPlanesVisible: boolean;
	mode: DigitalTwinMode;
	vtkState: VtkStateRef;
}) => {
	vtkState.current.digitalTwinMode = mode;

	hidePointsInPointClouds({
		areDigitalTwinsVisible,
		areResectionPlanesVisible,
		vtkState,
	});
};

const updateDigitalTwinOpacity = ({
	id,
	opacity,
	vtkState,
}: {
	id: string;
	opacity: number;
	vtkState: VtkStateRef;
}) => {
	const digitalTwin = vtkState.current.digitalTwinActors.find(
		(digitalTwin) => digitalTwin.id === id,
	);

	if (!digitalTwin) {
		throw new Error('Digital twin not found');
	}

	digitalTwin.actor.getProperty().setOpacity(opacity);
	digitalTwin.opacity = opacity;
};

const getBoxRotationMatrix = (matrix: mat4): number[] => {
	const indicesToKeep = [0, 1, 2, 4, 5, 6, 8, 9, 10];

	return indicesToKeep.map((index) => matrix[index]);
};

const updateDigitalTwinDimension = ({
	id,
	dimension,
	value,
	vtkState,
	extensionDirection,
}: {
	id: string;
	dimension: 'height' | 'width' | 'depth';
	value: number;
	vtkState: VtkStateRef;
	extensionDirection: HeightDirection | WidthDirection | DepthDirection;
}) => {
	const digitalTwin = vtkState.current.digitalTwinActors.find(
		(digitalTwin) => digitalTwin.id === id,
	) as
		| DrillDigitalTwinObjectActorEntry
		| ResectDigitalTwinObjectActorEntry
		| undefined;

	if (!digitalTwin) {
		throw new Error('Digital twin not found');
	}

	const digitalTwinsState = useDigitalTwinsStore.getState();

	let oldValue: number;
	if (dimension === 'height') {
		oldValue = digitalTwin.height;
	} else if (dimension === 'width' || dimension === 'depth') {
		oldValue = (digitalTwin as ResectDigitalTwinObjectActorEntry)[dimension];
	} else {
		throw new Error('Invalid dimension');
	}

	const change = value - oldValue;

	if (dimension === 'height') {
		digitalTwin.height = value;
	} else if (dimension === 'width' || dimension === 'depth') {
		(digitalTwin as ResectDigitalTwinObjectActorEntry)[dimension] = value;
	}

	const fourByFourMatrix = digitalTwin.actor.getMatrix();
	const boxRotationMatrixList: number[] =
		getBoxRotationMatrix(fourByFourMatrix);
	const boxRotationMatrix: [Coordinate, Coordinate, Coordinate] = [
		[
			boxRotationMatrixList[0],
			boxRotationMatrixList[1],
			boxRotationMatrixList[2],
		],
		[
			boxRotationMatrixList[3],
			boxRotationMatrixList[4],
			boxRotationMatrixList[5],
		],
		[
			boxRotationMatrixList[6],
			boxRotationMatrixList[7],
			boxRotationMatrixList[8],
		],
	];

	const rotationVectors: Record<string, Coordinate> = {
		height: digitalTwin.type === 'resect' ? [0, 0, 1] : [0, 1, 0],
		width: [1, 0, 0],
		depth: [0, 1, 0],
	};

	const rotatedVector = applyRotationMatrixToVector(
		rotationVectors[dimension],
		boxRotationMatrix,
	);

	const positionChange =
		extensionDirection !== 'upAndDown' &&
		extensionDirection !== 'leftAndRight' &&
		extensionDirection !== 'frontAndBack'
			? change / 2
			: 0;

	const currentPosition = digitalTwin.actor.getPosition() as Coordinate;
	const delta =
		(extensionDirection === 'up' ||
		extensionDirection === 'right' ||
		extensionDirection === 'front'
			? 1
			: -1) * positionChange;

	const centerTranslationVector = rotatedVector.map((value) => value * delta);

	const newCenter: Coordinate = [
		currentPosition[0] + centerTranslationVector[0],
		currentPosition[1] + centerTranslationVector[1],
		currentPosition[2] + centerTranslationVector[2],
	];

	digitalTwin.actor.setPosition(...newCenter);
	digitalTwin.center = [...newCenter];

	if (dimension === 'height') {
		if (digitalTwin.type === 'drill') {
			digitalTwin.source.setHeight(value);
		} else if (digitalTwin.type === 'resect') {
			digitalTwin.source.setZLength(value);
		}
	} else if (dimension === 'width') {
		(digitalTwin as ResectDigitalTwinObjectActorEntry).source.setXLength(value);
	} else if (dimension === 'depth') {
		(digitalTwin as ResectDigitalTwinObjectActorEntry).source.setYLength(value);
	}

	const selectedDigitalTwin = digitalTwinsState.digitalTwins.find(
		(digitalTwin) => digitalTwin.id === id,
	);

	if (!selectedDigitalTwin) {
		throw new Error('Digital twin not found');
	}

	digitalTwinsState.setDigitalTwin({
		...selectedDigitalTwin,
		position: {
			x: digitalTwin.center[0],
			y: digitalTwin.center[1],
			z: digitalTwin.center[2],
		},
	});
};

const updateDigitalTwinHeight = (args: {
	id: string;
	height: number;
	vtkState: VtkStateRef;
	extensionDirection: HeightDirection;
}) => {
	updateDigitalTwinDimension({
		...args,
		dimension: 'height',
		value: args.height,
	});
};

const updateDigitalTwinWidth = (args: {
	id: string;
	width: number;
	vtkState: VtkStateRef;
	extensionDirection: WidthDirection;
}) => {
	updateDigitalTwinDimension({
		...args,
		dimension: 'width',
		value: args.width,
	});
};

const updateDigitalTwinDepth = (args: {
	id: string;
	depth: number;
	vtkState: VtkStateRef;
	extensionDirection: DepthDirection;
}) => {
	updateDigitalTwinDimension({
		...args,
		dimension: 'depth',
		value: args.depth,
	});
};

const updateDigitalTwinRadius = ({
	id,
	radius,
	vtkState,
}: {
	id: string;
	radius: number;
	vtkState: VtkStateRef;
}) => {
	const digitalTwin = vtkState.current.digitalTwinActors.find(
		(digitalTwin) => digitalTwin.id === id,
	) as ReamDigitalTwinObjectActorEntry | undefined;

	if (!digitalTwin) {
		throw new Error('Digital twin not found');
	}

	digitalTwin.radius = radius;
	digitalTwin.source.setRadius(radius);
};

function applyRotationMatrixToVector(
	vector: Coordinate,
	rotationMatrix: RotationMatrix,
): Coordinate {
	const vectorXcomponent =
		rotationMatrix[0][0] * vector[0] +
		rotationMatrix[0][1] * vector[1] +
		rotationMatrix[0][2] * vector[2];
	const vectorYcomponent =
		rotationMatrix[1][0] * vector[0] +
		rotationMatrix[1][1] * vector[1] +
		rotationMatrix[1][2] * vector[2];
	const vectorZcomponent =
		rotationMatrix[2][0] * vector[0] +
		rotationMatrix[2][1] * vector[1] +
		rotationMatrix[2][2] * vector[2];

	return [vectorXcomponent, vectorYcomponent, vectorZcomponent];
}

// Explanation:
// The default axes are X: [1, 0, 0], Y: [0, 1, 0], Z: [0, 0, 1].
// These axes are relative to the cylinder and, because the cylinder initializes with these values, these are the starting axes.
// After rotation, the relative axes remain the same, but their values change. Therefore, it is necessary to track these axes.
// In addition to the direction (relative Y axis), the relative X and Z axes also need to be tracked for rotation purposes.
// Rotation about one axis changes only the other two. Below are two functions.

// The first one, generateRotationMatrixWithAxisAngleNotation, creates
// the rotation matrix about the input axis with the given degree angle. For example, to rotate around the Y axis by 30 degrees, input the current
// Y axis of the cylinder in the axis variable and set the degree angle to 30 degrees.

// The second one updates the axes based on the current axes and the rotation input from the UI. This should update the direction of all three input axes.

// All axes should be kept track of and updated with every rotation.

// Rodrigues' rotation formula function
function generateRotationMatrixWithAxisAngleNotation(
	axis: Coordinate,
	degreeAngle: number,
): RotationMatrix {
	const radianAngle = (degreeAngle * Math.PI) / 180;

	// Normalize axis
	const [axisX, axisY, axisZ] = axis;
	const axisLength = Math.sqrt(axisX ** 2 + axisY ** 2 + axisZ ** 2);

	// Find necessary angle values
	const sineAngle = Math.sin(radianAngle);
	const oneMinusCosineAngle = 1 - Math.cos(radianAngle);

	const normalizedAxisX = axisX / axisLength;
	const normalizedAxisY = axisY / axisLength;
	const normalizedAxisZ = axisZ / axisLength;

	// K Matrix
	const kMatrixRow1Col1 = 0;
	const kMatrixRow2Col1 = normalizedAxisZ;
	const kMatrixRow3Col1 = -normalizedAxisY;

	const kMatrixRow1Col2 = -normalizedAxisZ;
	const kMatrixRow2Col2 = 0;
	const kMatrixRow3Col2 = normalizedAxisX;

	const kMatrixRow1Col3 = normalizedAxisY;
	const kMatrixRow2Col3 = -normalizedAxisX;
	const kMatrixRow3Col3 = 0;

	// K2 Matrix
	const k2MatrixRow1Col1 =
		kMatrixRow1Col1 * kMatrixRow1Col1 +
		kMatrixRow1Col2 * kMatrixRow2Col1 +
		kMatrixRow1Col3 * kMatrixRow3Col1;
	const k2MatrixRow2Col1 =
		kMatrixRow2Col1 * kMatrixRow1Col1 +
		kMatrixRow2Col2 * kMatrixRow2Col1 +
		kMatrixRow2Col3 * kMatrixRow3Col1;
	const k2MatrixRow3Col1 =
		kMatrixRow3Col1 * kMatrixRow1Col1 +
		kMatrixRow3Col2 * kMatrixRow2Col1 +
		kMatrixRow3Col3 * kMatrixRow3Col1;

	const k2MatrixRow1Col2 =
		kMatrixRow1Col1 * kMatrixRow1Col2 +
		kMatrixRow1Col2 * kMatrixRow2Col2 +
		kMatrixRow1Col3 * kMatrixRow3Col2;
	const k2MatrixRow2Col2 =
		kMatrixRow2Col1 * kMatrixRow1Col2 +
		kMatrixRow2Col2 * kMatrixRow2Col2 +
		kMatrixRow2Col3 * kMatrixRow3Col2;
	const k2MatrixRow3Col2 =
		kMatrixRow3Col1 * kMatrixRow1Col2 +
		kMatrixRow3Col2 * kMatrixRow2Col2 +
		kMatrixRow3Col3 * kMatrixRow3Col2;

	const k2MatrixRow1Col3 =
		kMatrixRow1Col1 * kMatrixRow1Col3 +
		kMatrixRow1Col2 * kMatrixRow2Col3 +
		kMatrixRow1Col3 * kMatrixRow3Col3;
	const k2MatrixRow2Col3 =
		kMatrixRow2Col1 * kMatrixRow1Col3 +
		kMatrixRow2Col2 * kMatrixRow2Col3 +
		kMatrixRow2Col3 * kMatrixRow3Col3;
	const k2MatrixRow3Col3 =
		kMatrixRow3Col1 * kMatrixRow1Col3 +
		kMatrixRow3Col2 * kMatrixRow2Col3 +
		kMatrixRow3Col3 * kMatrixRow3Col3;

	const outputRotationMatrixRow1Col1 =
		1 + sineAngle * kMatrixRow1Col1 + oneMinusCosineAngle * k2MatrixRow1Col1;
	const outputRotationMatrixRow1Col2 =
		sineAngle * kMatrixRow1Col2 + oneMinusCosineAngle * k2MatrixRow1Col2;
	const outputRotationMatrixRow1Col3 =
		sineAngle * kMatrixRow1Col3 + oneMinusCosineAngle * k2MatrixRow1Col3;

	const outputRotationMatrixRow2Col1 =
		sineAngle * kMatrixRow2Col1 + oneMinusCosineAngle * k2MatrixRow2Col1;
	const outputRotationMatrixRow2Col2 =
		1 + sineAngle * kMatrixRow2Col2 + oneMinusCosineAngle * k2MatrixRow2Col2;
	const outputRotationMatrixRow2Col3 =
		sineAngle * kMatrixRow2Col3 + oneMinusCosineAngle * k2MatrixRow2Col3;

	const outputRotationMatrixRow3Col1 =
		sineAngle * kMatrixRow3Col1 + oneMinusCosineAngle * k2MatrixRow3Col1;
	const outputRotationMatrixRow3Col2 =
		sineAngle * kMatrixRow3Col2 + oneMinusCosineAngle * k2MatrixRow3Col2;
	const outputRotationMatrixRow3Col3 =
		1 + sineAngle * kMatrixRow3Col3 + oneMinusCosineAngle * k2MatrixRow3Col3;

	return [
		[
			outputRotationMatrixRow1Col1,
			outputRotationMatrixRow1Col2,
			outputRotationMatrixRow1Col3,
		],
		[
			outputRotationMatrixRow2Col1,
			outputRotationMatrixRow2Col2,
			outputRotationMatrixRow2Col3,
		],
		[
			outputRotationMatrixRow3Col1,
			outputRotationMatrixRow3Col2,
			outputRotationMatrixRow3Col3,
		],
	];
}

function axisRotationOrganization(axis: XyzDirections, rotation: Rotation) {
	const {x: xRotation, y: yRotation, z: zRotation} = rotation;

	let xAxis = axis.x;
	let yAxis = axis.y;
	let zAxis = axis.z;

	if (zRotation !== 0) {
		const xyRotationMatrix = generateRotationMatrixWithAxisAngleNotation(
			zAxis,
			zRotation,
		);
		xAxis = applyRotationMatrixToVector(xAxis, xyRotationMatrix);
		yAxis = applyRotationMatrixToVector(yAxis, xyRotationMatrix);
	}

	if (xRotation !== 0) {
		const yzRotationMatrix = generateRotationMatrixWithAxisAngleNotation(
			xAxis,
			xRotation,
		);
		yAxis = applyRotationMatrixToVector(yAxis, yzRotationMatrix);
		zAxis = applyRotationMatrixToVector(zAxis, yzRotationMatrix);
	}

	if (yRotation !== 0) {
		const xzRotationMatrix = generateRotationMatrixWithAxisAngleNotation(
			yAxis,
			yRotation,
		);
		xAxis = applyRotationMatrixToVector(xAxis, xzRotationMatrix);
		zAxis = applyRotationMatrixToVector(zAxis, xzRotationMatrix);
	}

	return {x: xAxis, y: yAxis, z: zAxis};
}

function updateDigitalTwinRotation({
	id,
	rotation,
	vtkState,
}: {
	id: string;
	rotation: Rotation;
	vtkState: VtkStateRef;
}) {
	const digitalTwin = vtkState.current.digitalTwinActors.find(
		(digitalTwin) => digitalTwin.id === id,
	) as DrillDigitalTwinObjectActorEntry | undefined;

	if (!digitalTwin) {
		throw new Error('Digital twin not found');
	}

	digitalTwin.rotation = rotation;

	const newDirections = axisRotationOrganization(
		{
			x: [1, 0, 0],
			y: [0, 1, 0],
			z: [0, 0, 1],
		},
		rotation,
	);

	digitalTwin.directions = newDirections;

	digitalTwin.actor.setOrientation(rotation.x, rotation.y, rotation.z);
}

const updateDigitalTwinPosition = ({
	id,
	position,
	vtkState,
}: {
	id: string;
	position: Position;
	vtkState: VtkStateRef;
}) => {
	const digitalTwin = vtkState.current.digitalTwinActors.find(
		(digitalTwin) => digitalTwin.id === id,
	);

	if (!digitalTwin) {
		throw new Error('Digital twin not found');
	}

	digitalTwin.actor.setPosition(position.x, position.y, position.z);
	digitalTwin.center = [position.x, position.y, position.z];
};

const updateSelectedDigitalTwin = ({
	areDigitalTwinsVisible,
	areResectionPlanesVisible,
	id,
	vtkState,
}: {
	areDigitalTwinsVisible: boolean;
	areResectionPlanesVisible: boolean;
	id: string;
	vtkState: VtkStateRef;
}) => {
	vtkState.current.digitalTwinActors.forEach((digitalTwin) => {
		if (digitalTwin.id === id) {
			digitalTwin.actor.getProperty().setColor(1, 1, 1);
		} else {
			digitalTwin.actor.getProperty().setColor(1, 0, 1);
		}
	});

	hidePointsInPointClouds({
		areDigitalTwinsVisible,
		areResectionPlanesVisible,
		vtkState,
	});
};

const updateDigitalTwinsVisibility = ({
	areResectionPlanesVisible,
	isVisible,
	vtkState,
}: {
	areResectionPlanesVisible: boolean;
	isVisible: boolean;
	vtkState: VtkStateRef;
}) => {
	if (!vtkState.current) {
		throw new Error('vtkState is not defined');
	}

	const {digitalTwinActors} = vtkState.current;
	digitalTwinActors.forEach((digitalTwinActor) => {
		const properties = digitalTwinActor.actor.getProperty();

		if (isVisible) {
			properties.setOpacity(digitalTwinActor.opacity);
		} else {
			properties.setOpacity(0);
		}
	});

	hidePointsInPointClouds({
		areDigitalTwinsVisible: isVisible,
		areResectionPlanesVisible,
		vtkState,
	});
};

export {
	createDigitalTwin,
	deleteDigitalTwin,
	updateDigitalTwinAffectedBone,
	updateDigitalTwinColor,
	updateDigitalTwinDepth,
	updateDigitalTwinHeight,
	updateDigitalTwinMode,
	updateDigitalTwinOpacity,
	updateDigitalTwinPosition,
	updateDigitalTwinRadius,
	updateDigitalTwinRotation,
	updateDigitalTwinWidth,
	updateDigitalTwinsVisibility,
	updateSelectedDigitalTwin,
};
