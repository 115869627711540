import {
	Box,
	type BoxProps,
	type ColorPaletteProp,
	Container,
	type ContainerProps,
	Sheet,
	Stack,
} from '@mui/joy';
import React from 'react';
import {useParams as useRouteParams, useRouteError} from 'react-router-dom';

import {Alert, AppBar, DarkMode, Loading, Viewer} from '@/components';
import {useScan, useTitle} from '@/hooks';

import {ToolBar} from './components';

export default function Scan() {
	const routeError = useRouteError();
	const {scanId} = useRouteParams<{scanId: string}>();

	useTitle(scanId ?? 'Scan');

	const [scan, isScanLoading] = useScan({id: scanId});

	let alert: {message: string; color: ColorPaletteProp} | undefined;
	let loadingMessage: string | undefined;

	if (routeError) {
		alert = {message: 'Failed to load scan', color: 'danger'};
	} else if (isScanLoading) {
		loadingMessage = 'Loading scan';
	} else if (scan) {
		if (scan.isPreparationPending || scan.isPreparing) {
			alert = {
				message: 'This scan is currently being prepared',
				color: 'info',
			};
		} else if (scan.didPreparationFail) {
			alert = {
				message: 'Scan preparation failed',
				color: 'danger',
			};
		}
	} else {
		alert = {message: 'Failed to load scan', color: 'danger'};
	}

	const fullWidth = !alert && !loadingMessage;
	const Body = (fullWidth ? Box : Container) as React.ElementType<
		BoxProps | ContainerProps
	>;

	return (
		<DarkMode>
			<Sheet sx={{height: '100%'}}>
				<Stack sx={{height: '100%'}}>
					<AppBar
						center={
							(scan?.hasReachedMilestone('prepared') ?? false) && (
								<ToolBar scan={scan} />
							)
						}
						fullWidth={fullWidth}
						size={fullWidth ? 'sm' : 'md'}
					/>

					<Body
						sx={{
							paddingY: 2,
							...(fullWidth && {
								height: '100%',
								overflow: 'auto',
								paddingY: 0,
								width: '100%',
							}),
						}}
					>
						{alert ? (
							<Alert
								color={alert.color}
								showContactButton={alert.color === 'danger'}
								showIcon
								sx={{marginTop: 2}}
							>
								{alert.message}
							</Alert>
						) : loadingMessage ? (
							<Stack sx={{height: '100%', justifyContent: 'center'}}>
								<Loading label={loadingMessage} size="lg" />
							</Stack>
						) : scan ? (
							<Viewer scan={scan} />
						) : null}
					</Body>
				</Stack>
			</Sheet>
		</DarkMode>
	);
}
