import {landmarks} from '@/library';
import {useGlobalState} from '@/state';
import {type CheckboxChangeHandler, type VtkStateRef} from '@/types';

export default function useLandmarksVisibility({
	vtkState,
}: {
	vtkState: VtkStateRef;
}): {handleVisibilityChange: CheckboxChangeHandler; visibility: boolean} {
	const {visibility: globalVisibility} = useGlobalState();

	const handleVisibilityChange: CheckboxChangeHandler = ({
		target: {checked: visibility},
	}) => {
		landmarks.setVisibility({visibility, vtkState: vtkState.current});
		globalVisibility.setVisibility('landmarks', visibility);
	};

	return {
		handleVisibilityChange,
		visibility: globalVisibility.landmarks,
	};
}
