const windowWidth = 400;
const windowCenter = 40;

const lower = windowCenter - windowWidth / 2;
const upper = windowCenter + windowWidth / 2;

const ctVoiRange = {lower, upper};

export default function setCtTransferFunctionForVolumeActor({
	volumeActor,
}: {
	volumeActor: any;
}) {
	volumeActor
		.getProperty()
		.getRGBTransferFunction(0)
		.setMappingRange(lower, upper);
}

export {ctVoiRange};
