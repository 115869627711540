import {useEffect, useState} from 'react';

import {isDefined, landmarks} from '@/library';
import {useGlobalState} from '@/state';
import {type VtkStateRef} from '@/types';

export default function useSetLandmarksVisibility({
	vtkState,
}: {
	vtkState: VtkStateRef;
}) {
	const {
		user: {user},
		viewports: {areInitialized: viewportsInitialized},
		visibility: globalVisibility,
	} = useGlobalState();
	const [applied, setApplied] = useState<boolean>(false);

	useEffect(() => {
		if (
			applied ||
			!isDefined(user) ||
			!viewportsInitialized ||
			vtkState.current.landmarkActors.length < 1
		) {
			return;
		}

		const visibility = user.customDefaults?.visibility?.landmarks;

		if (isDefined(visibility) && visibility !== globalVisibility.landmarks) {
			landmarks.setVisibility({visibility, vtkState: vtkState.current});
			globalVisibility.setVisibility('landmarks', visibility);
		}

		setApplied(true);
	}, [applied, user, viewportsInitialized, vtkState.current.landmarkActors]);
}
