import {EVENTS, eventTarget, type VolumeViewport} from '@cornerstonejs/core';
import {type VolumeViewport3D} from '@cornerstonejs/core/dist/cjs/RenderingEngine';
import {Enums} from '@cornerstonejs/tools';
import {useEffect, useState} from 'react';

import {cornerstone} from '@/library';
import {
	areElementsDefined as areCornerstoneElementsDefined,
	type ViewportElementKey,
} from '@/library/cornerstone';
import {type Scan} from '@/library/models';
import {useGlobalState} from '@/state';
import {type Annotation} from '@/types';
import enableAndLoadCornerstoneViewports from '@/utils/enableAndLoadCornerstoneViewports';

const csToolsEvents = Enums.Events;

function useInitializeViewport({scan}: {scan: Scan}): {
	dicomImageLoadingProgress: number;
	volumeId: string;
} {
	const {
		annotations: {
			addAnnotation,
			updateAnnotation,
			removeAnnotation,
			setAreAnnotationsDirty,
		},
		viewports: {
			axialViewport,
			coronalViewport,
			sagittalViewport,
			setAreInitialized: setAreViewportsInitialized,
			setAxialViewport,
			setCoronalViewport,
			setSagittalViewport,
			setVolumeViewport,
		},
	} = useGlobalState();

	const [volumeId, setVolumeId] = useState<string>('');
	const [imageIds, setImageIds] = useState<string[]>([]);
	const [dicomImageLoadingProgress, setDicomImageLoadingProgress] = useState(0);

	useEffect(() => {
		let loadedCount = 0;

		const imageLoadedHandler = () => {
			loadedCount += 1;
			setDicomImageLoadingProgress(loadedCount / imageIds.length);
		};

		eventTarget.addEventListener(EVENTS.IMAGE_LOADED, imageLoadedHandler);
		return () => {
			eventTarget.removeEventListener(EVENTS.IMAGE_LOADED, imageLoadedHandler);
		};
	}, [imageIds.length]);

	useEffect(() => {
		const initializeViewport = async () => {
			if (!areCornerstoneElementsDefined()) {
				throw new Error('Invalid cornerstone elements');
			}

			const {volumeId, imageIds: loadedImageIds} =
				await enableAndLoadCornerstoneViewports({
					scan,
				});
			setImageIds(loadedImageIds);
			const renderingEngine = cornerstone.renderingEngine.get();

			const volumeViewport = renderingEngine.getViewport(
				cornerstone.viewportIds.volume,
			) as VolumeViewport3D;
			const axialViewport = renderingEngine.getViewport(
				cornerstone.viewportIds.axial,
			) as VolumeViewport;
			const coronalViewport = renderingEngine.getViewport(
				cornerstone.viewportIds.coronal,
			) as VolumeViewport;
			const sagittalViewport = renderingEngine.getViewport(
				cornerstone.viewportIds.sagittal,
			) as VolumeViewport;

			setVolumeId(volumeId);
			setVolumeViewport(volumeViewport);
			setAxialViewport(axialViewport);
			setCoronalViewport(coronalViewport);
			setSagittalViewport(sagittalViewport);
			setAreViewportsInitialized(true);
		};

		initializeViewport().catch(console.error);
	}, []);

	async function handleAnnotationEvent(event: any) {
		const {detail} = event;
		const {annotation} = detail;
		// eslint-disable-next-line @typescript-eslint/naming-convention
		const {annotationUID} = annotation;

		if (
			['CloudLasso', 'Crosshairs', 'Probe'].includes(
				annotation.metadata.toolName,
			)
		) {
			return;
		}

		let imageIndex = 0;
		if (event.detail?.viewportId === 'CT_AXIAL' && axialViewport) {
			imageIndex = axialViewport.getCurrentImageIdIndex() ?? 0;
		} else if (event.detail?.viewportId === 'CT_CORONAL' && coronalViewport) {
			imageIndex = coronalViewport.getCurrentImageIdIndex() ?? 0;
		} else if (event.detail?.viewportId === 'CT_SAGITTAL' && sagittalViewport) {
			imageIndex = sagittalViewport.getCurrentImageIdIndex() ?? 0;
		}

		const points = annotation.data.handles.points.map((point: number[]) => ({
			x: point[0],
			y: point[1],
			z: point[2],
		}));

		let viewportId: ViewportElementKey = 'axial';

		if (event.detail.viewportId === 'CT_CORONAL') {
			viewportId = 'coronal';
		} else if (event.detail.viewportId === 'CT_SAGITTAL') {
			viewportId = 'sagittal';
		}

		const {toolName} = annotation.metadata;
		const data = annotation.data.cachedStats
			? annotation.data.cachedStats[
					'volumeId:cornerstoneStreamingImageVolume:CT_VOLUME_ID'
			  ]
			: {};

		const newAnnotation: Annotation = {
			id: annotationUID,
			viewportId,
			imageIndex,
			points,
			data: {
				...((toolName === 'Angle' || toolName === 'CobbAngle') && {
					angle: data?.angle ?? 0,
				}),
				...(toolName === 'ArrowAnnotate' && {
					text: annotation.data.text ?? '',
				}),
				...(toolName === 'Bidirectional' && {
					length: data?.length ?? 0,
					unit: data?.unit ?? 0,
					width: data?.width ?? 0,
				}),
				...((toolName === 'EllipticalROI' || toolName === 'RectangleROI') && {
					area: data?.area ?? 0,
					areaUnit: data?.areaUnit ?? 0,
				}),
				...(toolName === 'Length' && {
					length: data?.length ?? 0,
				}),
			},
			note:
				toolName === 'ArrowAnnotate' ? annotation.data.text : annotation.note,
			toolType: toolName,
			screenshotDataUrl: undefined,
		};

		switch (event.type) {
			case csToolsEvents.ANNOTATION_ADDED:
				addAnnotation(newAnnotation);
				break;
			case csToolsEvents.ANNOTATION_COMPLETED:
				updateAnnotation(annotationUID, newAnnotation);
				break;
			case csToolsEvents.ANNOTATION_MODIFIED:
				updateAnnotation(annotationUID, newAnnotation);
				break;
			case csToolsEvents.ANNOTATION_REMOVED:
				removeAnnotation(annotationUID);
				break;
			default:
				throw new Error('Invalid event type');
		}

		setAreAnnotationsDirty(true);
	}

	useEffect(() => {
		const addedEvt = csToolsEvents.ANNOTATION_ADDED;
		const completedEvt = csToolsEvents.ANNOTATION_COMPLETED;
		const updatedEvt = csToolsEvents.ANNOTATION_MODIFIED;
		const removedEvt = csToolsEvents.ANNOTATION_REMOVED;

		eventTarget.addEventListener(addedEvt, handleAnnotationEvent);
		eventTarget.addEventListener(completedEvt, handleAnnotationEvent);
		eventTarget.addEventListener(updatedEvt, handleAnnotationEvent);
		eventTarget.addEventListener(removedEvt, handleAnnotationEvent);

		return () => {
			eventTarget.removeEventListener(addedEvt, handleAnnotationEvent);
			eventTarget.removeEventListener(completedEvt, handleAnnotationEvent);
			eventTarget.removeEventListener(updatedEvt, handleAnnotationEvent);
			eventTarget.removeEventListener(removedEvt, handleAnnotationEvent);
		};
	}, [axialViewport, coronalViewport, sagittalViewport]);

	return {
		dicomImageLoadingProgress,
		volumeId,
	};
}

export default useInitializeViewport;
