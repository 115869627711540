import {type RadioGroupProps} from '@mui/joy';
import delay from 'delay';

import {
	getPointCloudColorScheme,
	isDefined,
	pointCloudColorSchemes,
	setPointCloudColorScheme,
} from '@/library';
import {useGlobalState} from '@/state';
import {type VtkStateRef} from '@/types';

export default function useColorScheme({vtkState}: {vtkState: VtkStateRef}) {
	const {densityMap} = useGlobalState();

	const handleChange: RadioGroupProps['onChange'] = async (event) => {
		if (!isDefined(densityMap.threshold)) return;

		densityMap.setState('updating');
		await delay(1); // Force render

		const colorSchemeId = event.target.value;
		const colorScheme = getPointCloudColorScheme({id: colorSchemeId});

		const {globalLut, globalRange} = setPointCloudColorScheme({
			colorScheme,
			threshold: densityMap.threshold,
			vtkState: vtkState.current,
		});

		densityMap.setColorScheme(colorScheme);
		densityMap.updateThreshold({
			lut: globalLut,
			max: globalRange.max,
			min: globalRange.min,
		});
		densityMap.setState('ready');
	};

	return {
		handleChange,
		options: pointCloudColorSchemes,
		value: densityMap.colorScheme,
	};
}
