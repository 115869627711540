import {capitalCase} from 'change-case';
import delay from 'delay';
import React, {type ForwardRefExoticComponent, useState} from 'react';
import {
	type LucideProps,
	ArrowDownLeftIcon,
	CameraIcon,
	ChevronUpIcon,
	CircleIcon,
	PlusIcon,
	RectangleHorizontalIcon,
	RulerIcon,
	Trash2Icon,
	TriangleRightIcon,
} from 'lucide-react';
import {
	type SelectProps,
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Alert,
	Button,
	DialogTitle,
	DialogContent,
	DialogActions,
	Divider,
	IconButton,
	ListItemDecorator,
	Modal,
	ModalDialog,
	Option,
	Select,
	Stack,
	Table,
	Textarea,
	Tooltip,
	Typography,
} from '@mui/joy';
import {annotation as cornerstoneAnnotation} from '@cornerstonejs/tools';

import {type ViewportElementKey} from '@/library/cornerstone';
import {type Scan} from '@/library/models';
import {useCaptureScreenshot} from '@/hooks';
import {useGlobalState} from '@/state';
import {
	type Annotation,
	type AnnotationData,
	type AnnotationTool,
} from '@/types';

export const toolTypeToIcon: Record<
	AnnotationTool,
	ForwardRefExoticComponent<LucideProps>
> = {
	Angle: ChevronUpIcon,
	ArrowAnnotate: ArrowDownLeftIcon,
	Bidirectional: PlusIcon,
	CobbAngle: TriangleRightIcon,
	// eslint-disable-next-line @typescript-eslint/naming-convention
	EllipticalROI: CircleIcon,
	Length: RulerIcon,
	// eslint-disable-next-line @typescript-eslint/naming-convention
	RectangleROI: RectangleHorizontalIcon,
	Screenshot: CameraIcon,
};

export const getIconForToolType = (toolType: AnnotationTool) => {
	const IconComponent = toolTypeToIcon[toolType];

	return IconComponent ? <IconComponent /> : null;
};

export default function Annotations({
	onSave,
	scan,
	viewports,
}: {
	onSave: () => void;
	scan: Scan;
	viewports: Record<ViewportElementKey, any>;
}) {
	const {
		annotations: {
			addAnnotation,
			annotations,
			areAnnotationsDirty,
			currentTool,
			notes,
			removeAnnotation,
			setAreAnnotationsDirty,
			setCurrentTool,
			setNotes,
			setState,
			updateAnnotation,
		},
		viewports: {axialViewport, sagittalViewport, coronalViewport},
	} = useGlobalState();
	const {captureScreenshot} = useCaptureScreenshot();

	const handleSelectCurrentTool: SelectProps<
		AnnotationTool,
		false
	>['onChange'] = async (_, newTool) => {
		if (newTool === null) return;

		setCurrentTool(newTool);
	};

	const [openModal, setOpenModal] = useState(false);
	const [selectedAnnotation, setSelectedAnnotation] = useState<
		Annotation | undefined
	>(undefined);

	const handleOpenModal = (annotation: Annotation) => {
		setSelectedAnnotation(annotation);
		setOpenModal(true);
	};

	const handleCloseModal = () => {
		setOpenModal(false);
		setSelectedAnnotation(undefined);
	};

	const handleConfirmDelete = () => {
		if (selectedAnnotation) {
			const annotationManager =
				cornerstoneAnnotation.state.getDefaultAnnotationManager();

			annotationManager.removeAnnotation(selectedAnnotation.id);
			removeAnnotation(selectedAnnotation.id);

			viewports[selectedAnnotation.viewportId].render();

			setAreAnnotationsDirty(true);

			handleCloseModal();
		}
	};

	const formatValue = (value: any) => {
		if (typeof value === 'number' && !Number.isInteger(value)) {
			return Math.abs(value).toFixed(2);
		}

		return value;
	};

	const renderAnnotationTable = (data: AnnotationData) =>
		Object.entries(data)
			.filter(([_, value]) => value !== undefined && value !== null)
			.filter(([key, _]) => key !== 'text')
			.map(([key, value]) => (
				<tr key={key}>
					<td>{capitalCase(key)}</td>
					<td>{formatValue(value)}</td>
				</tr>
			));

	const handleClickUpdateScreenshot = async (annotationId: string) => {
		const annotation = annotations.find((a) => a.id === annotationId);

		if (!annotation) {
			throw new Error(`Annotation with id ${annotationId} not found`);
		}

		const screenshotDataUrl = await captureScreenshot({
			viewportKey: annotation.viewportId,
		});

		if (screenshotDataUrl) {
			updateAnnotation(annotationId, {screenshotDataUrl});
		}

		setAreAnnotationsDirty(true);
	};

	const handleRevertAnnotationsChanges = async () => {
		setState('updating');

		await delay(1);

		annotations.forEach(async (annotation) => {
			const annotationManager =
				cornerstoneAnnotation.state.getDefaultAnnotationManager();

			annotationManager.removeAnnotation(annotation.id);
			removeAnnotation(annotation.id);

			viewports[annotation.viewportId].render();
			handleCloseModal();
		});

		scan.annotations.annotations.forEach(async (annotation) => {
			addAnnotation(annotation);
		});

		setNotes(scan.annotations.notes);

		axialViewport?.render();
		sagittalViewport?.render();
		coronalViewport?.render();

		setAreAnnotationsDirty(false);
		setState('ready');
	};

	return (
		<Stack spacing={4}>
			{/* Dirty annotations alert / actions */}
			{areAnnotationsDirty && (
				<Alert>
					<Stack spacing={2}>
						<span>You&rsquo;ve made changes to the Annotations.</span>

						<Stack direction="row" spacing={2}>
							<Button
								color="danger"
								onClick={async () => {
									await handleRevertAnnotationsChanges();
								}}
								size="sm"
								variant="outlined"
							>
								Revert Changes
							</Button>
							<Button onClick={onSave} size="sm">
								Save Changes
							</Button>
						</Stack>
					</Stack>
				</Alert>
			)}
			<Stack spacing={1}>
				<Typography level="title-md">Notes</Typography>
				<Textarea
					minRows={4}
					value={notes}
					onChange={(e) => {
						setNotes(e.target.value);

						setAreAnnotationsDirty(true);
					}}
				/>
			</Stack>
			<Stack spacing={1}>
				<Typography level="title-md">Select Annotation Tool</Typography>
				<Select<AnnotationTool>
					onChange={handleSelectCurrentTool}
					value={currentTool}
					startDecorator={getIconForToolType(currentTool)}
				>
					{Object.entries(toolTypeToIcon)
						.filter((toolType) => toolType[0] !== 'Screenshot')
						.map(([toolType, _]) => (
							<Option key={toolType} value={toolType}>
								<ListItemDecorator>
									{getIconForToolType(toolType as AnnotationTool)}
								</ListItemDecorator>
								{toolType}
							</Option>
						))}
				</Select>
			</Stack>

			<Stack spacing={1}>
				<Typography level="title-md">Manage Annotations</Typography>
				<Stack
					direction="column"
					justifyContent="center"
					alignItems="flex-start"
					spacing={2}
				>
					{annotations.length ? (
						annotations.map((annotation) => (
							<Accordion
								key={annotation.id}
								variant="outlined"
								sx={{width: '100%'}}
							>
								<AccordionSummary>
									<Typography
										sx={{
											display: 'flex',
											flexDirection: 'row',
											alignItems: 'center',
											gap: 1,
											p: 1,
										}}
										component="span"
									>
										{getIconForToolType(annotation.toolType)}
										<Divider orientation="vertical" color="primary" />
										<span style={{textTransform: 'capitalize'}}>
											{annotation.viewportId}
										</span>
										<Divider orientation="vertical" />
										{annotation.imageIndex}
									</Typography>

									<Tooltip color="primary" title="Delete Annotation">
										<IconButton
											onClick={async (event) => {
												event.stopPropagation();
												handleOpenModal(annotation);
											}}
											sx={{position: 'absolute', right: '2.5rem'}}
										>
											<Trash2Icon />
										</IconButton>
									</Tooltip>
								</AccordionSummary>

								<AccordionDetails>
									<Stack spacing={2} sx={{p: 1}}>
										{annotation.screenshotDataUrl && (
											<img
												src={annotation.screenshotDataUrl}
												alt="Annotation Screenshot"
												style={{maxWidth: '100%', borderRadius: '.5rem'}}
											/>
										)}
										<Button
											onClick={async () => {
												await handleClickUpdateScreenshot(annotation.id);
											}}
										>
											{annotation.screenshotDataUrl ? 'Update' : 'Create'}{' '}
											Screenshot
										</Button>

										{annotation.data &&
											Object.keys(annotation.data).length > 0 && (
												<Table size="md" aria-label="Annotation Data">
													<tbody>
														{renderAnnotationTable(annotation.data)}
													</tbody>
												</Table>
											)}

										<Stack spacing={1}>
											<Typography level="title-md">Notes</Typography>
											<Textarea
												minRows={2}
												value={annotation.note}
												onChange={(e) => {
													const newNote = e.target.value;
													const updatedFields = {
														note: newNote,
														data: {...annotation.data, text: newNote},
													};

													updateAnnotation(annotation.id, updatedFields);

													if (annotation.toolType === 'ArrowAnnotate') {
														const annotationManager =
															cornerstoneAnnotation.state.getDefaultAnnotationManager();
														const annotationObject: any =
															annotationManager.getAnnotation(annotation.id);

														annotationObject.data.text = newNote;
														viewports[annotation.viewportId].render();
													}
												}}
											/>
										</Stack>
									</Stack>
								</AccordionDetails>
							</Accordion>
						))
					) : (
						<></>
					)}

					<Modal
						open={openModal}
						onClose={handleCloseModal}
						aria-labelledby="modal-modal-title"
						aria-describedby="modal-modal-description"
					>
						<ModalDialog>
							<DialogTitle>Delete Annotation</DialogTitle>

							<Divider />

							<DialogContent>
								<Typography>
									Are you sure you want to delete this annotation?
								</Typography>
							</DialogContent>

							<DialogActions>
								<Button
									variant="solid"
									color="danger"
									onClick={handleConfirmDelete}
								>
									Delete
								</Button>
								<Button variant="outlined" onClick={handleCloseModal}>
									Cancel
								</Button>
							</DialogActions>
						</ModalDialog>
					</Modal>
				</Stack>
			</Stack>
		</Stack>
	);
}
