import vtkDataArray from '@kitware/vtk.js/Common/Core/DataArray';
import vtkPolyData from '@kitware/vtk.js/Common/DataModel/PolyData';
import vtkActor from '@kitware/vtk.js/Rendering/Core/Actor';
import vtkMapper from '@kitware/vtk.js/Rendering/Core/Mapper';
import {v4 as uuid} from 'uuid';

import {
	ActorEntry,
	pointCloudsMaxOpacity,
	pointCloudsPointSize,
} from '@/library';
import {type VtkState} from '@/types';

function createActorEntryFromCloudLassoSelection({
	baseName,
	points,
	scalars,
	thresholdData,
	visibility,
	vtkState,
}: {
	baseName: string;
	points: number[] | Float32Array;
	scalars: number[] | Float32Array;
	thresholdData: {lut: any; max: number; min: number};
	visibility: boolean;
	vtkState: VtkState;
}) {
	// Add removed points as a new point cloud
	const polyData = vtkPolyData.newInstance();

	// Points
	const pointsCloud = polyData.getPoints();
	pointsCloud.setData(points);

	// Cells
	const numPts = points.length / 3;
	const verts = new Uint32Array(numPts + 1);

	// @ts-expect-error -- Leaving extra argument for now in case it's needed
	polyData.getVerts().setData(verts, 1);

	// Generate point connectivity
	verts[0] = numPts;
	for (let i = 0; i < numPts; i++) {
		verts[i + 1] = i;
	}

	// Scalars
	if (scalars instanceof Float32Array) scalars = Array.from(scalars);
	const cloudPointData = polyData.getPointData();
	const cloudPointRange = vtkDataArray.computeRange(scalars);
	const scalarCloud = vtkDataArray.newInstance({empty: true});
	scalarCloud.setData(scalars, 1);
	scalarCloud.setName('Scalars');
	scalarCloud.setRange(cloudPointRange, 1);
	cloudPointData.setScalars(scalarCloud);

	// Compute bounds
	polyData.computeBounds();

	// Create Mapper
	const mapper = vtkMapper.newInstance({
		scalarVisibility: true,
		useLookupTableScalarRange: true,
	});

	// Create Actor
	const actor = vtkActor.newInstance();
	actor.getProperty().setOpacity(pointCloudsMaxOpacity);
	actor.getProperty().setPointSize(pointCloudsPointSize);
	actor.getProperty().setEdgeVisibility(false);
	actor.setMapper(mapper);
	actor.setVisibility(visibility);

	// Set Mapper
	mapper.setInputData(polyData);
	mapper.setLookupTable(thresholdData.lut);
	mapper.setScalarRange([thresholdData.min, thresholdData.max]);
	mapper.setScalarModeToUsePointData();

	const id = `${baseName}-${uuid().slice(0, 6)}`;

	const actorEntry = new ActorEntry(actor, id + '_pointcloud', id);

	vtkState.pointCloudActors = [...vtkState.pointCloudActors, actorEntry];

	return actorEntry;
}

export default createActorEntryFromCloudLassoSelection;
