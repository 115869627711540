import {Alert as JoyAlert, type AlertProps as JoyAlertProps} from '@mui/joy';
import React from 'react';

import {ContactVent, StatusIcon} from '@/components';

export type Props = JoyAlertProps & {
	loading?: boolean;
	showContactButton?: boolean;
	showIcon?: boolean;
};

/**
 * Enhanced version of [Joy UI's Alert]{@link https://mui.com/joy-ui/react-alert/}
 * with the following features:
 * - Automatically adds icon based on `color`
 * - Optional 'Contact Vent' button
 */
export default function Alert({
	loading = false,
	showContactButton = false,
	showIcon,
	sx,
	...props
}: Props) {
	if (showContactButton) {
		props.endDecorator = <ContactVent color={props.color} component="button" />;
	}

	if (showIcon) {
		props.startDecorator = <StatusIcon color={props.color} loading={loading} />;
	}

	return (
		<JoyAlert
			sx={[{alignItems: 'flex-start'}, ...(Array.isArray(sx) ? sx : [sx])]}
			{...props}
		/>
	);
}
