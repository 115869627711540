/**
 * @todo Replace this with the official Joy UI App Bar once it exists. See
 *       [this GitHub issue]{@link https://github.com/mui/material-ui/issues/39736}.
 */

import {
	Box,
	Container as JoyContainer,
	List,
	ListItem,
	ListItemDecorator,
} from '@mui/joy';
import {ListIcon, LogOutIcon, MailIcon, PlusIcon} from 'lucide-react';
import React, {Fragment, type ReactNode} from 'react';

import {AppBarListItemButton} from '@/components';
import {useFirebaseAuthState} from '@/hooks';

type Props = {
	center?: ReactNode;
	fullWidth?: boolean;
	size?: 'sm' | 'md';
};

export default function AppBar({
	center,
	fullWidth = false,
	size = 'md',
}: Props) {
	const [user, isUserLoading] = useFirebaseAuthState();

	const isUserLoaded = !isUserLoading;
	const isUserLoggedIn = isUserLoaded && Boolean(user);

	const Container = fullWidth ? Fragment : JoyContainer;

	return (
		<Box sx={{backgroundColor: 'primary.900'}}>
			<Container>
				<List
					orientation="horizontal"
					size={size}
					sx={{
						padding: 0,
						...(!fullWidth && {
							// Increase width to visually align app bar content with body
							// content
							marginX: -1,
						}),
					}}
				>
					{/* Logo / Home */}
					<ListItem>
						<AppBarListItemButton
							component="a"
							href="/"
							sx={{fontWeight: 'bold'}}
						>
							<ListItemDecorator>
								<Box
									component="img"
									alt="Vent Creativity logo"
									src="/logo-outlined.svg"
									sx={{
										fontSize: 'var(--Icon-fontSize, 20px)',
										height: '1em',
									}}
								/>
							</ListItemDecorator>
							Vent Creativity
						</AppBarListItemButton>
					</ListItem>

					{isUserLoggedIn && (
						<>
							{/* Scans */}
							<ListItem>
								<AppBarListItemButton component="a" href="/scans">
									<ListItemDecorator>
										<ListIcon />
									</ListItemDecorator>
									Scans
								</AppBarListItemButton>
							</ListItem>

							{/* New Scan */}
							<ListItem>
								<AppBarListItemButton component="a" href="/scans/new">
									<ListItemDecorator>
										<PlusIcon />
									</ListItemDecorator>
									New Scan
								</AppBarListItemButton>
							</ListItem>
						</>
					)}

					{/* Center slot */}
					{center && (
						<ListItem sx={{marginInlineStart: 'auto'}}>{center}</ListItem>
					)}

					{isUserLoggedIn && (
						<>
							{/* Contact */}
							<ListItem sx={{marginInlineStart: 'auto'}}>
								<AppBarListItemButton component="a" href="/contact">
									<ListItemDecorator>
										<MailIcon />
									</ListItemDecorator>
									Contact
								</AppBarListItemButton>
							</ListItem>

							{/* Log out */}
							<ListItem>
								<AppBarListItemButton component="a" href="/log-out">
									<ListItemDecorator>
										<LogOutIcon />
									</ListItemDecorator>
									Log Out
								</AppBarListItemButton>
							</ListItem>
						</>
					)}
				</List>
			</Container>
		</Box>
	);
}
