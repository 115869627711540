import {type Coordinate} from './3d';
import {type Bone} from './bones';

export const digitalTwinModes = ['off', 'remaining', 'removed'] as const;

export type DigitalTwinMode = (typeof digitalTwinModes)[number];

export const digitalTwinPolygonTypes = ['drill', 'ream', 'resect'] as const;

export type DigitalTwinPolygonType = (typeof digitalTwinPolygonTypes)[number];

export type Position = {
	x: number;
	y: number;
	z: number;
};

export type Rotation = {
	x: number;
	y: number;
	z: number;
};

export type RotationMatrix = [Coordinate, Coordinate, Coordinate];

export type DepthDirection = 'front' | 'back' | 'frontAndBack';
export type WidthDirection = 'left' | 'right' | 'leftAndRight';
export type HeightDirection = 'up' | 'down' | 'upAndDown';

export type WithWidth = {
	width: number;
	widthDirection: WidthDirection;
};

export type WithHeight = {
	height: number;
	heightDirection: HeightDirection;
};

export type WithDepth = {
	depth: number;
	depthDirection: DepthDirection;
};

export type WithOpacity = {
	opacity: number;
};

export type WithRadius = {
	radius: number;
};

export type WithPosition = {
	position: Position;
};

export type WithRotation = {
	rotation: Rotation;
};

export type BaseDigitalTwinObject = {
	bone: Bone;
	id: string;
	label: string;
	opacity: number;
	position: Position;
	type: DigitalTwinPolygonType;
};

export type ResectData = BaseDigitalTwinObject &
	WithPosition &
	WithRotation &
	WithWidth &
	WithHeight &
	WithDepth & {
		type: 'resect';
	};

export type DrillData = BaseDigitalTwinObject &
	WithPosition &
	WithRotation &
	WithRadius &
	WithHeight & {
		type: 'drill';
	};

export type ReamData = BaseDigitalTwinObject &
	WithPosition &
	WithRadius & {
		type: 'ream';
	};

export type DigitalTwinPolygonData = ResectData | DrillData | ReamData;

export function isDrillData(data: DigitalTwinPolygonData): data is DrillData {
	return data.type === 'drill';
}

export function isReamData(data: DigitalTwinPolygonData): data is ReamData {
	return data.type === 'ream';
}

export function isResectData(data: DigitalTwinPolygonData): data is ResectData {
	return data.type === 'resect';
}
