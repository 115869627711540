import {meshes as meshesLibrary} from '@/library';
import {useGlobalState} from '@/state';
import {type VtkStateRef} from '@/types';

export default function useMeshesVisibility({
	vtkState,
}: {
	vtkState: VtkStateRef;
}) {
	const {meshes: globalMeshes} = useGlobalState();

	function setAllMeshesVisibility(visibility: boolean) {
		for (const mesh of globalMeshes.meshes) {
			setMeshVisibility({
				id: mesh.id,
				visibility,
			});
		}
	}

	function setMeshVisibility({
		id,
		visibility,
	}: {
		id: string;
		visibility: boolean;
	}): void {
		meshesLibrary.setVisibility({id, visibility, vtkState: vtkState.current});
		globalMeshes.updateMesh({id, visibility});
	}

	return {
		all: {
			setVisibility: setAllMeshesVisibility,
			visibility: globalMeshes.all.visibility,
		},
		individual: globalMeshes.meshes,
		loaded: globalMeshes.loaded,
		setVisibility: setMeshVisibility,
	};
}
