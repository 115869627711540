import {Representation} from '@kitware/vtk.js/Rendering/Core/Property/Constants';
import vtkPlaneSource from '@kitware/vtk.js/Filters/Sources/PlaneSource';
import vtkMapper from '@kitware/vtk.js/Rendering/Core/Mapper';
import vtkActor from '@kitware/vtk.js/Rendering/Core/Actor';

import delay from 'delay';
import {vec3} from 'gl-matrix';
import {type Entries} from 'type-fest';

import {axesDirection} from '@/constants';
import {
	cornerstone,
	encapsulateBounds,
	hidePointsInPointCloud,
	hidePointsInPointClouds,
} from '@/library';
import {useResectionPlanesStore} from '@/state/resection-planes';
import {useScanStore} from '@/state/scan';
import {useViewportsStore} from '@/state/viewports';
import {useVisibilityStore} from '@/state/visibility';
import {
	type Axis,
	type Coordinate,
	type Landmarks,
	type ResectionPlane,
	type ResectionPlaneKey,
	type ResectionPlanePairKey,
	type ResectionPlaneProperty,
	type ResectionPlanes,
	type ResectionPlanesState,
	type Vector3,
	type VtkStateRef,
} from '@/types';

const purple: [number, number, number] = [0.549, 0.239, 0.82];
const white: [number, number, number] = [1, 1, 1];

type ResectionPlaneAdjustmentLabel = {
	long: string;
	short: string;
};

export const resectionPlaneAdjustmentLabels: {
	position: {
		x: ResectionPlaneAdjustmentLabel;
		y: ResectionPlaneAdjustmentLabel;
		z: ResectionPlaneAdjustmentLabel;
	};
	rotation: {
		x: ResectionPlaneAdjustmentLabel;
		y: ResectionPlaneAdjustmentLabel;
	};
} = {
	position: {
		x: {
			long: 'Medial/Lateral',
			short: 'M/L',
		},
		y: {
			long: 'Anterior/Posterior',
			short: 'A/P',
		},
		z: {
			long: 'Superior/Inferior',
			short: 'S/I',
		},
	},
	rotation: {
		x: {
			long: 'Extension/Flexion',
			short: 'Ext/Flex',
		},
		y: {
			long: 'Varus/Valgus',
			short: 'Var/Val',
		},
	},
};

function createResectionPlane({
	pairKey,
	plane,
	planeKey,
	viewport,
	vtkState,
}: {
	pairKey: ResectionPlanePairKey;
	plane: ResectionPlane;
	planeKey: ResectionPlaneKey;
	viewport: any;
	vtkState: VtkStateRef;
}) {
	const outerBounds = vtkState.current.bounds;

	if (outerBounds === undefined) {
		throw new Error(`bounds are not defined`);
	}

	const {selectedPairKey, selectedPlaneKey} =
		useResectionPlanesStore.getState();

	const renderer = viewport.getRenderer();

	const width = outerBounds[1] - outerBounds[0];
	const height = outerBounds[3] - outerBounds[2];

	const planeSource = vtkPlaneSource.newInstance();

	const {origin, normal} = plane;
	planeSource.setXResolution(1); // Show single edge instead of grid
	planeSource.setYResolution(1);
	planeSource.setOrigin(origin);
	planeSource.setPoint1(origin[0] + width, origin[1], origin[2]);
	planeSource.setPoint2(origin[0], origin[1] + height, origin[2]);
	planeSource.setNormal(
		normal.map((n) => (planeKey === 'tibial' ? -n : n)) as Coordinate, // Invert normal for tibial plane just for visualization so we don't mess up rotations
	);
	planeSource.setCenter(origin);

	const mapper = vtkMapper.newInstance();
	mapper.setInputData(planeSource.getOutputData());

	const planeActor = vtkActor.newInstance();
	planeActor.getProperty().setLighting(false);
	planeActor.setMapper(mapper);
	const planeProperties = planeActor.getProperty();

	if (pairKey === selectedPairKey) {
		planeProperties.setOpacity(1.0);

		if (planeKey === selectedPlaneKey) {
			planeProperties.setColor(white);
		} else {
			planeProperties.setColor(purple);
		}
	} else {
		planeProperties.setOpacity(0.0);
		planeProperties.setColor(purple);
	}

	planeProperties.setRepresentation(Representation.WIREFRAME);

	renderer.addActor(planeActor);
	vtkState.current.resectionPlaneActors.push({
		actor: planeActor,
		normal,
		origin,
		pair: pairKey,
		plane: planeKey,
	});

	return planeActor;
}

function updateResectionPlanePosition({
	axis,
	pairKey,
	plane,
	planeKey,
	previousValue,
	value,
	viewport,
	vtkState,
}: {
	axis: Axis;
	pairKey: ResectionPlanePairKey;
	plane: ResectionPlane;
	planeKey: ResectionPlaneKey;
	previousValue: number;
	value: number;
	viewport: any;
	vtkState: VtkStateRef;
}) {
	const {origin} = plane;

	let deltaValue = value - previousValue;

	if (planeKey === 'tibial') {
		deltaValue = -deltaValue;
	}

	const vectorFromOrigin = axesDirection[axis].map(
		(direction) => direction * deltaValue,
	);

	const newPosition: Vector3 = [0, 0, 0];
	for (let i = 0; i <= 2; i++) {
		newPosition[i] = origin[i] + vectorFromOrigin[i];
	}

	plane.origin = newPosition;

	updateResectionPlane({
		newOrigin: newPosition,
		pairKey,
		plane,
		planeKey,
		viewport,
		vtkState,
	});
}

function updateResectionPlaneRotation({
	axis,
	pairKey,
	plane,
	planeKey,
	previousValue,
	value,
	viewport,
	vtkState,
}: {
	axis: Vector3;
	pairKey: ResectionPlanePairKey;
	plane: ResectionPlane;
	planeKey: ResectionPlaneKey;
	previousValue: number;
	value: number;
	viewport: any;
	vtkState: VtkStateRef;
}) {
	const angle = value - previousValue;

	const rotationMatrix = generateRotationMatrixWithAxisAngleNotation(
		axis,
		angle,
	);

	plane.normal = applyRotationMatrixToVector(plane.normal, rotationMatrix);

	updateResectionPlane({
		newOrigin: plane.origin,
		pairKey,
		plane,
		planeKey,
		viewport,
		vtkState,
	});
}

function createResectionPlanes({
	resectionPlanes,
	viewport,
	vtkState,
}: {
	resectionPlanes: ResectionPlanes;
	viewport: any;
	vtkState: VtkStateRef;
}) {
	const actorEntries = [
		...vtkState.current.meshActors,
		...vtkState.current.pointCloudActors,
	];

	const actors = actorEntries.map((entry) => entry.actor);

	const actorBounds = actors.map((actor) => actor.getBounds());

	const outerBounds = encapsulateBounds(actorBounds);

	if (!outerBounds?.length) {
		throw new Error('outerBounds is empty');
	}

	vtkState.current.bounds = outerBounds;

	const resectionPlaneActors: vtkActor[] = [];

	for (const [pairKey, pair] of Object.entries(resectionPlanes) as Entries<
		typeof resectionPlanes
	>) {
		for (const [planeKey, resectionPlane] of Object.entries(pair) as Entries<
			typeof pair
		>) {
			resectionPlaneActors.push(
				createResectionPlane({
					pairKey,
					plane: resectionPlane,
					planeKey,
					viewport,
					vtkState,
				}),
			);
		}
	}

	resectionPlaneActors[0].getProperty().setColor(white);
}

async function setSelectedResectionPlanePair({
	pair,
	setState = true,
	vtkState,
}: {
	pair: ResectionPlanePairKey;
	setState?: boolean;
	vtkState: VtkStateRef;
}) {
	const resectionPlanesStore = useResectionPlanesStore.getState();

	vtkState.current.selectedResectionPlanePair = pair;

	if (setState) resectionPlanesStore.setState('updating');

	await delay(1); // Force render

	resectionPlanesStore.setSelectedPairKey(pair);

	vtkState.current.resectionPlaneActors.forEach((resectionPlaneActor) => {
		const properties = resectionPlaneActor.actor.getProperty();

		if (resectionPlaneActor.pair === pair) {
			properties.setOpacity(1);
			properties.setColor(purple);

			if (resectionPlaneActor.plane === resectionPlanesStore.selectedPlaneKey) {
				properties.setColor(white);
			}
		} else {
			properties.setOpacity(0);
		}
	});

	const areResectionPlanesVisible =
		useResectionPlanesStore.getState().visibility;
	const areDigitalTwinsVisible = useVisibilityStore.getState().digitalTwins;

	hidePointsInPointCloud({
		areDigitalTwinsVisible,
		areResectionPlanesVisible,
		bone: 'femur',
		vtkState,
	});

	hidePointsInPointCloud({
		areDigitalTwinsVisible,
		areResectionPlanesVisible,
		bone: 'tibia',
		vtkState,
	});

	cornerstone.renderViewports('volume');

	if (setState) resectionPlanesStore.setState('ready');
}

async function setSelectedResectionPlane({
	plane,
	vtkState,
}: {
	plane: ResectionPlaneKey;
	vtkState: VtkStateRef;
}) {
	const resectionPlanesStore = useResectionPlanesStore.getState();
	const pair = resectionPlanesStore.selectedPairKey;

	resectionPlanesStore.setState('updating');

	await delay(0); // Force render

	if (!vtkState.current) {
		throw new Error('vtkState is not defined');
	}

	const {resectionPlaneActors} = vtkState.current;

	resectionPlaneActors.forEach((resectionPlaneActor) => {
		const properties = resectionPlaneActor.actor.getProperty();

		if (
			resectionPlaneActor.pair === pair &&
			resectionPlaneActor.plane === plane
		) {
			properties.setColor(white);
		} else if (resectionPlaneActor.pair === pair) {
			properties.setColor(purple);
		}
	});

	resectionPlanesStore.setSelectedPlaneKey(plane);

	cornerstone.renderViewports('volume');

	resectionPlanesStore.setState('ready');
}

const updateResectionPlanesVisibility = ({
	areDigitalTwinsVisible,
	isVisible,
	selectedPair,
	vtkState,
}: {
	areDigitalTwinsVisible: boolean;
	isVisible: boolean;
	selectedPair: ResectionPlanePairKey;
	vtkState: VtkStateRef;
}) => {
	if (!vtkState.current) {
		throw new Error('vtkState is not defined');
	}

	const {resectionPlaneActors} = vtkState.current;

	resectionPlaneActors.forEach((resectionPlaneActor) => {
		const properties = resectionPlaneActor.actor.getProperty();

		if (isVisible && resectionPlaneActor.pair === selectedPair) {
			properties.setOpacity(1);
		} else {
			properties.setOpacity(0);
		}
	});

	hidePointsInPointClouds({
		areDigitalTwinsVisible,
		areResectionPlanesVisible: isVisible,
		vtkState,
	});

	cornerstone.renderViewports('volume');
};

function updateResectionPlane({
	newOrigin,
	pairKey,
	plane,
	planeKey,
	viewport,
	vtkState,
}: {
	newOrigin: Vector3;
	pairKey: ResectionPlanePairKey;
	plane: ResectionPlane;
	planeKey: ResectionPlaneKey;
	viewport: any;
	vtkState: VtkStateRef;
}): void {
	const {resectionPlaneActors} = vtkState.current;
	const planeIndex = resectionPlaneActors.findIndex(
		(resectionPlaneActor) =>
			resectionPlaneActor.pair === pairKey &&
			resectionPlaneActor.plane === planeKey,
	);

	if (planeIndex !== -1) {
		const oldPlaneActor = resectionPlaneActors[planeIndex].actor;
		viewport.getRenderer().removeActor(oldPlaneActor);
		resectionPlaneActors.splice(planeIndex, 1);
	}

	const resectionPlaneWithNewOrigin: ResectionPlane = {
		...plane,
		origin: newOrigin,
	};

	createResectionPlane({
		pairKey,
		plane: resectionPlaneWithNewOrigin,
		planeKey,
		viewport,
		vtkState,
	});
}

const initializeResectionPlanes = ({
	landmarks,
	resectionPlanes,
	resectionPlanesAdjustments,
	resectionPlanesAdjustmentsSaved,
	volumeViewport,
	vtkState,
}: {
	landmarks: Landmarks;
	resectionPlanes: ResectionPlanes;
	resectionPlanesAdjustments: ResectionPlanesState;
	resectionPlanesAdjustmentsSaved?: ResectionPlanesState;
	volumeViewport: any;
	vtkState: VtkStateRef;
}) => {
	(Object.entries(resectionPlanes) as Entries<typeof resectionPlanes>).forEach(
		([pairKey, pairValue]) => {
			(Object.entries(pairValue) as Entries<typeof pairValue>).forEach(
				([planeKey, planeValue]) => {
					const {position, rotation} =
						resectionPlanesAdjustments[pairKey][planeKey];

					// Always apply position adjustments, whether they are pre-offset to not cut bone initially or because they have been saved
					updateResectionPlanePosition({
						axis: 'x',
						pairKey,
						plane: planeValue,
						planeKey,
						previousValue: 0,
						value: position.x,
						viewport: volumeViewport,
						vtkState,
					});
					updateResectionPlanePosition({
						axis: 'y',
						pairKey,
						plane: planeValue,
						planeKey,
						previousValue: 0,
						value: position.y,
						viewport: volumeViewport,
						vtkState,
					});
					updateResectionPlanePosition({
						axis: 'z',
						pairKey,
						plane: planeValue,
						planeKey,
						previousValue: 0,
						value: position.z,
						viewport: volumeViewport,
						vtkState,
					});

					if (resectionPlanesAdjustmentsSaved) {
						// Only update vtk rotation if there are saved adjustments because we don't apply any default adjustments to rotation and planes are initialized with rotation via their calculated normals
						updateResectionPlaneRotation({
							axis: getRotationAxisForPlaneRotation({
								axis: 'x',
								landmarks,
								planeKey,
							}),
							pairKey,
							plane: planeValue,
							planeKey,
							previousValue: 0,
							value: rotation.x,
							viewport: volumeViewport,
							vtkState,
						});
						updateResectionPlaneRotation({
							axis: getRotationAxisForPlaneRotation({
								axis: 'y',
								landmarks,
								planeKey,
							}),
							pairKey,
							plane: planeValue,
							planeKey,
							previousValue: 0,
							value: rotation.y,
							viewport: volumeViewport,
							vtkState,
						});
					}
				},
			);
		},
	);
};

function getDistanceFromPointToPlane({
	pointOnPlane,
	point,
	normal,
}: {
	pointOnPlane: Vector3;
	normal: Vector3;
	point: Vector3;
}): number {
	const vectorToPoint = vec3.fromValues(
		point[0] - pointOnPlane[0],
		point[1] - pointOnPlane[1],
		point[2] - pointOnPlane[2],
	);

	return vec3.dot(vectorToPoint, normal);
}

function calculateMedialDistance({
	landmarks,
	normal,
	plane,
	pointOnPlane,
}: {
	landmarks: Landmarks;
	normal: Coordinate;
	plane: ResectionPlaneKey;
	pointOnPlane: Coordinate;
}) {
	const femurMedialInferiorLandmark = landmarks.primary.find(
		({id}) => id === 'femurMedialInferiorPoint',
	);

	const tibiaMedialSulcusLandmark = landmarks.primary.find(
		({id}) => id === 'tibiaMedialSulcusPoint',
	);

	return (
		getDistanceFromPointToPlane({
			pointOnPlane,

			point: (plane === 'femoral'
				? femurMedialInferiorLandmark?.center
				: tibiaMedialSulcusLandmark?.center) ?? [0, 0, 0],
			normal,
		}) * -1
	);
}

function calculateLateralDistance({
	landmarks,
	normal,
	plane,
}: {
	landmarks: Landmarks;
	normal: Coordinate;
	plane: ResectionPlaneKey;
}) {
	const femurLateralInferiorLandmark = landmarks.primary.find(
		({id}) => id === 'femurLateralInferiorPoint',
	);

	const femurMedialInferiorLandmark = landmarks.primary.find(
		({id}) => id === 'femurMedialInferiorPoint',
	);

	const tibiaMedialSulcusLandmark = landmarks.primary.find(
		({id}) => id === 'tibiaMedialSulcusPoint',
	);

	const tibiaLateralSulcusLandmark = landmarks.primary.find(
		({id}) => id === 'tibiaLateralSulcusPoint',
	);

	const pointOnPlane =
		plane === 'femoral'
			? femurMedialInferiorLandmark?.center
			: tibiaMedialSulcusLandmark?.center;

	const point =
		plane === 'femoral'
			? femurLateralInferiorLandmark?.center
			: tibiaLateralSulcusLandmark?.center;

	if (!pointOnPlane || !point) {
		throw new Error('Point or pointOnPlane is undefined');
	}

	return getDistanceFromPointToPlane({
		pointOnPlane,
		point,
		normal,
	});
}

function projectVectorToPlane(
	vector: Coordinate,
	normal: Coordinate,
): Coordinate {
	// Normalize input vector
	const vectorLength = vec3.length(vector);
	const normalizedVector = vec3.normalize(vec3.create(), vector);

	// Normalize plane normal (should be normalized but just in case)
	const normalizedPlaneNormal = vec3.normalize(vec3.create(), normal);

	// Get dot product between vector and normal
	let dotProductBetweenNormalAndVector = vec3.dot(
		normalizedVector,
		normalizedPlaneNormal,
	);

	// Should not be bigger than 1 or less than -1, Done for floatingpointerror
	if (dotProductBetweenNormalAndVector > 1) {
		dotProductBetweenNormalAndVector = 1;
	}

	if (dotProductBetweenNormalAndVector < -1) {
		dotProductBetweenNormalAndVector = -1;
	}

	const dottedNormalizedPlaneNormal = normalizedPlaneNormal.map(
		(value) => value * dotProductBetweenNormalAndVector,
	);

	// Get normalized projected vector
	const normalizedProjectedVector = normalizedVector.map(
		(element, index) => element - dottedNormalizedPlaneNormal[index],
	);

	// Add length back to projected vector
	return normalizedProjectedVector.map(
		(element) => element * vectorLength,
	) as Coordinate;
}

function calculateAngleBetweenTwoVectorsInDegrees(
	vector1: Coordinate,
	vector2: Coordinate,
): number {
	const normalizedVector1 = vec3.normalize(
		vec3.create(),
		vector1.map((element) => Number(element.toFixed(4))) as Coordinate,
	);
	const normalizedVector2 = vec3.normalize(
		vec3.create(),
		vector2.map((element) => Number(element.toFixed(4))) as Coordinate,
	);

	let normalizedVectorsDotProduct = vec3.dot(
		normalizedVector1,
		normalizedVector2,
	);

	// Prevent floating point error should be [-1,1]
	if (normalizedVectorsDotProduct > 1) {
		normalizedVectorsDotProduct = 1;
	}

	if (normalizedVectorsDotProduct < -1) {
		normalizedVectorsDotProduct = -1;
	}

	// Calculate radian angle
	const angleRadians = Math.acos(normalizedVectorsDotProduct);
	const crossProduct = vec3.cross(
		vec3.create(),
		normalizedVector1,
		normalizedVector2,
	);

	let largestValue = crossProduct[0];

	crossProduct.forEach((value) => {
		if (Math.abs(value) > Math.abs(largestValue)) {
			largestValue = value;
		}
	});

	const signOfLargest = Math.sign(largestValue);

	return signOfLargest * angleRadians * (180 / Math.PI);
}

function generateRotationMatrixWithAxisAngleNotation(
	axis: Coordinate,
	degreeAngle: number,
): [Coordinate, Coordinate, Coordinate] {
	const radianAngle = (degreeAngle * Math.PI) / 180;

	// Normalize axis
	const [axisX, axisY, axisZ] = axis;
	const axisLength = Math.sqrt(axisX ** 2 + axisY ** 2 + axisZ ** 2);

	// Find necessary angle values
	const sineAngle = Math.sin(radianAngle);
	const oneMinusCosineAngle = 1 - Math.cos(radianAngle);

	const normalizedAxisX = axisX / axisLength;
	const normalizedAxisY = axisY / axisLength;
	const normalizedAxisZ = axisZ / axisLength;

	// K MATRIX
	const kMatrixRow1Col1 = 0;
	const kMatrixRow2Col1 = normalizedAxisZ;
	const kMatrixRow3Col1 = normalizedAxisY * -1;

	const kMatrixRow1Col2 = normalizedAxisZ * -1;
	const kMatrixRow2Col2 = 0;
	const kMatrixRow3Col2 = normalizedAxisX;

	const kMatrixRow1Col3 = normalizedAxisY;
	const kMatrixRow2Col3 = normalizedAxisX * -1;
	const kMatrixRow3Col3 = 0;

	// K2 MATRIX
	const k2MatrixRow1Col1 =
		kMatrixRow1Col1 * kMatrixRow1Col1 +
		kMatrixRow1Col2 * kMatrixRow2Col1 +
		kMatrixRow1Col3 * kMatrixRow3Col1;
	const k2MatrixRow2Col1 =
		kMatrixRow2Col1 * kMatrixRow1Col1 +
		kMatrixRow2Col2 * kMatrixRow2Col1 +
		kMatrixRow2Col3 * kMatrixRow3Col1;
	const k2MatrixRow3Col1 =
		kMatrixRow3Col1 * kMatrixRow1Col1 +
		kMatrixRow3Col2 * kMatrixRow2Col1 +
		kMatrixRow3Col3 * kMatrixRow3Col1;

	const k2MatrixRow1Col2 =
		kMatrixRow1Col1 * kMatrixRow1Col2 +
		kMatrixRow1Col2 * kMatrixRow2Col2 +
		kMatrixRow1Col3 * kMatrixRow3Col2;
	const k2MatrixRow2Col2 =
		kMatrixRow2Col1 * kMatrixRow1Col2 +
		kMatrixRow2Col2 * kMatrixRow2Col2 +
		kMatrixRow2Col3 * kMatrixRow3Col2;
	const k2MatrixRow3Col2 =
		kMatrixRow3Col1 * kMatrixRow1Col2 +
		kMatrixRow3Col2 * kMatrixRow2Col2 +
		kMatrixRow3Col3 * kMatrixRow3Col2;

	const k2MatrixRow1Col3 =
		kMatrixRow1Col1 * kMatrixRow1Col3 +
		kMatrixRow1Col2 * kMatrixRow2Col3 +
		kMatrixRow1Col3 * kMatrixRow3Col3;
	const k2MatrixRow2Col3 =
		kMatrixRow2Col1 * kMatrixRow1Col3 +
		kMatrixRow2Col2 * kMatrixRow2Col3 +
		kMatrixRow2Col3 * kMatrixRow3Col3;
	const k2MatrixRow3Col3 =
		kMatrixRow3Col1 * kMatrixRow1Col3 +
		kMatrixRow3Col2 * kMatrixRow2Col3 +
		kMatrixRow3Col3 * kMatrixRow3Col3;

	const outputRotationMatrixRow1Col1 =
		1 + sineAngle * kMatrixRow1Col1 + oneMinusCosineAngle * k2MatrixRow1Col1;
	const outputRotationMatrixRow1Col2 =
		sineAngle * kMatrixRow1Col2 + oneMinusCosineAngle * k2MatrixRow1Col2;
	const outputRotationMatrixRow1Col3 =
		sineAngle * kMatrixRow1Col3 + oneMinusCosineAngle * k2MatrixRow1Col3;

	const outputRotationMatrixRow2Col1 =
		sineAngle * kMatrixRow2Col1 + oneMinusCosineAngle * k2MatrixRow2Col1;
	const outputRotationMatrixRow2Col2 =
		1 + sineAngle * kMatrixRow2Col2 + oneMinusCosineAngle * k2MatrixRow2Col2;
	const outputRotationMatrixRow2Col3 =
		sineAngle * kMatrixRow2Col3 + oneMinusCosineAngle * k2MatrixRow2Col3;

	const outputRotationMatrixRow3Col1 =
		sineAngle * kMatrixRow3Col1 + oneMinusCosineAngle * k2MatrixRow3Col1;
	const outputRotationMatrixRow3Col2 =
		sineAngle * kMatrixRow3Col2 + oneMinusCosineAngle * k2MatrixRow3Col2;
	const outputRotationMatrixRow3Col3 =
		1 + sineAngle * kMatrixRow3Col3 + oneMinusCosineAngle * k2MatrixRow3Col3;

	const outputRotationMatrix = [
		[
			outputRotationMatrixRow1Col1,
			outputRotationMatrixRow1Col2,
			outputRotationMatrixRow1Col3,
		],
		[
			outputRotationMatrixRow2Col1,
			outputRotationMatrixRow2Col2,
			outputRotationMatrixRow2Col3,
		],
		[
			outputRotationMatrixRow3Col1,
			outputRotationMatrixRow3Col2,
			outputRotationMatrixRow3Col3,
		],
	];

	return outputRotationMatrix as [Coordinate, Coordinate, Coordinate];
}

function applyRotationMatrixToVector(
	vector: Coordinate,
	rotationMatrix: [Coordinate, Coordinate, Coordinate],
): Coordinate {
	const vectorXaxisComponent =
		rotationMatrix[0][0] * vector[0] +
		rotationMatrix[0][1] * vector[1] +
		rotationMatrix[0][2] * vector[2];
	const vectorYaxisComponent =
		rotationMatrix[1][0] * vector[0] +
		rotationMatrix[1][1] * vector[1] +
		rotationMatrix[1][2] * vector[2];
	const vectorZaxisComponent =
		rotationMatrix[2][0] * vector[0] +
		rotationMatrix[2][1] * vector[1] +
		rotationMatrix[2][2] * vector[2];

	return [vectorXaxisComponent, vectorYaxisComponent, vectorZaxisComponent];
}

function calculateRotationAngleInDegrees(
	projectionPlaneNormal: Coordinate,
	mechanicalAxisNormal: Coordinate,
	currentAlignementAxisNormal: Coordinate,
): number {
	const projectedMechanicalAxisNormal: Coordinate = projectVectorToPlane(
		mechanicalAxisNormal,
		projectionPlaneNormal,
	);
	let projectedCurrentAlignementAxisNormal: Coordinate = projectVectorToPlane(
		currentAlignementAxisNormal,
		projectionPlaneNormal,
	);

	if (
		Math.sign(projectedMechanicalAxisNormal[2]) !==
		Math.sign(projectedCurrentAlignementAxisNormal[2])
	) {
		projectedCurrentAlignementAxisNormal =
			projectedCurrentAlignementAxisNormal.map((value) => -value) as Coordinate;
	}

	return calculateAngleBetweenTwoVectorsInDegrees(
		projectedMechanicalAxisNormal,
		projectedCurrentAlignementAxisNormal,
	);
}

function calculateRotationAnglesInDegrees({
	landmarks,
	normal,
	planeKey,
}: {
	landmarks: Landmarks;
	normal: Coordinate;
	planeKey: ResectionPlaneKey;
}) {
	const {
		femurSagittalPlaneNormalVector,
		femurMechanicalAxisNormalVector,
		femurPosteriorPlaneNormalVector,
		tibiaSagittalPlaneNormalVector,
		tibiaMechanicalAxisNormalVector,
		tibiaPosteriorPlaneNormalVector,
	} = getNormalVectorsForRotationCalculation(landmarks);

	let projectionPlaneNormal: Coordinate =
		planeKey === 'femoral'
			? femurSagittalPlaneNormalVector
			: tibiaSagittalPlaneNormalVector;

	const mechanicalAxisNormal =
		planeKey === 'femoral'
			? femurMechanicalAxisNormalVector
			: tibiaMechanicalAxisNormalVector;

	const x = calculateRotationAngleInDegrees(
		projectionPlaneNormal,
		mechanicalAxisNormal,
		normal,
	);

	projectionPlaneNormal =
		planeKey === 'femoral'
			? femurPosteriorPlaneNormalVector
			: tibiaPosteriorPlaneNormalVector;

	const y = calculateRotationAngleInDegrees(
		projectionPlaneNormal,
		mechanicalAxisNormal,
		normal,
	);

	const z = calculateRotationAngleInDegrees(
		mechanicalAxisNormal,
		mechanicalAxisNormal,
		normal,
	);

	return {x, y, z};
}

function getNormalVectorsForRotationCalculation(landmarks: Landmarks) {
	const femurSagittalPointPlane = landmarks.secondary.find(
		(landmark) => landmark.id === 'femurSagittalPointPlane',
	);
	const tibiaSagittalPointPlane = landmarks.secondary.find(
		(landmark) => landmark.id === 'tibiaSagittalPointPlane',
	);

	const femurPosteriorPointPlane = landmarks.secondary.find(
		(landmark) => landmark.id === 'femurPosteriorPointPlane',
	);
	const tibiaPosteriorPointPlane = landmarks.secondary.find(
		(landmark) => landmark.id === 'tibiaPosteriorPointPlane',
	);

	const femurMechanicalPointPlane = landmarks.secondary.find(
		(landmark) => landmark.id === 'femurMechanicalPointPlane',
	);
	const tibiaMechanicalPointPlane = landmarks.secondary.find(
		(landmark) => landmark.id === 'tibiaMechanicalPointPlane',
	);

	const femurSagittalPlaneNormalVector = femurSagittalPointPlane?.center
		.slice(1)
		.slice(-3) as Coordinate;
	const tibiaSagittalPlaneNormalVector = tibiaSagittalPointPlane?.center
		.slice(1)
		.slice(-3) as Coordinate;
	const femurPosteriorPlaneNormalVector = femurPosteriorPointPlane?.center
		.slice(1)
		.slice(-3) as Coordinate;
	const tibiaPosteriorPlaneNormalVector = tibiaPosteriorPointPlane?.center
		.slice(1)
		.slice(-3) as Coordinate;

	const femurMechanicalAxisNormalVector = femurMechanicalPointPlane?.center
		.slice(1)
		.slice(-3) as Coordinate;
	const tibiaMechanicalAxisNormalVector = tibiaMechanicalPointPlane?.center
		.slice(1)
		.slice(-3) as Coordinate;

	return {
		femurSagittalPlaneNormalVector,
		tibiaSagittalPlaneNormalVector,
		femurPosteriorPlaneNormalVector,
		tibiaPosteriorPlaneNormalVector,
		femurMechanicalAxisNormalVector,
		tibiaMechanicalAxisNormalVector,
	};
}

function getRotationAxisForPlaneRotation({
	axis,
	landmarks,
	planeKey,
}: {
	axis: Axis;
	landmarks: Landmarks;
	planeKey: ResectionPlaneKey;
}) {
	const {
		femurSagittalPlaneNormalVector,
		femurPosteriorPlaneNormalVector,
		tibiaSagittalPlaneNormalVector,
		tibiaPosteriorPlaneNormalVector,
	} = getNormalVectorsForRotationCalculation(landmarks);

	let rotationAxis: Coordinate = [0, 0, 0];

	if (planeKey === 'femoral') {
		if (axis === 'x') {
			rotationAxis = femurSagittalPlaneNormalVector;
		} else if (axis === 'y') {
			rotationAxis = femurPosteriorPlaneNormalVector;
		}
	} else if (planeKey === 'tibial') {
		if (axis === 'x') {
			rotationAxis = tibiaSagittalPlaneNormalVector;
		} else if (axis === 'y') {
			rotationAxis = tibiaPosteriorPlaneNormalVector;
		}
	}

	return rotationAxis;
}

async function updateResectionPlaneProperty({
	axis,
	committed = false,
	markDirty = true,
	pairKey,
	plane,
	planeKey,
	previousValue,
	property,
	value,
	vtkState,
}: {
	axis: Axis;
	committed?: boolean;
	markDirty?: boolean;
	pairKey: ResectionPlanePairKey;
	plane: ResectionPlane;
	planeKey: ResectionPlaneKey;
	previousValue: number;
	property: ResectionPlaneProperty;
	value: number | number[];
	vtkState: VtkStateRef;
}) {
	const {
		setAdjustment,
		setDirtyProperty,
		setState,
		state,
		visibility: resectionPlanesVisibility,
	} = useResectionPlanesStore.getState();
	const {landmarks} = useScanStore.getState();
	const {volumeViewport} = useViewportsStore.getState();
	const visibility = useVisibilityStore.getState();

	if (
		(previousValue === value && !committed) ||
		state === 'updating' ||
		Number.isNaN(value) ||
		Array.isArray(value)
	) {
		return;
	}

	if (markDirty) setDirtyProperty(property);

	if (property === 'position') {
		updateResectionPlanePosition({
			axis,
			pairKey,
			plane,
			planeKey,
			previousValue,
			value,
			viewport: volumeViewport,
			vtkState,
		});

		setAdjustment({axis, pair: pairKey, plane: planeKey, property, value});
	} else {
		updateResectionPlaneRotation({
			axis: getRotationAxisForPlaneRotation({
				axis,
				landmarks,
				planeKey,
			}),
			pairKey,
			plane,
			planeKey,
			previousValue,
			value,
			viewport: volumeViewport,
			vtkState,
		});

		const {x, y} = calculateRotationAnglesInDegrees({
			landmarks,
			normal: plane.normal,
			planeKey,
		});

		setAdjustment({
			axis: 'y',
			pair: pairKey,
			plane: planeKey,
			property: 'rotation',
			value: y,
		});
		setAdjustment({
			axis: 'x',
			pair: pairKey,
			plane: planeKey,
			property: 'rotation',
			value: x,
		});
		setAdjustment({
			axis: 'z',
			pair: pairKey,
			plane: planeKey,
			property: 'position',
			value: calculateMedialDistance({
				landmarks,
				normal: plane.normal,
				plane: planeKey,
				pointOnPlane: plane.origin,
			}),
		});
	}

	volumeViewport?.render();

	if (committed) {
		setState('updating');

		await delay(1);

		hidePointsInPointCloud({
			areDigitalTwinsVisible: visibility.digitalTwins,
			areResectionPlanesVisible: resectionPlanesVisibility,
			bone: planeKey === 'femoral' ? 'femur' : 'tibia',
			vtkState,
		});

		volumeViewport?.render();

		setState('ready');
	}
}

export {
	applyRotationMatrixToVector,
	calculateAngleBetweenTwoVectorsInDegrees,
	calculateLateralDistance,
	calculateMedialDistance,
	calculateRotationAngleInDegrees,
	calculateRotationAnglesInDegrees,
	createResectionPlanes,
	generateRotationMatrixWithAxisAngleNotation,
	getDistanceFromPointToPlane,
	getNormalVectorsForRotationCalculation,
	getRotationAxisForPlaneRotation,
	initializeResectionPlanes,
	projectVectorToPlane,
	setSelectedResectionPlane,
	setSelectedResectionPlanePair,
	updateResectionPlanePosition,
	updateResectionPlaneProperty,
	updateResectionPlaneRotation,
	updateResectionPlanesVisibility,
};
