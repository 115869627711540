import type Color from 'colorjs.io';
import {create} from 'zustand';
import {immer} from 'zustand/middleware/immer';
import {subscribeWithSelector} from 'zustand/middleware';

type AllMeshesVisibility = 'hidden' | 'mixed' | 'visible';

export type Mesh = {
	color: Color;
	id: string;
	labels: {humanReadable: string; raw: string};
	visibility: boolean;
};

type State = {
	all: {visibility: AllMeshesVisibility};
	error?: Error;
	loaded: boolean;
	meshes: Mesh[];
	setError: (error?: Error) => void;
	setLoaded: (areLoaded: boolean) => void;
	setMeshes: (meshes: Mesh[]) => void;
	updateMesh: (parameters: {id: string} & Partial<Mesh>) => void;
};

export const useMeshesStore = create<State>()(
	immer(
		subscribeWithSelector((set, get) => ({
			all: {
				visibility: 'visible',
			},
			loaded: false,
			meshes: [],
			setError(error) {
				set((state) => {
					state.error = error;
				});
			},
			setLoaded(loaded) {
				set((state) => {
					state.loaded = loaded;
				});
			},
			setMeshes(meshes) {
				set((state) => {
					state.meshes = meshes;
				});
			},
			updateMesh({id, ...updatedProperties}) {
				const index = get().meshes.findIndex((mesh) => mesh.id === id);

				if (index < 0) throw new Error(`Failed to find mesh. [id=${id}]`);

				set((state) => {
					state.meshes[index] = {
						...state.meshes[index],
						...updatedProperties,
					};
				});
			},
		})),
	),
);

// Compute state
useMeshesStore.subscribe(
	(state) => state.meshes,
	(meshes) => {
		let allMeshesVisibility: AllMeshesVisibility = 'mixed';

		if (meshes.every((mesh) => !mesh.visibility)) {
			allMeshesVisibility = 'hidden';
		} else if (meshes.every((mesh) => mesh.visibility)) {
			allMeshesVisibility = 'visible';
		}

		useMeshesStore.setState({all: {visibility: allMeshesVisibility}});
	},
);
