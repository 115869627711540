import {useEffect} from 'react';

import {
	calculateRotationAnglesInDegrees,
	createResectionPlanes,
	initializeResectionPlanes,
	isDefined,
	setSelectedResectionPlanePair,
} from '@/library';
import {initialResectionPlanesAdjustments} from '@/library/models/scan';
import {useGlobalState} from '@/state';
import {
	type ResectionPlaneKey,
	type ResectionPlanePairKey,
	type VtkStateRef,
} from '@/types';

import {type Scan} from '../library/models';

const useLoadResectionPlanes = ({
	arePointCloudsLoaded,
	scan,
	vtkState,
}: {
	arePointCloudsLoaded: boolean;
	scan: Scan;
	vtkState: VtkStateRef;
}) => {
	const {
		meshes,
		resectionPlanes: {
			adjustments: resectionPlanesAdjustments,
			setAdjustments: setResectionPlanesAdjustments,
			state,
			setState,
		},
		scan: {landmarks},
		viewports: {volumeViewport},
	} = useGlobalState();

	// Instantiate resection planes
	useEffect(() => {
		if (
			state === 'loading' &&
			volumeViewport &&
			arePointCloudsLoaded &&
			meshes.loaded &&
			isDefined(scan.resectionPlanes) &&
			scan.hasReachedMilestone('landmarked')
		) {
			createResectionPlanes({
				resectionPlanes: scan.resectionPlanes,
				viewport: volumeViewport,
				vtkState,
			});
			volumeViewport.render();

			const initialAdjustments = initialResectionPlanesAdjustments;
			const adjustedAdjustments = JSON.parse(
				JSON.stringify(initialAdjustments),
			);

			if (scan.latestResectionPlanesAdjustment) {
				setResectionPlanesAdjustments(scan.latestResectionPlanesAdjustment);
			} else {
				for (const [pairKey, pairValue] of Object.entries(
					scan.resectionPlanes,
				)) {
					for (const [planeKey, planeValue] of Object.entries(pairValue)) {
						adjustedAdjustments[pairKey][planeKey].position.z =
							planeValue.zOffsetInitial;

						adjustedAdjustments[pairKey][planeKey].rotation =
							calculateRotationAnglesInDegrees({
								landmarks,
								normal:
									scan.resectionPlanes[pairKey as ResectionPlanePairKey][
										planeKey as ResectionPlaneKey
									].normal,
								planeKey: planeKey as ResectionPlaneKey,
							});
					}
				}

				setResectionPlanesAdjustments(adjustedAdjustments);
			}

			setState('initialRendering');
		}
	}, [
		meshes.loaded,
		arePointCloudsLoaded,
		scan.resectionPlanes,
		scan.state,
		setResectionPlanesAdjustments,
		state,
		volumeViewport,
	]);

	// Update resection planes position/rotation from saved state
	useEffect(() => {
		async function initialize() {
			if (
				state !== 'initialRendering' ||
				vtkState.current.bounds === undefined ||
				scan.resectionPlanes === undefined
			) {
				return;
			}

			initializeResectionPlanes({
				landmarks,
				resectionPlanes: scan.resectionPlanes,
				resectionPlanesAdjustments,
				resectionPlanesAdjustmentsSaved: scan.latestResectionPlanesAdjustment,
				volumeViewport,
				vtkState,
			});

			await setSelectedResectionPlanePair({
				pair: 'anatomical',
				setState: false, // We want to control the state
				vtkState,
			});

			setState('ready');
		}

		initialize().catch(console.error);
	}, [
		resectionPlanesAdjustments,
		vtkState.current.bounds,
		scan.resectionPlanes,
		setState,
		state,
	]);
};

export default useLoadResectionPlanes;
