import {capitalCase} from 'change-case';
import Color from 'colorjs.io';
import {useEffect} from 'react';

import {ActorEntry} from '@/library';
import {type Scan} from '@/library/models';
import {type VtkStateRef} from '@/types';
import {type Mesh, useGlobalState} from '@/state';
import {dracoMeshReader} from '@/utils';

function useLoadMeshes({scan, vtkState}: {scan: Scan; vtkState: VtkStateRef}) {
	const {
		meshes: globalMeshes,
		scan: {auxMeshObjects},
		viewports: {volumeViewport},
	} = useGlobalState();

	useEffect(() => {
		async function loadAndSetMeshActors() {
			if (
				auxMeshObjects.length < 1 ||
				globalMeshes.loaded ||
				!scan.hasReachedMilestone('kneeSegmented') ||
				!volumeViewport
			) {
				return;
			}

			const meshes: Mesh[] = [];

			await Promise.all(
				auxMeshObjects.map(async (auxMeshObject) => {
					const vtkActor = await dracoMeshReader(auxMeshObject.file);
					vtkActor.getProperty().setColor(...auxMeshObject.color);

					meshes.push({
						color: new Color({
							coords: auxMeshObject.color,
							space: 'srgb',
						}),
						id: auxMeshObject.id,
						labels: {
							humanReadable: capitalCase(auxMeshObject.label),
							raw: auxMeshObject.label,
						},
						visibility: vtkActor.getVisibility(),
					});

					vtkState.current.meshActors.push(
						new ActorEntry(vtkActor, auxMeshObject.id, auxMeshObject.label),
					);
				}),
			);

			volumeViewport.setActors(
				vtkState.current.meshActors.map((actorEntry) => ({
					actor: actorEntry.actor,
					uid: actorEntry.id,
				})),
			);
			volumeViewport.render();

			globalMeshes.setMeshes(meshes);
			globalMeshes.setLoaded(true);
		}

		loadAndSetMeshActors().catch((error) => {
			if (error instanceof Error) {
				globalMeshes.setError(error);
			}

			console.error(error);
		});
	}, [
		auxMeshObjects.length,
		globalMeshes.loaded,
		globalMeshes.setLoaded,
		scan.state,
		volumeViewport,
	]);
}

export default useLoadMeshes;
