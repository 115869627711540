import {
	Box,
	FormControl,
	FormLabel,
	Radio,
	RadioGroup,
	Slider,
	Stack,
	Typography,
} from '@mui/joy';
import {assert} from '@sindresorhus/is';
import React from 'react';

import {Loading, NumberInput, SliderAndNumberInput} from '@/components';
import {colors, isDefined} from '@/library';
import {useGlobalState} from '@/state';
import {type VtkStateRef} from '@/types';

import {useColorScheme, useOpacity, useThreshold} from './hooks';

type Props = {
	vtkState: VtkStateRef;
};

export default function DensityMap({vtkState}: Props) {
	const {densityMap} = useGlobalState();
	const {
		handleChange: handleColorSchemeChange,
		options: colorSchemes,
		value: colorScheme,
	} = useColorScheme({
		vtkState,
	});
	const {
		handleChange: handleOpacityChange,
		maximum: maximumOpacity,
		minimum: minimumOpacity,
		value: opacity,
	} = useOpacity({
		vtkState,
	});
	const {handleChange: handleThresholdChange, value: threshold} =
		useThreshold();

	const cssGradient = colors.pointCloudColorsToCssGradient({
		angle: 'to right',
		colorScheme,
	});

	const inputDisabled = densityMap.state !== 'ready';

	if (!isDefined(threshold.lut)) {
		return <Loading label="Loading" size="sm" />;
	}

	return (
		<Stack spacing={4}>
			{/* Color scheme */}
			<Stack spacing={1}>
				<Typography level="title-md">Color Scheme</Typography>

				<RadioGroup onChange={handleColorSchemeChange} value={colorScheme.id}>
					{colorSchemes.map(({id, label}) => (
						<Radio disabled={inputDisabled} key={id} label={label} value={id} />
					))}
				</RadioGroup>
			</Stack>

			{/* Opacity */}
			<Stack spacing={1}>
				<Typography level="title-md">Opacity</Typography>

				<SliderAndNumberInput
					disabled={inputDisabled}
					endDecorator="%"
					formatOptions={{
						maximumFractionDigits: 0,
					}}
					label="Opacity"
					max={maximumOpacity}
					min={minimumOpacity}
					onNumberInputChange={handleOpacityChange({committed: true})}
					onSliderChange={handleOpacityChange()}
					onSliderChangeCommitted={handleOpacityChange({committed: true})}
					value={opacity}
				/>
			</Stack>

			{/* Threshold */}
			<Stack spacing={1}>
				<Typography level="title-md">Threshold</Typography>

				{/* Threshold slider */}
				<Box sx={{paddingTop: 4, paddingX: 4}}>
					<Slider
						disabled={inputDisabled}
						max={threshold.max}
						min={threshold.min}
						onChange={async (event, value) => {
							assert.array(value);
							await handleThresholdChange({lower: value[0], upper: value[1]});
						}}
						onChangeCommitted={async (event, value) => {
							assert.array(value);
							await handleThresholdChange({
								committed: true,
								lower: value[0],
								upper: value[1],
							});
						}}
						slotProps={{
							root: {'data-testid': 'slider:threshold'},
							...(colorScheme.mapColorsToThreshold
								? {
										track: {
											sx: {
												background: cssGradient,
											},
										},
								  }
								: {
										rail: {
											sx: {
												background: cssGradient,
											},
										},
										track: {
											sx: {
												background: 'transparent',
												outline: '2px solid var(--Slider-thumbColor)',
											},
										},
								  }),
						}}
						value={[threshold.lower, threshold.upper]}
						valueLabelDisplay="on"
					/>
				</Box>

				{/* Threshold inputs */}
				<Stack direction="row" spacing={2}>
					{/* Threshold minimum input */}
					<FormControl sx={{minWidth: 0}}>
						<FormLabel htmlFor="threshold-minimum">Minimum</FormLabel>

						<NumberInput
							disabled={inputDisabled}
							endDecorator="HU"
							formatOptions={{
								maximumFractionDigits: 0,
							}}
							id="threshold-minimum"
							label="Minimum"
							maxValue={threshold.upper - 1}
							minValue={threshold.min}
							onChange={async (lower) => {
								await handleThresholdChange({committed: true, lower});
							}}
							slotProps={{
								root: {'data-testid': 'number-input:threshold:minimum'},
							}}
							value={threshold.lower}
						/>
					</FormControl>

					{/* Threshold maximum input */}
					<FormControl sx={{minWidth: 0}}>
						<FormLabel htmlFor="threshold-maximum">Maximum</FormLabel>

						<NumberInput
							disabled={inputDisabled}
							endDecorator="HU"
							formatOptions={{
								maximumFractionDigits: 0,
							}}
							id="threshold-maximum"
							label="Maximum"
							maxValue={threshold.max}
							minValue={threshold.lower + 1}
							onChange={async (upper) => {
								await handleThresholdChange({committed: true, upper});
							}}
							slotProps={{
								root: {'data-testid': 'number-input:threshold:maximum'},
							}}
							value={threshold.upper}
						/>
					</FormControl>
				</Stack>
			</Stack>
		</Stack>
	);
}
