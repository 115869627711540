import {useEffect, useRef} from 'react';
import {type Entries} from 'type-fest';

import {isDefined, updateResectionPlaneProperty} from '@/library';
import {type Scan} from '@/library/models';
import {useGlobalState} from '@/state';
import {type VtkStateRef} from '@/types';

export default function useSetPosition({
	scan,
	vtkState,
}: {
	scan: Scan;
	vtkState: VtkStateRef;
}) {
	const {
		customDefaults,
		resectionPlanes,
		user: {user},
	} = useGlobalState();
	const applied = useRef<boolean>(false);

	useEffect(() => {
		async function apply() {
			if (
				applied.current ||
				// Wait until custom default plane has been applied
				!customDefaults.resectionPlanes.plane.applied ||
				resectionPlanes.state !== 'ready' ||
				scan.resectionPlanes === undefined ||
				user === undefined
			) {
				return;
			}

			applied.current = true;

			const customAdjustments =
				user.customDefaults?.resectionPlanes?.adjustments;

			if (isDefined(customAdjustments)) {
				for (const [pairKey, pair] of Object.entries(
					customAdjustments,
				) as Entries<typeof customAdjustments>) {
					if (isDefined(pair)) {
						for (const [planeKey, plane] of Object.entries(pair) as Entries<
							typeof pair
						>) {
							if (isDefined(plane)) {
								for (const [axis, value] of Object.entries(
									plane.position,
								) as Entries<typeof plane.position>) {
									if (isDefined(value)) {
										// eslint-disable-next-line no-await-in-loop
										await updateResectionPlaneProperty({
											axis,
											committed: true,
											markDirty: false,
											pairKey,
											plane: scan.resectionPlanes[pairKey][planeKey],
											planeKey,
											previousValue:
												resectionPlanes.adjustments[pairKey][planeKey].position[
													axis
												],
											property: 'position',
											value,
											vtkState,
										});
									}
								}
							}
						}
					}
				}
			}
		}

		apply().catch(console.error);
	}, [
		customDefaults.resectionPlanes.plane.applied,
		resectionPlanes.state,
		scan.resectionPlanes,
		user,
	]);
}
