import {setPointCloudOpacity} from '@/library';
import {useGlobalState} from '@/state';
import {type VtkStateRef} from '@/types';

const maximum = 100;
const minimum = 0;

export default function useOpacity({vtkState}: {vtkState: VtkStateRef}) {
	const {densityMap} = useGlobalState();

	const handleChange: (parameters?: {
		committed?: boolean;
	}) => (opacity: number) => void =
		({committed} = {}) =>
		(opacity) => {
			if (Number.isNaN(opacity)) return;

			densityMap.setOpacity(opacity);

			if (committed) {
				setPointCloudOpacity({opacity, vtkState: vtkState.current});
			}
		};

	return {
		handleChange,
		maximum,
		minimum,
		value: densityMap.opacity,
	};
}
