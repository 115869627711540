import {type VolumeViewport} from '@cornerstonejs/core';
import {type VolumeViewport3D} from '@cornerstonejs/core/dist/cjs/RenderingEngine';
import {create} from 'zustand';
import {immer} from 'zustand/middleware/immer';

type State = {
	areInitialized: boolean;
	axialViewport?: VolumeViewport;
	coronalViewport?: VolumeViewport;
	sagittalViewport?: VolumeViewport;
	volumeViewport?: VolumeViewport3D;
};

type Actions = {
	setAreInitialized: (areInitialized: boolean) => void;
	setAxialViewport: (axial: VolumeViewport) => void;
	setCoronalViewport: (coronal: VolumeViewport) => void;
	setSagittalViewport: (sagittal: VolumeViewport) => void;
	setVolumeViewport: (volume: VolumeViewport3D) => void;
};

export const useViewportsStore = create<State & Actions>()(
	immer((set) => ({
		areInitialized: false,
		setAreInitialized(areInitialized) {
			set((state) => {
				state.areInitialized = areInitialized;
			});
		},
		setAxialViewport(axial) {
			set((state) => {
				// @ts-expect-error -- https://github.com/immerjs/immer/issues/710https://github.com/immerjs/immer/issues/710
				state.axialViewport = axial;
			});
		},
		setCoronalViewport(coronal) {
			set((state) => {
				// @ts-expect-error
				state.coronalViewport = coronal;
			});
		},
		setSagittalViewport(sagittal) {
			set((state) => {
				// @ts-expect-error
				state.sagittalViewport = sagittal;
			});
		},
		setVolumeViewport(volume) {
			set((state) => {
				// @ts-expect-error
				state.volumeViewport = volume;
			});
		},
	})),
);
