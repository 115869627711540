import {
	annotation as cornerstoneAnnotation,
	Enums as CornerstoneEnums,
	TrackballRotateTool,
} from '@cornerstonejs/tools';
import {
	type KeyUpEventType,
	type MouseUpEventType,
} from '@cornerstonejs/tools/dist/cjs/types/EventTypes';
import is from '@sindresorhus/is';
import delay from 'delay';
import {chunk} from 'lodash';

import {useDensityMapStore} from '@/state/density-map';
import {useToolsStore} from '@/state/tools';
import {useViewportsStore} from '@/state/viewports';
import {CloudLassoTool} from '@/tools';
import {type VtkState} from '@/types';
import {createActorEntryFromCloudLassoSelection, pointIsInPoly} from '@/utils';

import {elements as cornerstoneElements} from './elements';
import * as toolGroups from './tool-groups';

// eslint-disable-next-line @typescript-eslint/naming-convention
const {Events: CornerstoneEvents} = CornerstoneEnums;

type KeyUpListener = (event: KeyUpEventType) => void;
type Mode = 'multi' | 'single';
type MouseUpListener = (event: MouseUpEventType) => void;

let enterUpListener: KeyUpListener | undefined;
let mouseMainButtonUpListener: MouseUpListener | undefined;

async function applyLasso({vtkState}: {vtkState: VtkState}) {
	if (vtkState === undefined) return;

	const {volumeViewport} = useViewportsStore.getState();

	if (volumeViewport === undefined) return;

	const densityMapStore = useDensityMapStore.getState();

	if (densityMapStore.threshold.lut === undefined) return;

	const {
		lasso: {mode},
	} = useToolsStore.getState();

	let annotations = cornerstoneAnnotation.state.getAnnotations(
		volumeViewport.element,
		'CloudLasso',
	);

	if (is.emptyArray(annotations)) return;

	if (mode === 'single') annotations = [annotations[0]];

	densityMapStore.setArePointCloudsDirty(true);

	const newPoints: number[] = [];
	const newScalars: number[] = [];

	densityMapStore.setNewPointCloudProgress(0);

	await delay(0); // Force render

	for (
		let pointCloudActorIndex = 0;
		pointCloudActorIndex < vtkState.pointCloudActors.length;
		pointCloudActorIndex++
	) {
		const {actor} = vtkState.pointCloudActors[pointCloudActorIndex];

		if (actor.getVisibility()) {
			const mapper = actor.getMapper();

			if (mapper === null) {
				throw new Error('Failed to get point cloud actor mapper');
			}

			const polyData = mapper?.getInputData();
			const points = polyData.getPoints();
			const pointsData = points.getData();
			const scalars = polyData.getPointData().getScalars();
			const scalarsData = scalars.getData();

			const nonSelectedPoints = [];
			const nonSelectedScalars = [];

			const worldPoints = chunk(pointsData, 3) as Array<
				[number, number, number]
			>;

			for (let pointIndex = 0; pointIndex < worldPoints.length; pointIndex++) {
				const point = worldPoints[pointIndex];
				const canvasPoint = volumeViewport.worldToCanvas(point);

				const isInside = annotations.some((annotation) =>
					pointIsInPoly(canvasPoint, annotation.data.polylinePoints),
				);

				if (isInside) {
					newPoints.push(...point);
					newScalars.push(scalarsData[pointIndex]);
				} else {
					nonSelectedPoints.push(...point);
					nonSelectedScalars.push(scalarsData[pointIndex]);
				}
			}

			points.setData(nonSelectedPoints);
			scalars.setData(nonSelectedScalars);
			mapper.modified();
		}

		densityMapStore.setNewPointCloudProgress(
			((pointCloudActorIndex + 1) / vtkState.pointCloudActors.length) * 100,
		);

		// eslint-disable-next-line no-await-in-loop
		await delay(0); // Force render
	}

	annotations
		.map((annotation) => annotation.annotationUID)
		.filter(is.string)
		.forEach((uid) => {
			cornerstoneAnnotation.state.removeAnnotation(
				uid,
				cornerstoneElements.volume,
			);
		});

	if (!is.emptyArray(newPoints)) {
		const actorEntry = createActorEntryFromCloudLassoSelection({
			baseName: 'lasso',
			points: newPoints,
			scalars: newScalars,
			// @ts-expect-error -- densityMapStore.threshold.lut is defined
			thresholdData: densityMapStore.threshold,
			visibility: false,
			vtkState,
		});

		vtkState.pointCloudActors.push(actorEntry);

		volumeViewport.addActors([{actor: actorEntry.actor, uid: actorEntry.id}]);

		densityMapStore.addPointCloud({
			id: actorEntry.id,
			isEditing: false,
			isVisible: actorEntry.actor.getVisibility(),
			label: actorEntry.label,
		});
	}

	densityMapStore.setNewPointCloudProgress(undefined);

	volumeViewport.render();
}

const keyUpListener: KeyUpListener = (event) => {
	if (event.detail.key === 'Enter') {
		enterUpListener?.(event);
	}
};

const mouseUpListener: MouseUpListener = (event) => {
	if (event.detail.mouseButton === 0) {
		mouseMainButtonUpListener?.(event);
	}
};

/**
 * Update listener closures. This is necessary because the listener symbols
 * can't change, so that they can be passed to `(add|remove)EventListener`, but
 * we need to pass arguments to {@link applyLasso}.
 */
function setListeners({vtkState}: {vtkState: VtkState}) {
	enterUpListener = () => {
		applyLasso({vtkState}).catch(console.error);
	};

	mouseMainButtonUpListener = () => {
		applyLasso({vtkState}).catch(console.error);
	};
}

export function disable() {
	const toolGroup = toolGroups.threeDimensionalViewport.get();

	if (toolGroup === undefined) return;

	toolGroup.setToolDisabled(CloudLassoTool.toolName);
	toolGroup.setToolDisabled(TrackballRotateTool.toolName);

	toolGroup.setToolActive(TrackballRotateTool.toolName, {
		bindings: [
			{
				mouseButton: CornerstoneEnums.MouseBindings.Primary,
			},
		],
	});

	const volumeElement = cornerstoneElements.volume;

	volumeElement?.removeEventListener(
		CornerstoneEvents.KEY_UP,
		keyUpListener as EventListener,
	);
	volumeElement?.removeEventListener(
		CornerstoneEvents.MOUSE_UP,
		mouseUpListener as EventListener,
	);

	useToolsStore.getState().lasso.setEnabled(false);
}

export function enable({vtkState}: {vtkState: VtkState}) {
	const toolGroup = toolGroups.threeDimensionalViewport.get();

	if (toolGroup === undefined) return;

	const toolsStore = useToolsStore.getState();
	const {mode} = toolsStore.lasso;

	toolGroup.setToolDisabled(TrackballRotateTool.toolName);

	toolGroup.setToolActive(TrackballRotateTool.toolName, {
		bindings: [
			{
				mouseButton: CornerstoneEnums.MouseBindings.Primary,
				modifierKey: CornerstoneEnums.KeyboardBindings.Shift,
			},
		],
	});

	toolGroup.setToolActive(CloudLassoTool.toolName, {
		bindings: [{mouseButton: CornerstoneEnums.MouseBindings.Primary}],
	});

	setMode({mode, vtkState});

	toolsStore.lasso.setEnabled(true);
}

export function setMode({mode, vtkState}: {mode: Mode; vtkState: VtkState}) {
	const volumeElement = cornerstoneElements.volume;

	setListeners({vtkState});

	if (mode === 'multi') {
		volumeElement?.removeEventListener(
			CornerstoneEvents.MOUSE_UP,
			mouseUpListener as EventListener,
		);
		volumeElement?.addEventListener(
			CornerstoneEvents.KEY_UP,
			keyUpListener as EventListener,
		);
	} else if (mode === 'single') {
		volumeElement?.removeEventListener(
			CornerstoneEvents.KEY_UP,
			keyUpListener as EventListener,
		);
		volumeElement?.addEventListener(
			CornerstoneEvents.MOUSE_UP,
			mouseUpListener as EventListener,
		);
	}

	useToolsStore.getState().lasso.setMode(mode);
}
