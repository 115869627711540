import React, {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {useFirebaseSignOut} from '../hooks';

export default function LogOut() {
	const [firebaseSignOut, firebaseSignOutLoading, firebaseSignOutError] =
		useFirebaseSignOut();
	const navigate = useNavigate();

	useEffect(() => {
		async function signOut() {
			const signOutSucceeded = await firebaseSignOut();

			if (signOutSucceeded) {
				navigate('/log-in', {replace: true});
			} else {
				console.error('Sign out failed');
			}
		}

		signOut().catch(console.error);
	}, []);

	useEffect(() => {
		if (firebaseSignOutError) {
			console.error(firebaseSignOutLoading);
		}
	}, [firebaseSignOutError]);

	return null;
}
