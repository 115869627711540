import {createEncoderModule} from 'draco3d';
import {saveAs} from 'file-saver';

import {getDracoEncoderUrl} from '../api/urls';

let encoderModuleInstance;

export async function convertPolyDataToDracoBuffer(polyData) {
	const data = adaptPolyDataToDraco(polyData);
	if (!data || !encoderModuleInstance) {
		return null;
	}

	const {colors, positions, normals, texCoords} = data;
	const encoder = new encoderModuleInstance.Encoder();
	const pointCloudBuilder = new encoderModuleInstance.PointCloudBuilder();
	const dracoPointCloud = new encoderModuleInstance.PointCloud();

	const numOfPoints = positions.length / 3;
	const numberOfComponents = 3;

	if (positions) {
		/* eslint-disable-next-line new-cap --
		 * we don't have control over this
		 */
		pointCloudBuilder.AddFloatAttribute(
			dracoPointCloud,
			encoderModuleInstance.POSITION,
			numOfPoints,
			numberOfComponents,
			positions, // Data array with length numberOfValues
		);
	}

	if (normals) {
		/* eslint-disable-next-line new-cap --
		 * we don't have control over this
		 */
		pointCloudBuilder.AddFloatAttribute(
			dracoPointCloud,
			// eslint-disable-next-line no-undef -- no idea how this works
			decoderModule.NORMAL,
			numOfPoints,
			numberOfComponents,
			normals,
		);
	}

	if (texCoords) {
		/* eslint-disable-next-line new-cap --
		 * we don't have control over this
		 */
		pointCloudBuilder.AddFloatAttribute(
			dracoPointCloud,
			// eslint-disable-next-line no-undef -- no idea how this works
			decoderModule.TEX_COORD,
			numOfPoints,
			numberOfComponents,
			texCoords,
		);
	}

	if (colors) {
		// Encode color as (R,G,B) = Intensity, 0, 0
		/* eslint-disable-next-line new-cap --
		 * we don't have control over this
		 */
		pointCloudBuilder.AddFloatAttribute(
			dracoPointCloud,
			encoderModuleInstance.COLOR,
			numOfPoints,
			numberOfComponents,
			colors, // Data array with length numberOfValues
		);
	}

	// Believe this needs to be set? TODO
	/* eslint-disable-next-line new-cap --
	 * we don't have control over this
	 */
	encoder.SetEncodingMethod(encoderModuleInstance.MESH_SEQUENTIAL_ENCODING);

	const encodedData = new encoderModuleInstance.DracoInt8Array();
	// Use default encoding setting.
	/* eslint-disable-next-line new-cap --
	 * we don't have control over this
	 */
	const encodedLen = encoder.EncodePointCloudToDracoBuffer(
		dracoPointCloud,
		false, // Deduplicate_values -> Assume we don't have duplicated values for now.
		encodedData,
	);

	if (encodedLen < 1) {
		console.error('Encoding failed.');
	}

	// Copy encoded data to buffer.
	const outputBuffer = new ArrayBuffer(encodedLen);
	const outputData = new Int8Array(outputBuffer);

	for (let i = 0; i < encodedLen; ++i) {
		/* eslint-disable-next-line new-cap --
		 * we don't have control over this
		 */
		outputData[i] = encodedData.GetValue(i);
	}

	encoderModuleInstance.destroy(dracoPointCloud);
	encoderModuleInstance.destroy(encoder);
	encoderModuleInstance.destroy(pointCloudBuilder);

	return outputBuffer;
}

function adaptPolyDataToDraco(polyData) {
	if (!polyData) {
		return;
	}

	function getDataContent(content) {
		return content && content.getData();
	}

	const pointsCloud = polyData.getPoints();
	const positions = getDataContent(pointsCloud);
	const pointData = pointsCloud && polyData.getPointData();
	const scalarCloud = pointData && pointData.getScalars();
	const scalarCloudArray = getDataContent(scalarCloud);
	const colors = convertScalarsToColorArray(scalarCloudArray);
	const verticesCloud = polyData.getVerts();
	const vertices = getDataContent(verticesCloud);
	const normalsCloud = pointData && pointData.getNormals();
	const normals = getDataContent(normalsCloud);
	const texCoordsCloud = pointData && pointData.getTCoords();
	const texCoords = getDataContent(texCoordsCloud);

	return {
		positions,
		colors,
		vertices,
		normals,
		texCoords,
	};
}

function convertScalarsToColorArray(scalars) {
	const colors = [];

	let colorIndex = 0;
	// Red color only
	for (let i = 0; i < scalars.length; i++) {
		colors[colorIndex] = scalars[i];
		colorIndex++;
		colors[colorIndex] = 0;
		colorIndex++;
		colors[colorIndex] = 0;
		colorIndex++;
	}

	return colors;
}

function setWasmBinary(url, binaryName, moduleCreator) {
	const module = {};

	return new Promise((resolve, reject) => {
		module.wasmBinaryFile = binaryName;

		const xhr = new XMLHttpRequest();
		xhr.open('GET', url, true);
		xhr.responseType = 'arraybuffer';

		xhr.onload = () => {
			if (xhr.status === 200) {
				module.wasmBinary = xhr.response;
				if (!moduleCreator) {
					reject(new Error('WASM binary could not be loaded'));
				}

				// Use Promise.resolve to be compatible with versions before Draco 1.4.0
				Promise.resolve(moduleCreator(module)).then((module) => {
					encoderModuleInstance = module;
					resolve(true);
				}, reject);
			} else {
				reject(Error(`WASM binary could not be loaded: ${xhr.statusText}`));
			}
		};

		xhr.send(null);
	});
}

export async function savePointCloudsToDisk(items = []) {
	for (const item of items) {
		const {polyData, label} = item;
		// eslint-disable-next-line no-await-in-loop -- keeping simple for now
		const buffer = await convertPolyDataToDracoBuffer(polyData);

		if (buffer) {
			saveAs(new Blob([buffer], {type: 'text/plain'}), label + '.drc');
		}
	}
}

setWasmBinary(getDracoEncoderUrl(), 'draco_encoder.wasm', createEncoderModule);
