import {
	AspectRatio,
	Button,
	CircularProgress,
	DialogActions,
	DialogContent,
	FormControl,
	FormHelperText,
	FormLabel,
	List,
	ListItem,
	ListItemContent,
	ListItemDecorator,
	Modal,
	ModalDialog,
	Stack,
	Switch,
	ToggleButtonGroup,
	type ToggleButtonGroupProps,
	Typography,
} from '@mui/joy';
import React from 'react';

import {Alert, Loading} from '@/components';
import {LassoModeMultipleIcon, LassoModeSingleIcon} from '@/icons';
import {cornerstone, isDefined} from '@/library';
import {type useGlobalState} from '@/state';
import {type PointCloud, type VtkStateRef} from '@/types';
import {
	createActorEntryFromCloudLassoSelection,
	dracoEncoder,
	float32Concat,
} from '@/utils';

import {PointCloudListItem, type PointCloudListItemProps} from './components';

const modes = ['single', 'multi'] as const;

type Mode = (typeof modes)[number];

export type Props = {
	arePointCloudsDirty: boolean;
	areResectionPlanesDirty: boolean;
	isSegmentationUpdating: boolean;
	globalState: ReturnType<typeof useGlobalState>;
	onEditPointClouds: () => void;
	onSave: () => void;
	vtkState: VtkStateRef;
};

type State = {
	modal: {
		message?: string;
		open: boolean;
	};
	selectedCloud: string;
};

/**
 * @todo Refactor this class component into a function component by moving all
 *       its code into the Lasso function component
 */
export default class LassoClass extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {
			modal: {
				open: false,
			},
			selectedCloud: 'femur',
		};
	}

	closeModal = () => {
		this.setState(({modal: previousModal}) => ({
			modal: {...previousModal, open: false},
		}));
	};

	handleEnabledChange: React.ChangeEventHandler<HTMLInputElement> = ({
		target: {checked: active},
	}) => {
		if (active) {
			cornerstone.lasso.enable({
				vtkState: this.props.vtkState.current,
			});
		} else {
			cornerstone.lasso.disable();
		}
	};

	handleClickDelete = () => {
		if (this.props.globalState.viewports.volumeViewport === undefined) return;

		const pointsCloudsState = [...this.props.vtkState.current.pointCloudActors];

		if (this.visiblePointClouds.length < 1) {
			return;
		}

		this.props.onEditPointClouds();

		const idsToDelete: string[] = [];

		for (let i = 0; i < pointsCloudsState.length; i++) {
			const pointsCloudActor = pointsCloudsState[i].actor;
			if (!pointsCloudActor.getVisibility()) {
				continue;
			}

			// Collect the ids of actors that will be deleted
			idsToDelete.push(pointsCloudsState[i].id);

			this.props.globalState.viewports.volumeViewport.removeVolumeActors([
				pointsCloudsState[i].id,
			]);

			// Find the index in vtkState ref and remove it
			const indexInVtkState =
				this.props.vtkState.current.pointCloudActors.findIndex(
					(pointCloud) => pointCloud.id === pointsCloudsState[i].id,
				);
			if (indexInVtkState !== -1) {
				this.props.vtkState.current.pointCloudActors.splice(indexInVtkState, 1);
			}

			pointsCloudsState.splice(i, 1);
			i--;
		}

		for (const id of idsToDelete) {
			this.props.globalState.densityMap.deletePointCloud({id});
		}

		this.props.globalState.viewports.volumeViewport.render();
	};

	handleClickDownload = () => {
		const pointCloudsState = this.props.vtkState.current.pointCloudActors;
		const data = [];

		for (const pointCloudState of pointCloudsState) {
			const {actor, label} = pointCloudState;

			if (!actor.getVisibility()) {
				continue;
			}

			const pointsCloudMapper = actor.getMapper();
			const pointsCloudPolyData = pointsCloudMapper?.getInputData();

			data.push({
				polyData: pointsCloudPolyData,
				label,
			});
		}

		dracoEncoder.savePointCloudsToDisk(data).catch(console.error);
	};

	handleClickMerge = () => {
		if (
			!isDefined(this.props.globalState.densityMap.threshold.lut) ||
			!isDefined(this.props.globalState.viewports.volumeViewport)
		) {
			return;
		}

		const pointsCloudsState = [...this.props.vtkState.current.pointCloudActors];
		let mergedPointsArray = new Float32Array(0);
		let mergedScalarArray = new Float32Array(0);

		if (this.visiblePointClouds.length < 2) {
			return;
		}

		this.props.onEditPointClouds();

		const actorsToRemove = [];
		const mergedActorIds: string[] = [];

		for (let i = 0; i < pointsCloudsState.length; i++) {
			const pointsCloudActor = pointsCloudsState[i].actor;
			if (!pointsCloudActor.getVisibility()) {
				continue;
			}

			const pointsCloudMapper = pointsCloudActor.getMapper();
			const pointsCloudPolyData = pointsCloudMapper?.getInputData();
			const pointsCloud = pointsCloudPolyData.getPoints();
			const pointsCloudArrayWorld = pointsCloud.getData();
			const scalarCloud = pointsCloudPolyData.getPointData().getScalars();
			const scalarCloudArray = scalarCloud.getData();

			mergedPointsArray = float32Concat(
				mergedPointsArray,
				pointsCloudArrayWorld,
			);
			mergedScalarArray = float32Concat(mergedScalarArray, scalarCloudArray);

			actorsToRemove.push(pointsCloudsState[i].id);
			mergedActorIds.push(pointsCloudsState[i].id);

			const indexInVtkState =
				this.props.vtkState.current.pointCloudActors.findIndex(
					(pointCloud) => pointCloud.id === pointsCloudsState[i].id,
				);
			if (indexInVtkState !== -1) {
				this.props.vtkState.current.pointCloudActors.splice(indexInVtkState, 1);
			}

			pointsCloudsState.splice(i, 1);
			i--;
		}

		if (mergedPointsArray.length !== 0) {
			this.props.globalState.viewports.volumeViewport.removeVolumeActors(
				actorsToRemove,
			);

			const actorEntry = createActorEntryFromCloudLassoSelection({
				baseName: 'merged',
				points: mergedPointsArray,
				scalars: mergedScalarArray,
				// @ts-expect-error -- threshold.lut is defined
				thresholdData: this.props.globalState.densityMap.threshold,
				visibility: true,
				vtkState: this.props.vtkState.current,
			});

			const actorExists = this.props.vtkState.current.pointCloudActors.some(
				(pointCloud) => pointCloud.id === actorEntry.id,
			);

			if (!actorExists) {
				this.props.vtkState.current.pointCloudActors.push(actorEntry);
			}

			this.props.globalState.viewports.volumeViewport.addActors([
				{uid: actorEntry.id, actor: actorEntry.actor},
			]);

			this.props.globalState.densityMap.addPointCloud({
				id: actorEntry.id,
				isEditing: false,
				isVisible: actorEntry.actor.getVisibility(),
				label: actorEntry.label,
			});

			for (const id of mergedActorIds) {
				this.props.globalState.densityMap.deletePointCloud({id});
			}

			this.props.globalState.viewports.volumeViewport.render();
		}
	};

	handleModeChange: ToggleButtonGroupProps<Mode>['onChange'] = (
		_event,
		mode,
	) => {
		if (mode === null) return;

		cornerstone.lasso.setMode({
			mode,
			vtkState: this.props.vtkState.current,
		});
	};

	handlePointCloudEditingChange: (
		id: string,
	) => PointCloudListItemProps['onEditingChange'] = (id) => (isEditing) => {
		this.props.globalState.densityMap.updatePointCloud({id, isEditing});
	};

	handlePointCloudLabelChange: (
		id: string,
	) => PointCloudListItemProps['onLabelChange'] = (id) => (label) => {
		const inUse = this.props.globalState.densityMap.pointClouds
			.filter((cloud) => cloud.id !== id)
			.some((cloud) => cloud.label.toLowerCase() === label.toLowerCase());

		if (inUse) {
			this.setState({
				modal: {
					message: `Another point cloud is already named '${label}'.`,
					open: true,
				},
			});
			return;
		}

		const pointCloud = this.props.vtkState.current.pointCloudActors.find(
			(cloud) => cloud.id === id,
		);

		if (!pointCloud) {
			throw new Error(`Could not find point cloud with id: ${id}`);
		}

		this.props.onEditPointClouds();

		pointCloud.label = label;

		this.props.globalState.densityMap.updatePointCloud({id, label});
	};

	handlePointCloudVisibilityChange: (
		id: string,
	) => PointCloudListItemProps['onVisibilityChange'] = (id) => (isVisible) => {
		if (this.props.globalState.viewports.volumeViewport === undefined) return;

		const actorEntry = this.props.vtkState.current.pointCloudActors.find(
			(actor) => actor.id === id,
		);
		if (!actorEntry) {
			return;
		}

		actorEntry.actor.setVisibility(isVisible);
		this.props.globalState.viewports.volumeViewport.render();

		this.props.globalState.densityMap.updatePointCloud({id, isVisible});
	};

	get editingPointCloud(): PointCloud | undefined {
		return this.props.globalState.densityMap.pointClouds.find(
			(cloud) => cloud.isEditing,
		);
	}

	get visiblePointClouds(): PointCloud[] {
		return this.props.globalState.densityMap.pointClouds.filter(
			(cloud) => cloud.isVisible,
		);
	}

	render() {
		const {globalState} = this.props;
		const {enabled} = globalState.tools.lasso;

		if (this.props.isSegmentationUpdating) {
			return <Loading label="Saving segmentation" size="sm" />;
		}

		if (!isDefined(globalState.densityMap.threshold)) {
			return <Loading label="Loading" size="sm" />;
		}

		if (this.props.areResectionPlanesDirty) {
			return (
				<Alert color="info" showIcon>
					Save or revert your resection plane changes to enable this tool.
				</Alert>
			);
		}

		return (
			<>
				<Modal open={this.state.modal.open}>
					<ModalDialog>
						<DialogContent>
							<Typography>{this.state.modal.message}</Typography>
						</DialogContent>

						<DialogActions>
							<Button onClick={this.closeModal}>OK</Button>
						</DialogActions>
					</ModalDialog>
				</Modal>

				<Stack spacing={4}>
					{/* Point cloud changes alert */}
					{this.props.arePointCloudsDirty && (
						<Alert>
							<Stack spacing={2}>
								<span>You&rsquo;ve made changes to the point clouds.</span>

								<Stack direction="row" spacing={2}>
									<Button
										color="danger"
										onClick={() => {
											location.reload();
										}}
										size="sm"
										variant="outlined"
									>
										Revert Changes
									</Button>
									<Button onClick={this.props.onSave} size="sm">
										Save Changes
									</Button>
								</Stack>
							</Stack>
						</Alert>
					)}

					{/* Lasso tool active switch */}
					<FormControl>
						<FormLabel>Lasso Tool</FormLabel>

						<Switch
							checked={enabled}
							endDecorator={enabled ? 'Enabled' : 'Disabled'}
							onChange={this.handleEnabledChange}
							sx={{alignSelf: 'flex-start'}}
						/>
					</FormControl>

					{/* Lasso tool mode toggle */}
					{enabled && (
						<FormControl>
							<FormLabel>Lasso Tool Mode</FormLabel>

							<ToggleButtonGroup<Mode>
								onChange={this.handleModeChange}
								value={globalState.tools.lasso.mode}
							>
								<Button startDecorator={<LassoModeSingleIcon />} value="single">
									Single
								</Button>
								<Button
									startDecorator={<LassoModeMultipleIcon />}
									value="multi"
								>
									Multi
								</Button>
							</ToggleButtonGroup>

							<FormHelperText>
								{globalState.tools.lasso.mode === 'multi' && (
									<>
										Draw multiple shapes in the 3D viewport, then press Enter to
										generate a new point cloud.
									</>
								)}
								{globalState.tools.lasso.mode === 'single' && (
									<>
										Draw a shape in the 3D viewport to generate a new point
										cloud.
									</>
								)}
							</FormHelperText>
						</FormControl>
					)}

					{/* Point clouds list */}
					<Stack spacing={1}>
						<Typography level="title-sm">Point Clouds</Typography>

						<List>
							{globalState.densityMap.pointClouds.map(
								({id, isVisible, label}) => {
									const editingOtherPointCloud = Boolean(
										this.editingPointCloud &&
											this.editingPointCloud.label !== label,
									);

									return (
										<PointCloudListItem
											editingDisabled={editingOtherPointCloud}
											isVisible={isVisible}
											key={id}
											label={label}
											onEditingChange={this.handlePointCloudEditingChange(id)}
											onLabelChange={this.handlePointCloudLabelChange(id)}
											onVisibilityChange={this.handlePointCloudVisibilityChange(
												id,
											)}
										/>
									);
								},
							)}

							{/* New point cloud */}
							{globalState.densityMap.newPointCloudProgress !== undefined && (
								<ListItem>
									<ListItemDecorator>
										<AspectRatio ratio="1/1" variant="plain" sx={{width: 32}}>
											<CircularProgress
												determinate
												size="sm"
												value={globalState.densityMap.newPointCloudProgress}
											/>
										</AspectRatio>
									</ListItemDecorator>

									<ListItemContent>New point cloud</ListItemContent>
								</ListItem>
							)}
						</List>
					</Stack>

					{/* Point cloud operations */}
					<Stack spacing={1}>
						<Typography level="title-sm">Point Cloud Operations</Typography>

						<Stack spacing={1}>
							<Button
								disabled={this.visiblePointClouds.length < 2}
								onClick={this.handleClickMerge}
								variant="outlined"
							>
								Merge{' '}
								{this.visiblePointClouds.length >= 2 && `visible point clouds`}
							</Button>
							<Button
								disabled={this.visiblePointClouds.length < 1}
								onClick={this.handleClickDownload}
								variant="outlined"
							>
								Download{' '}
								{this.visiblePointClouds.length === 1 &&
									this.visiblePointClouds[0].label}
								{this.visiblePointClouds.length >= 2 && `visible point clouds`}
							</Button>
							<Button
								color="danger"
								disabled={this.visiblePointClouds.length < 1}
								onClick={this.handleClickDelete}
								variant="outlined"
							>
								Delete{' '}
								{this.visiblePointClouds.length === 1 &&
									this.visiblePointClouds[0].label}
								{this.visiblePointClouds.length >= 2 && `visible point clouds`}
							</Button>
						</Stack>
					</Stack>
				</Stack>
			</>
		);
	}
}
