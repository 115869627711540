/**
 * Check if a point is inside a polygon.
 * https://wrfranklin.org/Research/Short_Notes/pnpoly.html
 *
 * @param {Array[2]} p
 * @param {Array[Array[2]]} polygon
 * @returns boolean
 */
function pointIsInPoly(p, polygon) {
	let isInside = false;
	let minX = polygon[0][0];
	let maxX = polygon[0][0];
	let minY = polygon[0][1];
	let maxY = polygon[0][1];

	for (let n = 1; n < polygon.length; n++) {
		const q = polygon[n];
		minX = Math.min(q[0], minX);
		maxX = Math.max(q[0], maxX);
		minY = Math.min(q[1], minY);
		maxY = Math.max(q[1], maxY);
	}

	if (p[0] < minX || p[0] > maxX || p[1] < minY || p[1] > maxY) {
		return false;
	}

	let i = 0;
	let j = polygon.length - 1;

	for (i, j; i < polygon.length; j = i++) {
		if (
			polygon[i][1] > p[1] !== polygon[j][1] > p[1] &&
			p[0] <
				((polygon[j][0] - polygon[i][0]) * (p[1] - polygon[i][1])) /
					(polygon[j][1] - polygon[i][1]) +
					polygon[i][0]
		) {
			isInside = !isInside;
		}
	}

	return isInside;
}

export default pointIsInPoly;
