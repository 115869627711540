import {
	Button,
	Card,
	CircularProgress,
	type ColorPaletteProp,
	Container,
	Stack,
	Typography,
} from '@mui/joy';
import {FirebaseError} from 'firebase/app';
import {ref, uploadBytesResumable, getDownloadURL} from 'firebase/storage';
import React, {useState} from 'react';

import {Alert, FileInput} from '@/components';
import {useFirebaseAuthState, useTitle} from '@/hooks';
import {api} from '@/library';
import {firestore, storageInstance} from '@/library/firebase';
import {type Scan} from '@/library/models';

export default function NewScan() {
	useTitle('New Scan');

	const [firebaseAuthUser] = useFirebaseAuthState();
	const [alert, setAlert] = useState<
		{color: ColorPaletteProp; message: string} | undefined
	>(undefined);
	const [createdScanId, setCreatedScanId] = useState<string | undefined>(
		undefined,
	);
	const [file, setFile] = useState<File | undefined>(undefined);
	const [uploadProgress, setUploadProgress] = useState<number | undefined>(
		undefined,
	);

	const uploading = uploadProgress !== undefined;

	const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (
		event,
	) => {
		event.preventDefault();

		if (!file || !firebaseAuthUser) return;

		setUploadProgress(0);

		const ownerUid = firebaseAuthUser.uid;
		const scan: Scan = await firestore.createScan({ownerUid});
		const metadata = {contentType: 'application/zip'};
		const scanStoragePath = `${ownerUid}/scans/${scan.id}`;
		const dicomStoragePath = `${scanStoragePath}/DICOM.zip`;
		const storageRef = ref(storageInstance, dicomStoragePath);
		const gcpUrl = `https://console.cloud.google.com/storage/browser/hermes-storage--${process.env.GOOGLE_CLOUD_ENVIRONMENT}/${scanStoragePath}`;
		const encodedPath =
			`environments/${process.env.CLOUD_ENVIRONMENT}/scans/${scan.id}`.replace(
				/\//g,
				'~2F',
			);
		const firestoreUrl = `https://console.firebase.google.com/u/0/project/${process.env.GOOGLE_CLOUD_PROJECT}/firestore/data/${encodedPath}`;
		const uploadTask = uploadBytesResumable(storageRef, file, metadata);

		uploadTask.on(
			'state_changed',

			// Next
			(snapshot) => {
				setUploadProgress(
					(snapshot.bytesTransferred / snapshot.totalBytes) * 100,
				);
			},

			// Error
			async (error) => {
				setUploadProgress(undefined);
				console.error('Upload failed with error:', error);
				setAlert({
					color: 'danger',
					message: `Upload failed with error: ${error.code}`,
				});

				try {
					await firestore.deleteScan({id: scan.id});
				} catch (deleteError) {
					if (deleteError instanceof FirebaseError) {
						console.error('Failed to delete scan:', deleteError);
						setAlert({
							color: 'danger',
							message: `Failed to clean up after upload error: ${deleteError.code}`,
						});
					} else {
						console.error('An unexpected error occurred:', deleteError);
					}
				}
			},

			// Complete
			async () => {
				try {
					const downloadUrl = await getDownloadURL(uploadTask.snapshot.ref);

					await firestore.updateScan({
						id: scan.id,
						state: 'inputsUploaded',
					});

					await api.slack.post({
						webhook:
							'https://hooks.slack.com/services/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',
						message: `--------------------------------\nNew Scan Uploaded: \`${scan.id}\` \n\nURL: ${firestoreUrl}\n\nStorage URL: ${gcpUrl} \n\nDownload URL: ${downloadUrl}`,
					});

					setAlert({
						color: 'success',
						message: `Scan created from ZIP file '${file.name}'`,
					});
					setCreatedScanId(scan.id);
					setFile(undefined);
				} catch (downloadError) {
					if (downloadError instanceof FirebaseError) {
						console.error('Failed to get download URL:', downloadError);
						setAlert({
							color: 'danger',
							message: `Failed to retrieve download URL: ${downloadError.code}`,
						});
					} else {
						console.error('An unexpected error occurred:', downloadError);
					}
				}

				setUploadProgress(undefined);
			},
		);
	};

	return (
		<Container maxWidth="sm">
			<Card>
				<Stack spacing={2}>
					<Typography level="title-lg">Upload New Scan</Typography>

					<Typography>
						Please organize all the DICOM files for a scan into a single ZIP
						file and upload below.
					</Typography>

					<Typography>
						We will notify you when the scan is ready to view.
					</Typography>

					<Stack component="form" onSubmit={handleSubmit} spacing={2}>
						<FileInput
							accept={{
								/* eslint-disable-next-line @typescript-eslint/naming-convention --
								 * We don't control this
								 */
								'application/zip': ['.zip'],
							}}
							disabled={uploading}
							maxFiles={1}
							multiple={false}
							onChange={([file]) => {
								setFile(file);
							}}
						/>

						<Button
							disabled={!file}
							loading={uploading}
							loadingIndicator={
								<CircularProgress determinate value={uploadProgress} />
							}
							loadingPosition="start"
							sx={{alignSelf: 'flex-start'}}
							type="submit"
						>
							{uploading ? 'Uploading' : 'Upload'}
						</Button>
					</Stack>

					{alert && (
						<Alert
							color={alert.color}
							endDecorator={
								createdScanId ? (
									<Stack direction="row" spacing={2}>
										<Button
											color="success"
											onClick={() => {
												location.reload();
											}}
											size="sm"
											variant="outlined"
										>
											Upload another
										</Button>
										<Button
											color="success"
											component="a"
											href={`/scans/${createdScanId}`}
											size="sm"
										>
											View scan
										</Button>
									</Stack>
								) : null
							}
							showIcon
						>
							{alert.message}
						</Alert>
					)}
				</Stack>
			</Card>
		</Container>
	);
}
