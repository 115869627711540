import {init as csRenderInit} from '@cornerstonejs/core';
import {init as csToolsInit} from '@cornerstonejs/tools';

import initCornerstoneWadoImageLoader from './initCornerstoneWadoImageLoader';
import initProviders from './initProviders';
import initVolumeLoader from './initVolumeLoader';

export default async function initDemo() {
	initProviders();
	initCornerstoneWadoImageLoader();
	initVolumeLoader();
	await csRenderInit();
	csToolsInit();
}
