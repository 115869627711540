import {create} from 'zustand';
import {immer} from 'zustand/middleware/immer';

import {type DigitalTwinMode, type DigitalTwinPolygonData} from '@/types';

export type State = {
	areDigitalTwinsDirty: boolean;
	digitalTwins: DigitalTwinPolygonData[];
	mode: DigitalTwinMode;
	selectedDigitalTwinId: string | undefined;
	state: 'ready' | 'loading' | 'updating' | 'error';
};

type Actions = {
	setAreDigitalTwinsDirty: (areDigitalTwinsDirty: boolean) => void;
	setDigitalTwin: (digitalTwin: DigitalTwinPolygonData) => void;
	setDigitalTwins: (digitalTwins: DigitalTwinPolygonData[]) => void;
	setMode: (mode: DigitalTwinMode) => void;
	setSelectedDigitalTwinId: (digitalTwinId: string) => void;
	setState: (state: State['state']) => void;
};

export const useDigitalTwinsStore = create(
	immer<State & Actions>((set) => ({
		areDigitalTwinsDirty: false,
		digitalTwins: [],
		mode: 'remaining',
		selectedDigitalTwinId: undefined,
		setAreDigitalTwinsDirty(areDigitalTwinsDirty) {
			set((state) => {
				state.areDigitalTwinsDirty = areDigitalTwinsDirty;
			});
		},
		setDigitalTwin(digitalTwin) {
			set((state) => {
				const index = state.digitalTwins.findIndex(
					(dt) => dt.id === digitalTwin.id,
				);
				if (index !== -1) {
					state.digitalTwins[index] = digitalTwin;
				}
			});
		},
		setDigitalTwins(digitalTwins) {
			set((state) => {
				state.digitalTwins = digitalTwins;
			});
		},
		setMode(mode) {
			set((state) => {
				state.mode = mode;
			});
		},
		setSelectedDigitalTwinId(id) {
			set((state) => {
				state.selectedDigitalTwinId = id;
			});
		},
		setState(newState: State['state']) {
			set((state) => {
				state.state = newState;
			});
		},
		state: 'loading',
	})),
);
