import {Button, List, Modal, ModalDialog, Typography} from '@mui/joy';
import {capitalCase} from 'change-case';
import {CrosshairIcon, SettingsIcon} from 'lucide-react';
import React, {useState} from 'react';
import {type Entries} from 'type-fest';

import {useCustomDefaultsState, useTools} from '@/hooks';
import {isDefined} from '@/library';
import {type Scan} from '@/library/models';
import {useGlobalState} from '@/state';
import {customDefaultsXstateActor} from '@/state/machines/custom-defaults';

import {Divider, Item} from './components';
import {useCrosshairs} from './hooks';

type Props = {
	scan?: Scan;
};

export default function ToolBar({scan}: Props) {
	const {
		tools: {active: activeTool, setActive: setActiveTool},
	} = useGlobalState();
	const [areCrosshairsActive, toggleAreCrosshairsActive] = useCrosshairs();
	const tools = useTools({scan});
	const [modalMessage, setModalMessage] = useState<string | undefined>();
	const customDefaultsState = useCustomDefaultsState();

	const labels = {
		crosshairs: `${areCrosshairsActive ? 'Disable' : 'Enable'} Crosshairs`,
		customDefaults: `${
			customDefaultsState === 'active' ? 'Cancel editing' : 'Edit'
		} Custom Defaults`,
	};

	function handleCustomDefaultsClick() {
		const enablingEditing = customDefaultsState === 'inactive';

		if (enablingEditing) {
			if (
				isDefined(activeTool) &&
				!tools.tools[activeTool].hasCustomizableDefaults
			) {
				setActiveTool(undefined);
			}

			customDefaultsXstateActor.send({type: 'activate'});
		} else {
			customDefaultsXstateActor.send({type: 'deactivate'});
		}
	}

	if (!scan) return null;

	return (
		<>
			<Modal open={isDefined(modalMessage)}>
				<ModalDialog sx={{alignItems: 'center'}}>
					<Typography>{modalMessage}</Typography>
					<Button
						onClick={() => {
							setModalMessage(undefined);
						}}
					>
						OK
					</Button>
				</ModalDialog>
			</Modal>

			<List orientation="horizontal" size="sm">
				{/* Crosshairs */}
				<Item
					aria-label={labels.crosshairs}
					onClick={toggleAreCrosshairsActive}
					selected={areCrosshairsActive}
					title={labels.crosshairs}
				>
					<CrosshairIcon />
				</Item>

				<Divider />

				{(Object.entries(tools.tools) as Entries<typeof tools.tools>).map(
					([
						toolName,
						{
							disableAt: milestoneToDisableAt,
							enableAt: milestoneToEnableAt,
							hasCustomizableDefaults,
							Icon,
						},
					]) => {
						const label = capitalCase(toolName);

						const disabled =
							(customDefaultsState !== 'inactive' &&
								!hasCustomizableDefaults) ||
							!scan.hasReachedMilestone(milestoneToEnableAt) ||
							(milestoneToDisableAt !== undefined &&
								scan.hasReachedMilestone(milestoneToDisableAt));

						return (
							<Item
								aria-label={label}
								disabled={disabled}
								key={toolName}
								onClick={() => {
									const {notAllowedReason} = tools.setTool(toolName);

									if (notAllowedReason) setModalMessage(notAllowedReason);
								}}
								selected={activeTool === toolName}
								title={label}
							>
								<Icon />
							</Item>
						);
					},
				)}

				<Divider />

				<Item
					aria-label={labels.customDefaults}
					onClick={handleCustomDefaultsClick}
					selected={customDefaultsState !== 'inactive'}
					title={labels.customDefaults}
				>
					<SettingsIcon />
				</Item>
			</List>
		</>
	);
}
