import delay from 'delay';
import {useEffect} from 'react';

import {createDigitalTwin} from '@/library';
import {type Scan} from '@/library/models';
import {useGlobalState} from '@/state';
import {
	type DigitalTwinPolygonData,
	type VtkStateRef,
	isDrillData,
	isReamData,
	isResectData,
} from '@/types';

const useLoadDigitalTwins = ({
	arePointCloudsLoaded,
	scan,
	vtkState,
}: {
	arePointCloudsLoaded: boolean;
	scan: Scan;
	vtkState: VtkStateRef;
}) => {
	const {
		digitalTwins: {
			digitalTwins,
			setDigitalTwins,
			setSelectedDigitalTwinId,
			setState,
			state,
		},
		resectionPlanes,
		viewports: {volumeViewport},
		visibility,
	} = useGlobalState();

	useEffect(() => {
		async function loadDigitalTwins() {
			setState('updating');

			await delay(1);

			const digitalTwins: DigitalTwinPolygonData[] = [];

			scan.digitalTwins.forEach((digitalTwin) => {
				let newDigitalTwin;

				if (isResectData(digitalTwin)) {
					newDigitalTwin = createDigitalTwin({
						areDigitalTwinsVisible: visibility.digitalTwins,
						areResectionPlanesVisible: resectionPlanes.visibility,
						bone: digitalTwin.bone,
						depth: digitalTwin.depth,
						depthDirection: digitalTwin.depthDirection,
						height: digitalTwin.height,
						heightDirection: digitalTwin.heightDirection,
						id: digitalTwin.id,
						label: digitalTwin.label,
						opacity: digitalTwin.opacity,
						position: digitalTwin.position,
						renderer: volumeViewport?.getRenderer(),
						rotation: digitalTwin.rotation,
						type: digitalTwin.type,
						vtkState,
						width: digitalTwin.width,
						widthDirection: digitalTwin.widthDirection,
					});
				} else if (isDrillData(digitalTwin)) {
					newDigitalTwin = createDigitalTwin({
						areDigitalTwinsVisible: visibility.digitalTwins,
						areResectionPlanesVisible: resectionPlanes.visibility,
						bone: digitalTwin.bone,
						height: digitalTwin.height,
						id: digitalTwin.id,
						label: digitalTwin.label,
						opacity: digitalTwin.opacity,
						position: digitalTwin.position,
						radius: digitalTwin.radius,
						renderer: volumeViewport?.getRenderer(),
						rotation: digitalTwin.rotation,
						type: digitalTwin.type,
						vtkState,
					});
				} else if (isReamData(digitalTwin)) {
					newDigitalTwin = createDigitalTwin({
						areDigitalTwinsVisible: visibility.digitalTwins,
						areResectionPlanesVisible: resectionPlanes.visibility,
						bone: digitalTwin.bone,
						id: digitalTwin.id,
						label: digitalTwin.label,
						opacity: digitalTwin.opacity,
						position: digitalTwin.position,
						radius: digitalTwin.radius,
						renderer: volumeViewport?.getRenderer(),
						type: digitalTwin.type,
						vtkState,
					});
				} else {
					throw new Error('Invalid digital twin type');
				}

				digitalTwins.push(newDigitalTwin);

				setSelectedDigitalTwinId(newDigitalTwin.id);
			});

			setDigitalTwins(digitalTwins);

			volumeViewport?.render();

			setState('ready');
		}

		if (
			state === 'loading' &&
			volumeViewport &&
			arePointCloudsLoaded &&
			scan.digitalTwins?.length
		) {
			loadDigitalTwins().catch(console.error);
		}
	}, [
		arePointCloudsLoaded,
		digitalTwins,
		scan.digitalTwins,
		setState,
		state,
		volumeViewport,
	]);
};

export default useLoadDigitalTwins;
