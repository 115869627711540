import {create} from 'zustand';
import {immer} from 'zustand/middleware/immer';
import {initialResectionPlanesAdjustments} from '@/library/models/scan';
import {
	type Axis,
	type ResectionPlaneProperty,
	type ResectionPlaneKey,
	type ResectionPlanePairKey,
	type ResectionPlanesState,
} from '@/types';

export type State = {
	adjustments: ResectionPlanesState;
	dirtyProperty?: ResectionPlaneProperty;
	selectedPairKey: ResectionPlanePairKey;
	selectedPlaneKey: ResectionPlaneKey;
	state: 'loading' | 'initialRendering' | 'updating' | 'ready' | 'error';
	visibility: boolean;
};

type Actions = {
	setAdjustment: (parameters: {
		axis: Axis;
		pair: ResectionPlanePairKey;
		plane: ResectionPlaneKey;
		property: ResectionPlaneProperty;
		value: number;
	}) => void;
	setAdjustments: (adjustments: ResectionPlanesState) => void;
	setDirtyProperty: (property?: ResectionPlaneProperty) => void;
	setSelectedPairKey: (key: ResectionPlanePairKey) => void;
	setSelectedPlaneKey: (key: ResectionPlaneKey) => void;
	setState: (state: State['state']) => void;
	setVisibility: (visibility: boolean) => void;
};

export const useResectionPlanesStore = create<State & Actions>()(
	immer((set) => ({
		adjustments: initialResectionPlanesAdjustments,
		selectedPairKey: 'anatomical',
		selectedPlaneKey: 'femoral',
		setAdjustment({axis, pair, plane, property, value}) {
			set((state) => {
				state.adjustments[pair][plane][property][axis] = value;
			});
		},
		setAdjustments(resectionPlanesAdjustments) {
			set((state) => {
				state.adjustments = resectionPlanesAdjustments;
			});
		},
		setDirtyProperty(property) {
			set((state) => {
				state.dirtyProperty = property;
			});
		},
		setSelectedPairKey(selectedResectionPlanePair) {
			set((state) => {
				state.selectedPairKey = selectedResectionPlanePair;
			});
		},
		setSelectedPlaneKey(selectedResectionPlane) {
			set((state) => {
				state.selectedPlaneKey = selectedResectionPlane;
			});
		},
		setState(newState: State['state']) {
			set((state) => {
				state.state = newState;
			});
		},
		setVisibility(visibility) {
			set((state) => {
				state.visibility = visibility;
			});
		},
		state: 'loading',
		visibility: true,
	})),
);
