import {
	Button,
	Card,
	type ColorPaletteProp,
	Container,
	FormControl,
	FormLabel,
	Input,
	Stack,
	Typography,
} from '@mui/joy';
import React, {useState} from 'react';
import {useForm} from 'react-hook-form';
import {type LoaderFunction, redirect} from 'react-router-dom';
import {useImmer} from 'use-immer';

import {Snackbar} from '@/components';
import {useTitle} from '@/hooks';
import {firebase} from '@/library';

export const loader: LoaderFunction = async () => {
	const user = await firebase.auth.getCurrentUser();

	if (user) {
		return redirect('/scans');
	}

	return null;
};

export default function LogIn() {
	useTitle('Log In');

	const form = useForm<{email: string; password: string}>();
	const [snackbar, updateSnackbar] = useImmer<{
		color?: ColorPaletteProp;
		message?: string;
		open: boolean;
	}>({open: false});
	const [submitting, setSubmitting] = useState<boolean>(false);

	function closeSnackbar() {
		updateSnackbar({open: false});
	}

	const handleSubmit = form.handleSubmit(async ({email, password}) => {
		setSubmitting(true);

		try {
			await firebase.auth.signInWithEmailAndPassword(email, password);

			const savedRedirectPath = localStorage.getItem('redirectPath');
			const redirectPath = savedRedirectPath
				? JSON.parse(savedRedirectPath)
				: '/scans';

			localStorage.removeItem('redirectPath');

			updateSnackbar({
				color: 'success',
				message: 'Log-in succeeded',
				open: true,
			});

			window.location.href = redirectPath;
		} catch (error) {
			updateSnackbar({
				color: 'danger',
				message: 'Log-in failed',
				open: true,
			});
			console.error(error);
		} finally {
			setSubmitting(false);
		}
	});

	return (
		<>
			<Snackbar
				color={snackbar.color}
				onClose={closeSnackbar}
				open={snackbar.open}
				showIcon
			>
				{snackbar.message}
			</Snackbar>

			<Container maxWidth="xs">
				<Card>
					<Stack spacing={2}>
						<Typography level="title-lg">Log in</Typography>

						<Stack component="form" onSubmit={handleSubmit} spacing={2}>
							<FormControl disabled={submitting} required>
								<FormLabel>Email</FormLabel>
								<Input
									autoComplete="email"
									type="email"
									{...form.register('email')}
								/>
							</FormControl>

							<FormControl disabled={submitting} required>
								<FormLabel>Password</FormLabel>
								<Input
									autoComplete="current-password"
									type="password"
									{...form.register('password')}
								/>
							</FormControl>

							<Button
								loading={submitting}
								loadingPosition="start"
								sx={{alignSelf: 'flex-start'}}
								type="submit"
							>
								{submitting ? 'Logging in' : 'Log in'}
							</Button>
						</Stack>
					</Stack>
				</Card>
			</Container>
		</>
	);
}
