import {type VtkStateRef} from '@/types';

import {
	useSetLandmarksVisibility,
	useSetMeshesVisibility,
	useSetResectionPlanesVisibility,
	useSetSegmentationOverlaysVisibility,
} from './hooks';

export default function useSetVisibility({vtkState}: {vtkState: VtkStateRef}) {
	useSetLandmarksVisibility({vtkState});
	useSetMeshesVisibility({vtkState});
	useSetResectionPlanesVisibility({vtkState});
	useSetSegmentationOverlaysVisibility();
}
