import {
	Button as DefaultButton,
	type ButtonProps as DefaultButtonProps,
	IconButton,
	type IconButtonProps,
	ToggleButtonGroup,
	type ToggleButtonGroupProps,
	Tooltip,
} from '@mui/joy';
import React, {type ReactNode} from 'react';

export type Props = {
	checked?: boolean;
	children?: ReactNode;
	disabled?: boolean;
	icon?: boolean;
	onChange?: (event: React.MouseEvent<HTMLElement>, checked: boolean) => void;
	size?: ToggleButtonGroupProps<'checked' | 'unchecked'>['size'];
	tooltipTitle?: string;
};

/**
 * Single MUI [Toggle Button]{@link https://mui.com/joy-ui/react-toggle-button-group/}
 */

export default function ToggleButton({
	checked,
	children,
	disabled,
	icon,
	onChange,
	size,
	tooltipTitle,
}: Props) {
	const Button = (icon ? IconButton : DefaultButton) as React.ComponentType<
		IconButtonProps | DefaultButtonProps
	>;

	const button = <Button value="checked">{children}</Button>;

	return (
		<ToggleButtonGroup<'checked' | 'unchecked'>
			disabled={disabled}
			onChange={(event, value) => {
				onChange?.(event, value === 'checked');
			}}
			size={size}
			value={checked ? 'checked' : 'unchecked'}
		>
			{tooltipTitle ? <Tooltip title={tooltipTitle}>{button}</Tooltip> : button}
		</ToggleButtonGroup>
	);
}
