import {type Scan} from '@/library/models';
import {useGlobalState} from '@/state';
import {type State as DigitalTwinsState} from '@/state/digital-twins';
import {type State as ResectionPlanesState} from '@/state/resection-planes';

type ProgressChunk = {
	progress: number;
};

type UseViewportLoadingProgressProps = {
	areLandmarksLoaded: boolean;
	areMasksLoaded: boolean;
	densityMapState: string;
	dicomImageLoadingProgress: number;
	digitalTwinsState: DigitalTwinsState['state'];
	resectionPlanesState: ResectionPlanesState['state'];
	scan: Scan;
};

type ViewportLoadingProgress = {
	volumeViewport: number;
	twoDimensionalViewport: number;
};

export function useViewportLoadingProgress({
	areLandmarksLoaded,
	areMasksLoaded,
	densityMapState,
	dicomImageLoadingProgress,
	digitalTwinsState,
	resectionPlanesState,
	scan,
}: UseViewportLoadingProgressProps): ViewportLoadingProgress {
	const {meshes} = useGlobalState();

	function calculateProgress({chunks}: {chunks: ProgressChunk[]}): number {
		const progress = chunks.reduce((sum, chunk) => sum + chunk.progress, 0);
		const total = chunks.length;

		return (progress / total) * 100;
	}

	function calculateVolumeViewportLoadingProgress() {
		const chunks: ProgressChunk[] = [];

		if (scan.hasReachedMilestone('kneeSegmented')) {
			chunks.push(
				{progress: meshes.loaded ? 1 : 0},
				{progress: ['loading', 'updating'].includes(densityMapState) ? 0 : 1},
			);
		}

		if (scan.hasReachedMilestone('landmarked')) {
			chunks.push(
				{progress: meshes.loaded ? 1 : 0},
				{
					progress: ['loading', 'updating'].includes(resectionPlanesState)
						? 0
						: 1,
				},
				{progress: digitalTwinsState === 'updating' ? 0 : 1},
			);
		}

		return calculateProgress({
			chunks,
		});
	}

	function calculate2dViewportLoadingProgress() {
		const chunks: ProgressChunk[] = [];

		if (scan.hasReachedMilestone('prepared')) {
			chunks.push({progress: dicomImageLoadingProgress});
		}

		if (scan.hasReachedMilestone('kneeSegmented')) {
			chunks.push({progress: areMasksLoaded ? 1 : 0});
		}

		if (scan.hasReachedMilestone('landmarked')) {
			chunks.push({progress: areLandmarksLoaded ? 1 : 0});
		}

		return calculateProgress({chunks});
	}

	return {
		volumeViewport: calculateVolumeViewportLoadingProgress(),
		twoDimensionalViewport: calculate2dViewportLoadingProgress(),
	};
}

export default useViewportLoadingProgress;
