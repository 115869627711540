import delay from 'delay';
import {useEffect, useRef} from 'react';

import {
	getPointCloudColorScheme,
	isDefined,
	setPointCloudColorScheme,
} from '@/library';
import {useGlobalState} from '@/state';
import {type VtkStateRef} from '@/types';

export default function useSetColorScheme({vtkState}: {vtkState: VtkStateRef}) {
	const {
		customDefaults,
		densityMap,
		user: {user},
	} = useGlobalState();

	// Local state as ref to prevent async effect from running multiple times
	const applied = useRef<boolean>(false);

	useEffect(() => {
		async function apply() {
			if (applied.current || densityMap.state !== 'ready' || !isDefined(user)) {
				return;
			}

			applied.current = true;

			const colorSchemeId = user.customDefaults?.densityMap?.colorScheme;

			if (
				isDefined(colorSchemeId) &&
				colorSchemeId !== densityMap.colorScheme.id
			) {
				const colorScheme = getPointCloudColorScheme({id: colorSchemeId});

				densityMap.setState('updating');

				await delay(1); // Force re-render

				const {globalLut} = setPointCloudColorScheme({
					colorScheme,
					threshold: densityMap.threshold,
					vtkState: vtkState.current,
				});

				densityMap.setColorScheme(colorScheme);
				densityMap.updateThreshold({
					lut: globalLut,
				});
				densityMap.setState('ready');
			}

			customDefaults.densityMap.colorScheme.setApplied(true);
		}

		apply().catch(console.error);
	}, [customDefaults, densityMap, user]);
}
