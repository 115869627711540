import {type VtkState} from '@/types';

import * as cornerstone from './cornerstone';
import * as vtk from './vtk';

export function setVisibility({
	visibility,
	vtkState,
}: {
	visibility: boolean;
	vtkState: VtkState;
}) {
	cornerstone.landmarks.setVisibility(visibility);
	vtk.landmarks.setVisibility({visibility, vtkState});
}
