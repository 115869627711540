import vtkSphereSource from '@kitware/vtk.js/Filters/Sources/SphereSource';
import vtkActor from '@kitware/vtk.js/Rendering/Core/Actor';
import vtkMapper from '@kitware/vtk.js/Rendering/Core/Mapper';

import {ActorEntry} from '@/library';
import {type Landmark} from '@/types';

const sphereRadius = 2;
const sphereColor = [1, 1, 1];
const opacity = 0.75;

export const parseLandmarksCsv = (csv: string): Landmark[] => {
	const lines = csv.replaceAll('"', '').split('\n');

	return lines
		.filter((line) => {
			const items = line.split(',');
			// Items[0] is expected to be id, so we check if it's truthy (not empty or undefined)
			// items[1], items[2], and items[3] are expected to be the coordinates, so we check if they're not NaN when parsed as numbers
			return (
				items[0] &&
				!isNaN(Number(items[1])) &&
				!isNaN(Number(items[2])) &&
				!isNaN(Number(items[3]))
			);
		})
		.map((line) => {
			const items = line.split(',');
			const id = items[0];
			return {
				id,
				center: [Number(items[1]), Number(items[2]), Number(items[3])],
			};
		});
};

/**
 * Note that this expects a certain form of the CSV (the one provided by vent-creativity)
 */
export function createLandmarkerActorEntries(
	landmarks: Landmark[],
): ActorEntry[] {
	const actorEntries: ActorEntry[] = [];

	for (const landmark of landmarks) {
		const actor = getSphereSourceActor(landmark);
		const actorEntry = new ActorEntry(actor, landmark.id, landmark.id);

		actorEntries.push(actorEntry);
	}

	return actorEntries;
}

function getSphereSourceActor(landmark: Landmark) {
	const sphereSource = vtkSphereSource.newInstance({
		radius: sphereRadius,
		thetaResolution: 16,
		phiResolution: 16,
		center: landmark.center,
	});
	const actor = vtkActor.newInstance();
	const mapper = vtkMapper.newInstance();

	actor.getProperty().setEdgeVisibility(false);
	actor.getProperty().setOpacity(opacity);
	actor.getProperty().setColor(sphereColor[0], sphereColor[1], sphereColor[2]);

	mapper.setInputConnection(sphereSource.getOutputPort());
	actor.setMapper(mapper);

	return actor;
}
