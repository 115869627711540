import {
	Button,
	FormControl,
	FormLabel,
	Input,
	Slider,
	Stack,
	ToggleButtonGroup,
	type ToggleButtonGroupProps,
	Typography,
} from '@mui/joy';
import is from '@sindresorhus/is';
import alphaSort from 'alpha-sort';
import {capitalCase} from 'change-case';
import React from 'react';

import {Alert, Loading, NumberInput, SliderAndNumberInput} from '@/components';
import {resectionPlanePosition, resectionPlaneRotation} from '@/constants';
import {useCustomDefaultsState} from '@/hooks';
import {
	calculateLateralDistance,
	resectionPlaneAdjustmentLabels,
	setSelectedResectionPlane,
	setSelectedResectionPlanePair,
	updateResectionPlaneProperty,
} from '@/library';
import {type Scan} from '@/library/models';
import {useGlobalState} from '@/state';
import {
	type Axis,
	type ResectionPlaneKey,
	type ResectionPlanePairKey,
	type VtkStateRef,
} from '@/types';

type InputConfiguration = {
	axis: Axis;
	label: string;
};

type Props = {
	arePointCloudsDirty: boolean;
	areResectionPlanesDirty: boolean;
	isLoading: boolean;
	isSegmentationUpdating: boolean;
	onSaveChanges: () => void;
	scan: Scan;
	vtkState: VtkStateRef;
};

export default function ResectionPlanes({
	arePointCloudsDirty,
	areResectionPlanesDirty,
	isLoading,
	isSegmentationUpdating,
	onSaveChanges,
	scan,
	vtkState,
}: Props) {
	const customDefaultsState = useCustomDefaultsState();
	const {
		resectionPlanes: {
			adjustments,
			selectedPairKey,
			selectedPlaneKey,
			visibility: resectionPlanesVisibility,
		},
		scan: {landmarks},
	} = useGlobalState();

	const selectedPlane =
		scan.resectionPlanes?.[selectedPairKey][selectedPlaneKey];
	const selectedPlaneAdjustment =
		adjustments[selectedPairKey][selectedPlaneKey];

	const handleSelectedPairChange: ToggleButtonGroupProps<ResectionPlanePairKey>['onChange'] =
		async (_, newResectionPlanePair) => {
			if (
				scan.resectionPlanes === undefined ||
				newResectionPlanePair === null
			) {
				return;
			}

			await setSelectedResectionPlanePair({
				pair: newResectionPlanePair,
				vtkState,
			});
		};

	const handleSelectedPlaneChange: ToggleButtonGroupProps<ResectionPlaneKey>['onChange'] =
		async (_, newResectionPlane) => {
			if (newResectionPlane === null) return;

			await setSelectedResectionPlane({plane: newResectionPlane, vtkState});
		};

	const positionInputs: InputConfiguration[] = [
		{
			axis: 'z',
			label: resectionPlaneAdjustmentLabels.position.z.short,
		},
	];

	if (customDefaultsState === 'inactive') {
		positionInputs.unshift(
			{
				axis: 'x',
				label: resectionPlaneAdjustmentLabels.position.x.short,
			},
			{
				axis: 'y',
				label: resectionPlaneAdjustmentLabels.position.y.short,
			},
		);
	}

	const rotationInputs: InputConfiguration[] =
		customDefaultsState === 'inactive'
			? [
					{
						axis: 'x',
						label: resectionPlaneAdjustmentLabels.rotation.x.short,
					},
					{
						axis: 'y',
						label: resectionPlaneAdjustmentLabels.rotation.y.short,
					},
			  ]
			: [];

	if (arePointCloudsDirty) {
		return (
			<Alert color="info" showIcon>
				Save or revert your point cloud changes to enable this tool.
			</Alert>
		);
	}

	if (!resectionPlanesVisibility) {
		return (
			<Alert color="info" showIcon>
				Enable resection planes visibility to enable this tool.
			</Alert>
		);
	}

	if (
		isLoading ||
		scan.resectionPlanes === undefined ||
		selectedPlane === undefined
	) {
		return <Loading label="Loading" size="sm" />;
	}

	if (isSegmentationUpdating) {
		return <Loading label="Saving segmentation" size="sm" />;
	}

	return (
		<Stack spacing={4}>
			{/* Review warning alert */}
			{customDefaultsState === 'inactive' && (
				<Alert color="warning" showIcon>
					Please review all landmarks for accurate placement before reviewing
					the preferred surgical resection plane plans.
				</Alert>
			)}

			{/* Dirty resection planes alert / actions */}
			{areResectionPlanesDirty && customDefaultsState === 'inactive' && (
				<Alert>
					<Stack spacing={2}>
						<span>You&rsquo;ve made changes to the resection planes.</span>

						<Stack direction="row" spacing={2}>
							<Button
								color="danger"
								onClick={() => {
									location.reload();
								}}
								size="sm"
								variant="outlined"
							>
								Revert Changes
							</Button>
							<Button onClick={onSaveChanges} size="sm">
								Save Changes
							</Button>
						</Stack>
					</Stack>
				</Alert>
			)}

			{/* Pair */}
			<Stack spacing={2}>
				<Typography level="title-md">Pair</Typography>

				<ToggleButtonGroup<ResectionPlanePairKey>
					onChange={handleSelectedPairChange}
					size="sm"
					value={selectedPairKey}
				>
					{Object.keys(scan.resectionPlanes)
						.sort(alphaSort())
						.map((pair) => (
							<Button key={pair} value={pair}>
								{capitalCase(pair)}
							</Button>
						))}
				</ToggleButtonGroup>
			</Stack>

			{/* Plane */}
			<Stack spacing={1}>
				<Typography level="title-md">Plane</Typography>

				<ToggleButtonGroup<ResectionPlaneKey>
					onChange={handleSelectedPlaneChange}
					size="sm"
					value={selectedPlaneKey}
				>
					{Object.keys(scan.resectionPlanes[selectedPairKey])
						.sort(alphaSort())
						.map((plane) => (
							<Button key={plane} value={plane}>
								{capitalCase(plane)}
							</Button>
						))}
				</ToggleButtonGroup>
			</Stack>

			{resectionPlanesVisibility && (
				<>
					{/* Position */}
					<Stack spacing={2}>
						<Typography level="title-lg">Position</Typography>

						{positionInputs.map(({axis, label}) => (
							<Stack key={label} spacing={1}>
								<Typography level="title-md">{label}</Typography>

								{axis === 'z' ? (
									<Stack direction="column" spacing={2}>
										<Slider
											max={resectionPlanePosition.max}
											min={resectionPlanePosition.min}
											onChange={async (event, value) => {
												await updateResectionPlaneProperty({
													axis,
													pairKey: selectedPairKey,
													plane: selectedPlane,
													planeKey: selectedPlaneKey,
													previousValue: selectedPlaneAdjustment.position[axis],
													property: 'position',
													value,
													vtkState,
												});
											}}
											onChangeCommitted={async (event, value) => {
												await updateResectionPlaneProperty({
													axis,
													committed: true,
													pairKey: selectedPairKey,
													plane: selectedPlane,
													planeKey: selectedPlaneKey,
													previousValue: selectedPlaneAdjustment.position[axis],
													property: 'position',
													value,
													vtkState,
												});
											}}
											slotProps={{
												root: {
													'data-testid': `slider:${label.toLowerCase()}`,
												},
											}}
											track={false}
											value={selectedPlaneAdjustment.position[axis]}
										/>
										<Stack direction="row" spacing={2}>
											<FormControl sx={{minWidth: 0}}>
												<FormLabel htmlFor="medial">Medial</FormLabel>

												<NumberInput
													endDecorator="mm"
													formatOptions={{
														maximumFractionDigits: 2,
													}}
													id="medial"
													label={label}
													maxValue={resectionPlanePosition.max}
													minValue={resectionPlanePosition.min}
													onChange={async (value) => {
														await updateResectionPlaneProperty({
															axis,
															committed: true,
															pairKey: selectedPairKey,
															plane: selectedPlane,
															planeKey: selectedPlaneKey,
															previousValue:
																selectedPlaneAdjustment.position[axis],
															property: 'position',
															value,
															vtkState,
														});
													}}
													slotProps={{
														root: {
															'data-testid': `number-input:${label.toLowerCase()}`,
														},
													}}
													value={selectedPlaneAdjustment.position[axis]}
												/>
											</FormControl>

											<FormControl sx={{minWidth: 0}}>
												<FormLabel htmlFor="lateral">Lateral</FormLabel>

												<Input
													endDecorator="mm"
													id="lateral"
													readOnly
													value={(
														selectedPlaneAdjustment.position[axis] +
														calculateLateralDistance({
															landmarks,
															normal: selectedPlane.normal,
															plane: selectedPlaneKey,
														})
													).toFixed(2)}
												/>
											</FormControl>
										</Stack>
									</Stack>
								) : (
									<SliderAndNumberInput
										// TODO: Figure out why disabling `NumberInput` breaks this
										// disabled={state === 'updating'}
										endDecorator="mm"
										formatOptions={{
											maximumFractionDigits: 0,
										}}
										label={label}
										max={resectionPlanePosition.max}
										min={resectionPlanePosition.min}
										onNumberInputChange={async (value) => {
											await updateResectionPlaneProperty({
												axis,
												committed: true,
												pairKey: selectedPairKey,
												plane: selectedPlane,
												planeKey: selectedPlaneKey,
												previousValue: selectedPlaneAdjustment.position[axis],
												property: 'position',
												value,
												vtkState,
											});
										}}
										onSliderChange={async (value) => {
											await updateResectionPlaneProperty({
												axis,
												pairKey: selectedPairKey,
												plane: selectedPlane,
												planeKey: selectedPlaneKey,
												previousValue: selectedPlaneAdjustment.position[axis],
												property: 'position',
												value,
												vtkState,
											});
										}}
										onSliderChangeCommitted={async (value) => {
											await updateResectionPlaneProperty({
												axis,
												committed: true,
												pairKey: selectedPairKey,
												plane: selectedPlane,
												planeKey: selectedPlaneKey,
												previousValue: selectedPlaneAdjustment.position[axis],
												property: 'position',
												value,
												vtkState,
											});
										}}
										track={false}
										value={selectedPlaneAdjustment.position[axis]}
									/>
								)}
							</Stack>
						))}
					</Stack>

					{/* Rotation */}
					{is.nonEmptyArray(rotationInputs) && (
						<Stack spacing={2}>
							<Typography level="title-lg">Rotation</Typography>

							{rotationInputs.map(({axis, label}) => (
								<Stack key={label} spacing={1}>
									<Typography level="title-md">{label}</Typography>

									<SliderAndNumberInput
										// TODO: Figure out why disabling `NumberInput` breaks this
										// disabled={state === 'updating'}
										endDecorator="&deg;"
										formatOptions={{
											maximumFractionDigits: 2,
										}}
										label={label}
										max={resectionPlaneRotation.max}
										min={resectionPlaneRotation.min}
										onNumberInputChange={async (value) => {
											await updateResectionPlaneProperty({
												axis,
												committed: true,
												pairKey: selectedPairKey,
												plane: selectedPlane,
												planeKey: selectedPlaneKey,
												previousValue: selectedPlaneAdjustment.rotation[axis],
												property: 'rotation',
												value,
												vtkState,
											});
										}}
										onSliderChange={async (value) => {
											await updateResectionPlaneProperty({
												axis,
												pairKey: selectedPairKey,
												plane: selectedPlane,
												planeKey: selectedPlaneKey,
												previousValue: selectedPlaneAdjustment.rotation[axis],
												property: 'rotation',
												value,
												vtkState,
											});
										}}
										onSliderChangeCommitted={async (value) => {
											await updateResectionPlaneProperty({
												axis,
												committed: true,
												pairKey: selectedPairKey,
												plane: selectedPlane,
												planeKey: selectedPlaneKey,
												previousValue: selectedPlaneAdjustment.rotation[axis],
												property: 'rotation',
												value,
												vtkState,
											});
										}}
										track={false}
										value={selectedPlaneAdjustment.rotation[axis]}
									/>
								</Stack>
							))}
						</Stack>
					)}
				</>
			)}
		</Stack>
	);
}
