import {getEnabledElement} from '@cornerstonejs/core';
import {ProbeTool, annotation, utilities} from '@cornerstonejs/tools';
import {noCase} from 'change-case';

import {
	areElementsDefined as areCornerstoneElementsDefined,
	elements as cornerstoneElements,
} from '@/library/cornerstone';
import {type Landmark} from '@/types/cornerstone';

const {addAnnotation} = annotation.state;
const {triggerAnnotationRenderForViewportIds} = utilities;

export default function addLandmarksToCornerstone3dToolState(
	landmarks: Landmark[],
) {
	// NOTE -> For the POC so we don't have to rewrite all of the logic for
	// how planar annotations work for the landmark tool (can only be viewed in one direction),
	// duplicate the toolstate for each view.

	if (!areCornerstoneElementsDefined()) {
		throw new Error('Invalid cornerstone elements');
	}

	const {
		axial: axialElement,
		sagittal: sagittalElement,
		coronal: coronalElement,
	} = cornerstoneElements;

	if (!axialElement || !sagittalElement || !coronalElement) {
		throw new Error('One or more Cornerstone elements are missing');
	}

	const axialEnabledElement = getEnabledElement(axialElement);
	const sagittalEnabledElement = getEnabledElement(sagittalElement);
	const coronalEnabledElement = getEnabledElement(coronalElement);

	if (
		!axialEnabledElement ||
		!sagittalEnabledElement ||
		!coronalEnabledElement
	) {
		throw new Error('Could not find enabled element');
	}

	const axialCamera = axialEnabledElement.viewport.getCamera();
	const sagittalCamera = sagittalEnabledElement.viewport.getCamera();
	const coronalCamera = coronalEnabledElement.viewport.getCamera();

	const axialViewPlaneNormal = axialCamera.viewPlaneNormal;
	const axialViewPlaneUp = axialCamera.viewUp;
	const sagittalViewPlaneNormal = sagittalCamera.viewPlaneNormal;
	const sagittalViewPlaneUp = sagittalCamera.viewUp;
	const coronalViewPlaneNormal = coronalCamera.viewPlaneNormal;
	const coronalViewPlaneUp = coronalCamera.viewUp;

	const viewportIds = [
		axialEnabledElement.viewport.id,
		sagittalEnabledElement.viewport.id,
		coronalEnabledElement.viewport.id,
	];
	const {renderingEngine} = axialEnabledElement;
	/* eslint-disable-next-line @typescript-eslint/naming-convention --
	 * we don't have control over this
	 */
	const {FrameOfReferenceUID} = axialEnabledElement;

	landmarks.forEach((landmark) => {
		const axialAnnotation = {
			invalidated: true,
			highlighted: false,
			metadata: {
				viewPlaneNormal: axialViewPlaneNormal as [number, number, number],
				viewUp: axialViewPlaneUp as [number, number, number],
				/* eslint-disable-next-line @typescript-eslint/naming-convention --
				 * we don't have control over this
				 */
				FrameOfReferenceUID,
				toolName: ProbeTool.toolName,
			},
			data: {
				label: noCase(landmark.id),
				handles: {points: [landmark.center as [number, number, number]]},
				cachedStats: {},
			},
		};
		const sagittalAnnotation = {
			invalidated: true,
			highlighted: false,
			metadata: {
				viewPlaneNormal: sagittalViewPlaneNormal as [number, number, number],
				viewUp: sagittalViewPlaneUp as [number, number, number],
				/* eslint-disable-next-line @typescript-eslint/naming-convention --
				 * we don't have control over this
				 */
				FrameOfReferenceUID,
				toolName: ProbeTool.toolName,
			},
			data: {
				label: noCase(landmark.id),
				handles: {points: [landmark.center as [number, number, number]]},
				cachedStats: {},
			},
		};
		const coronalAnnotation = {
			invalidated: true,
			highlighted: false,
			metadata: {
				viewPlaneNormal: coronalViewPlaneNormal as [number, number, number],
				viewUp: coronalViewPlaneUp as [number, number, number],
				/* eslint-disable-next-line @typescript-eslint/naming-convention --
				 * we don't have control over this
				 */
				FrameOfReferenceUID,
				toolName: ProbeTool.toolName,
			},
			data: {
				label: noCase(landmark.id),
				handles: {points: [landmark.center as [number, number, number]]},
				cachedStats: {},
			},
		};

		addAnnotation(axialElement, axialAnnotation);
		addAnnotation(sagittalElement, sagittalAnnotation);
		addAnnotation(coronalElement, coronalAnnotation);
	});

	triggerAnnotationRenderForViewportIds(renderingEngine, viewportIds);
}
