import {CircularProgress, Stack, type StackProps, Typography} from '@mui/joy';
import React from 'react';

export type Props = StackProps & {
	direction?: 'row' | 'column';
	label: string;
	size: 'sm' | 'md' | 'lg';
};

export default function Loading({direction, label, size, sx, ...props}: Props) {
	if (!direction) {
		direction = size === 'sm' ? 'row' : 'column';
	}

	return (
		<Stack
			data-testid="loading"
			direction={direction}
			spacing={1}
			sx={{
				alignItems: 'center',
				...sx,
			}}
			{...props}
		>
			<CircularProgress size={size} />
			<Typography level={`body-${size === 'lg' ? 'lg' : 'md'}`}>
				{label}
			</Typography>
		</Stack>
	);
}
