import {type Coordinate} from './3d';

export type ResectionPlane = {
	normal: Coordinate;
	origin: Coordinate;
	zOffsetInitial: number;
};

type ResectionPlanePair = Record<ResectionPlaneKey, ResectionPlane>;

export type ResectionPlanes = Record<ResectionPlanePairKey, ResectionPlanePair>;

export type ResectionPlaneState = {
	position: {
		x: number;
		y: number;
		z: number;
	};
	rotation: {
		x: number;
		y: number;
		z: number;
	};
};

export type ResectionPlanesState = Record<
	ResectionPlanePairKey,
	Record<ResectionPlaneKey, ResectionPlaneState>
> &
	Record<'createdAt', Date>;

export const resectionPlanePairKeys = [
	'anatomical',
	'mechanical',
	'kinematic',
] as const;

export type ResectionPlanePairKey = (typeof resectionPlanePairKeys)[number];

export const resectionPlaneKeys = ['femoral', 'tibial'] as const;
export type ResectionPlaneKey = (typeof resectionPlaneKeys)[number];
export type ResectionPlaneProperty = 'position' | 'rotation';
