import {type Scan} from '@/library/models';
import {type VtkStateRef} from '@/types';

import {
	useSetDensityMap,
	useSetResectionPlanes,
	useSetTool,
	useSetVisibility,
} from './hooks';

export default function useSetCustomDefaults({
	scan,
	vtkState,
}: {
	scan: Scan;
	vtkState: VtkStateRef;
}) {
	useSetDensityMap({vtkState});
	useSetResectionPlanes({scan, vtkState});
	useSetTool({scan});
	useSetVisibility({vtkState});
}
