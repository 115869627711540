import {cache} from '@cornerstonejs/core';
import {Enums, segmentation} from '@cornerstonejs/tools';

class SegmentationService {
	getActiveSegmentationVolume(toolGroupId: string) {
		const activeSegmentationRepresentation =
			segmentation.activeSegmentation.getActiveSegmentationRepresentation(
				toolGroupId,
			);
		if (!activeSegmentationRepresentation) {
			return;
		}

		const {segmentationId, type} = activeSegmentationRepresentation;
		const segmentationInstance =
			segmentation.state.getSegmentation(segmentationId);

		if (!segmentationInstance) {
			throw new Error(
				`No segmentation instance found for segmentationId: ${segmentationId}`,
			);
		}

		const {representationData} = segmentationInstance;
		// Check if the representationData is of type LabelmapSegmentationData
		if (
			type === Enums.SegmentationRepresentations.Labelmap &&
			'volumeId' in representationData
		) {
			const {volumeId} = representationData;
			return cache.getVolume(volumeId as string);
		}

		throw new Error(`Unsupported segmentation representation type: ${type}`);
	}

	getBufferSegmentation(
		segmentationData: Uint8Array | Float32Array | undefined,
	) {
		if (!segmentationData) {
			return;
		}

		if (segmentationData instanceof Uint8Array) {
			return segmentationData.buffer;
		}

		if (segmentationData instanceof Float32Array) {
			return Uint8Array.from(segmentationData).buffer;
		}
	}

	getSegmentationFromBuffer(segmentationBuffer: ArrayBuffer) {
		const segmentationVolume = new Uint8Array(segmentationBuffer);
		let data: Uint8Array | Float32Array;
		if (segmentationVolume instanceof Float32Array) {
			data = Float32Array.from(segmentationVolume);
		} else {
			data = segmentationVolume;
		}

		return data;
	}
}

export const instance = new SegmentationService();
