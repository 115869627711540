import {
	Alert,
	Button,
	Divider,
	Dropdown,
	FormControl,
	FormHelperText,
	FormLabel,
	IconButton,
	Input,
	List,
	ListItem,
	ListItemButton,
	ListItemDecorator,
	Menu,
	MenuButton,
	MenuItem,
	Option,
	Select,
	type SelectProps,
	Stack,
	ToggleButtonGroup,
	type ToggleButtonGroupOwnProps,
	Tooltip,
	Typography,
} from '@mui/joy';
import {capitalCase} from 'change-case';
import delay from 'delay';
import {
	BoxIcon,
	CylinderIcon,
	MagnetIcon,
	Move3dIcon,
	MoveDiagonalIcon,
	MoveDownIcon,
	MoveDownLeftIcon,
	MoveHorizontalIcon,
	MoveLeftIcon,
	MoveRightIcon,
	MoveUpIcon,
	MoveUpRightIcon,
	MoveVerticalIcon,
	PenSquareIcon,
	PlusIcon,
	Rotate3dIcon,
	RulerIcon,
	Trash2Icon,
} from 'lucide-react';
import React, {type ReactNode} from 'react';

import {
	SliderAndNumberInput,
	type SliderAndNumberInputProps,
} from '@/components';
import {
	ResectionRemainingIcon,
	ResectionRemovedIcon,
	SphereIcon,
} from '@/icons';
import {
	createDigitalTwin,
	deleteDigitalTwin,
	hidePointsInPointCloud,
	updateSelectedDigitalTwin,
	updateDigitalTwinAffectedBone,
	updateDigitalTwinDepth,
	updateDigitalTwinHeight,
	updateDigitalTwinMode,
	updateDigitalTwinOpacity,
	updateDigitalTwinPosition,
	updateDigitalTwinRadius,
	updateDigitalTwinRotation,
	updateDigitalTwinWidth,
} from '@/library';
import {type Scan} from '@/library/models';
import {useGlobalState} from '@/state';
import {
	type Axis,
	type Bone,
	bones,
	type DepthDirection,
	type DigitalTwinMode,
	type DigitalTwinPolygonData,
	digitalTwinModes,
	type HeightDirection,
	isDrillData,
	isReamData,
	isResectData,
	type VtkStateRef,
	type WidthDirection,
} from '@/types';

import DirectionToggleButtonGroup from './DirectionToggleButtonGroup';

type Props = {
	areDigitalTwinsDirty: boolean;
	onEditDigitalTwins: () => void;
	onSave: () => void;
	scan: Scan;
	vtkState: VtkStateRef;
};

export default function DigitalTwins({
	areDigitalTwinsDirty,
	onEditDigitalTwins,
	onSave,
	scan,
	vtkState,
}: Props) {
	const {
		digitalTwins: {
			digitalTwins,
			mode,
			selectedDigitalTwinId,
			setDigitalTwin,
			setDigitalTwins,
			setMode,
			setSelectedDigitalTwinId,
			setState,
			state,
		},
		resectionPlanes,
		scan: {landmarks},
		viewports: {volumeViewport},
		visibility,
	} = useGlobalState();

	const selectedDigitalTwin = digitalTwins.find(
		(digitalTwin) => digitalTwin.id === selectedDigitalTwinId,
	);

	async function applyDigitalTwins() {
		if (!selectedDigitalTwin) return;

		setState('updating');

		await delay(1);

		hidePointsInPointCloud({
			areResectionPlanesVisible: resectionPlanes.visibility,
			areDigitalTwinsVisible: visibility.digitalTwins,
			bone: selectedDigitalTwin.bone,
			vtkState,
		});

		volumeViewport?.render();

		deEmphasizeSelectedDigitalTwin();

		onEditDigitalTwins();

		setState('ready');
	}

	const handleChangeSelectedDigitalTwin = async (id: string) => {
		if (id === selectedDigitalTwinId) return;

		setState('updating');

		await delay(1);

		setSelectedDigitalTwinId(id);

		updateSelectedDigitalTwin({
			areDigitalTwinsVisible: visibility.digitalTwins,
			areResectionPlanesVisible: resectionPlanes.visibility,
			id,
			vtkState,
		});

		volumeViewport?.render();

		setState('ready');
	};

	const handleDeleteDigitalTwin = async (id: string) => {
		setState('updating');

		await delay(1);

		const digitalTwin = digitalTwins.find((twin) => twin.id === id);

		setDigitalTwins(digitalTwins.filter((twin) => twin.id !== id));

		if (id === selectedDigitalTwinId) {
			await handleChangeSelectedDigitalTwin(digitalTwins[0]?.id);
		}

		if (digitalTwin?.bone) {
			deleteDigitalTwin({
				areDigitalTwinsVisible: visibility.digitalTwins,
				areResectionPlanesVisible: resectionPlanes.visibility,
				bone: digitalTwin.bone,
				id: digitalTwin.id,
				vtkState,
				renderer: volumeViewport?.getRenderer(),
			});

			if (digitalTwins.length === 1) {
				setMode('remaining');

				updateDigitalTwinMode({
					areDigitalTwinsVisible: visibility.digitalTwins,
					areResectionPlanesVisible: resectionPlanes.visibility,
					mode: 'remaining',
					vtkState,
				});
			}
		}

		volumeViewport?.render();

		onEditDigitalTwins();

		setState('ready');
	};

	const handleChangeMode: ToggleButtonGroupOwnProps<DigitalTwinMode>['onChange'] =
		async (event, mode) => {
			if (!mode || !selectedDigitalTwin) return;

			setState('updating');

			await delay(1);

			setMode(mode);

			updateDigitalTwinMode({
				areDigitalTwinsVisible: visibility.digitalTwins,
				areResectionPlanesVisible: resectionPlanes.visibility,
				mode,
				vtkState,
			});

			volumeViewport?.render();

			setState('ready');
		};

	const handleClickAddResect = async () => {
		setState('updating');

		await delay(1);

		const newResect = createDigitalTwin({
			areDigitalTwinsVisible: visibility.digitalTwins,
			areResectionPlanesVisible: resectionPlanes.visibility,
			vtkState,
			renderer: volumeViewport?.getRenderer(),
			type: 'resect',
		});

		setDigitalTwins([...digitalTwins, newResect]);
		setSelectedDigitalTwinId(newResect.id);

		volumeViewport?.render();

		onEditDigitalTwins();

		setState('ready');
	};

	const handleClickAddDrill = async () => {
		setState('updating');

		await delay(1);

		const newDrill = createDigitalTwin({
			areDigitalTwinsVisible: visibility.digitalTwins,
			areResectionPlanesVisible: resectionPlanes.visibility,
			vtkState,
			renderer: volumeViewport?.getRenderer(),
			type: 'drill',
		});

		setDigitalTwins([...digitalTwins, newDrill]);
		setSelectedDigitalTwinId(newDrill.id);

		volumeViewport?.render();

		onEditDigitalTwins();

		setState('ready');
	};

	const handleClickAddReam = async () => {
		setState('updating');

		await delay(1);

		const newReam = createDigitalTwin({
			areDigitalTwinsVisible: visibility.digitalTwins,
			areResectionPlanesVisible: resectionPlanes.visibility,
			vtkState,
			renderer: volumeViewport?.getRenderer(),
			type: 'ream',
		});

		setDigitalTwins([...digitalTwins, newReam]);
		setSelectedDigitalTwinId(newReam.id);

		volumeViewport?.render();

		onEditDigitalTwins();

		setState('ready');
	};

	const handleChangeLabel: React.ChangeEventHandler<HTMLInputElement> = ({
		target: {value: newLabel},
	}) => {
		if (!selectedDigitalTwin?.id) return;

		setDigitalTwins(
			digitalTwins.map((digitalTwin) => {
				if (digitalTwin.id === selectedDigitalTwin.id) {
					return {...digitalTwin, label: newLabel};
				}

				return digitalTwin;
			}),
		);
	};

	const handleChangeAffectedBone: SelectProps<Bone, false>['onChange'] = async (
		event,
		newBone,
	) => {
		if (!newBone || !selectedDigitalTwin) return;

		const oldBone = selectedDigitalTwin?.bone;

		setState('updating');

		await delay(1);

		setDigitalTwins(
			digitalTwins.map((digitalTwin) => {
				if (digitalTwin.id === selectedDigitalTwin.id) {
					return {...digitalTwin, bone: newBone};
				}

				return digitalTwin;
			}),
		);

		updateDigitalTwinAffectedBone({
			areDigitalTwinsVisible: visibility.digitalTwins,
			areResectionPlanesVisible: resectionPlanes.visibility,
			id: selectedDigitalTwin.id,
			newBone,
			oldBone,
			vtkState,
		});

		volumeViewport?.render();

		setState('ready');
	};

	const handleChangeDepth: (parameters?: {
		committed?: boolean;
	}) => (depth: number) => void =
		({committed} = {}) =>
		async (depth) => {
			if (
				Number.isNaN(depth) ||
				!selectedDigitalTwin ||
				!isResectData(selectedDigitalTwin)
			)
				return;

			emphasizeSelectedDigitalTwin();

			setDigitalTwin({
				...selectedDigitalTwin,
				depth,
				depthDirection: selectedDigitalTwin.depthDirection,
			});

			updateDigitalTwinDepth({
				extensionDirection: selectedDigitalTwin.depthDirection,
				id: selectedDigitalTwin.id,
				depth,
				vtkState,
			});

			volumeViewport?.render();

			if (committed) await applyDigitalTwins();
		};

	const handleChangeHeight: (parameters?: {
		committed?: boolean;
	}) => (height: number) => void =
		({committed} = {}) =>
		async (height) => {
			if (
				Number.isNaN(height) ||
				!selectedDigitalTwin ||
				!(isDrillData(selectedDigitalTwin) || isResectData(selectedDigitalTwin))
			)
				return;

			emphasizeSelectedDigitalTwin();

			setDigitalTwin({
				...selectedDigitalTwin,
				height,
				heightDirection: selectedDigitalTwin.heightDirection,
			});

			updateDigitalTwinHeight({
				extensionDirection: selectedDigitalTwin.heightDirection,
				id: selectedDigitalTwin.id,
				height,
				vtkState,
			});

			volumeViewport?.render();

			if (committed) await applyDigitalTwins();
		};

	const handleChangeOpacity: SliderAndNumberInputProps['onChange'] = (
		opacity,
	) => {
		if (Number.isNaN(opacity) || !selectedDigitalTwin) return;

		opacity /= 100;

		setDigitalTwin({
			...selectedDigitalTwin,
			opacity,
		});

		updateDigitalTwinOpacity({id: selectedDigitalTwin.id, opacity, vtkState});

		volumeViewport?.render();
	};

	const handleChangeRadius: (parameters?: {
		committed?: boolean;
	}) => (radius: number) => void =
		({committed} = {}) =>
		async (radius) => {
			if (
				Number.isNaN(radius) ||
				!selectedDigitalTwin ||
				!(isDrillData(selectedDigitalTwin) || isReamData(selectedDigitalTwin))
			)
				return;

			emphasizeSelectedDigitalTwin();

			setDigitalTwin({
				...selectedDigitalTwin,
				radius,
			});

			updateDigitalTwinRadius({id: selectedDigitalTwin.id, radius, vtkState});

			volumeViewport?.render();

			if (committed) await applyDigitalTwins();
		};

	const handleChangeRotation: (parameters: {
		axis: Axis;
		committed?: boolean;
	}) => SliderAndNumberInputProps['onChange'] =
		({axis, committed}) =>
		async (angle) => {
			if (
				Number.isNaN(angle) ||
				!selectedDigitalTwin ||
				!(isDrillData(selectedDigitalTwin) || isResectData(selectedDigitalTwin))
			) {
				return;
			}

			emphasizeSelectedDigitalTwin();

			const rotation = {
				...selectedDigitalTwin.rotation,
				[axis]: angle,
			};

			setDigitalTwin({
				...selectedDigitalTwin,
				rotation,
			});

			updateDigitalTwinRotation({
				id: selectedDigitalTwin.id,
				rotation,
				vtkState,
			});

			volumeViewport?.render();

			if (committed) await applyDigitalTwins();
		};

	const handleChangeWidth: (parameters?: {
		committed?: boolean;
	}) => (width: number) => void =
		({committed} = {}) =>
		async (width) => {
			if (
				Number.isNaN(width) ||
				!selectedDigitalTwin ||
				!isResectData(selectedDigitalTwin)
			)
				return;

			emphasizeSelectedDigitalTwin();

			setDigitalTwin({
				...selectedDigitalTwin,
				width,
				widthDirection: selectedDigitalTwin.widthDirection,
			});

			updateDigitalTwinWidth({
				extensionDirection: selectedDigitalTwin.widthDirection,
				id: selectedDigitalTwin.id,
				width,
				vtkState,
			});

			volumeViewport?.render();

			if (committed) await applyDigitalTwins();
		};

	const handleChangePosition: (parameters: {
		axis: Axis;
		committed?: boolean;
	}) => SliderAndNumberInputProps['onChange'] =
		({axis, committed}) =>
		async (value) => {
			if (Number.isNaN(value) || !selectedDigitalTwin) return;

			emphasizeSelectedDigitalTwin();

			const position = {...selectedDigitalTwin.position, [axis]: value};

			setDigitalTwin({
				...selectedDigitalTwin,
				position,
			});

			updateDigitalTwinPosition({
				id: selectedDigitalTwin.id,
				position,
				vtkState,
			});

			volumeViewport?.render();

			if (committed) await applyDigitalTwins();
		};

	const handleSelectLandmark: (parameters: {
		id: string;
	}) => React.MouseEventHandler<HTMLDivElement> =
		({id}) =>
		async () => {
			if (!selectedDigitalTwin?.bone) return;

			const landmark = landmarks.primary.find((landmark) => landmark.id === id);

			if (!landmark) return;

			setState('updating');

			await delay(1);

			const position = {
				x: Math.round(landmark.center[0]),
				y: Math.round(landmark.center[1]),
				z: Math.round(landmark.center[2]),
			};

			setDigitalTwin({
				...selectedDigitalTwin,
				position,
			});

			updateDigitalTwinPosition({
				id: selectedDigitalTwin.id,
				position,
				vtkState,
			});

			hidePointsInPointCloud({
				areDigitalTwinsVisible: visibility.digitalTwins,
				areResectionPlanesVisible: resectionPlanes.visibility,
				bone: selectedDigitalTwin?.bone,
				vtkState,
			});

			volumeViewport?.render();

			onEditDigitalTwins();

			setState('ready');
		};

	function deEmphasizeSelectedDigitalTwin() {
		if (!selectedDigitalTwin) return;

		updateDigitalTwinOpacity({
			id: selectedDigitalTwin.id,
			opacity: selectedDigitalTwin.opacity,
			vtkState,
		});
	}

	function emphasizeSelectedDigitalTwin() {
		if (!selectedDigitalTwin) return;

		let delta = 0.35;
		const tooOpaque = selectedDigitalTwin.opacity + delta > 1;
		if (tooOpaque) delta = -delta; // Decrease instead of increase

		updateDigitalTwinOpacity({
			id: selectedDigitalTwin.id,
			opacity: selectedDigitalTwin.opacity + delta,
			vtkState,
		});

		volumeViewport?.render();
	}

	const dimensionsInputs: Array<{
		type: 'height' | 'width' | 'depth' | 'radius';
		label: string;
		max: number;
		min: number;
		onChange: (value: number) => void;
		onChangeCommitted: (value: number) => void;
		value: number;
		directionOptions?: Array<{
			value: HeightDirection | WidthDirection | DepthDirection;
			icon: ReactNode;
		}>;
		selectedDirection?: HeightDirection | WidthDirection | DepthDirection;
		onHeightDirectionChange?: (newDirection: HeightDirection) => void;
		onWidthDirectionChange?: (newDirection: WidthDirection) => void;
		onDepthDirectionChange?: (newDirection: DepthDirection) => void;
	}> = [];

	if (selectedDigitalTwin) {
		if (isDrillData(selectedDigitalTwin)) {
			dimensionsInputs.push({
				directionOptions: [
					{value: 'down', icon: <MoveDownIcon />},
					{value: 'up', icon: <MoveUpIcon />},
					{value: 'upAndDown', icon: <MoveVerticalIcon />},
				],
				label: 'Height',
				max: 75,
				min: 1,
				onChange: handleChangeHeight(),
				onChangeCommitted: handleChangeHeight({committed: true}),
				onHeightDirectionChange(direction) {
					setDigitalTwin({
						...selectedDigitalTwin,
						heightDirection: direction,
					});
				},
				selectedDirection: selectedDigitalTwin.heightDirection,
				type: 'height',
				value: selectedDigitalTwin.height,
			});

			dimensionsInputs.push({
				type: 'radius',
				label: 'Radius',
				max: 50,
				min: 1,
				onChange: handleChangeRadius(),
				onChangeCommitted: handleChangeRadius({committed: true}),
				value: selectedDigitalTwin.radius,
			});
		}

		if (isResectData(selectedDigitalTwin)) {
			dimensionsInputs.push({
				directionOptions: [
					{value: 'down', icon: <MoveDownIcon />},
					{value: 'up', icon: <MoveUpIcon />},
					{value: 'upAndDown', icon: <MoveVerticalIcon />},
				],
				label: 'S/I',
				max: 75,
				min: 1,
				onChange: handleChangeHeight(),
				onChangeCommitted: handleChangeHeight({committed: true}),
				onHeightDirectionChange(direction) {
					setDigitalTwin({
						...selectedDigitalTwin,
						heightDirection: direction,
					});
				},
				selectedDirection: selectedDigitalTwin.heightDirection,
				type: 'height',
				value: selectedDigitalTwin.height,
			});

			dimensionsInputs.push({
				directionOptions: [
					{value: 'left', icon: <MoveLeftIcon />},
					{value: 'right', icon: <MoveRightIcon />},
					{value: 'leftAndRight', icon: <MoveHorizontalIcon />},
				],
				label: 'M/L',
				max: 100,
				min: 1,
				onChange: handleChangeWidth(),
				onChangeCommitted: handleChangeWidth({committed: true}),
				onWidthDirectionChange(direction) {
					setDigitalTwin({
						...selectedDigitalTwin,
						widthDirection: direction,
					});
				},
				selectedDirection: selectedDigitalTwin.widthDirection,
				type: 'width',
				value: selectedDigitalTwin.width,
			});

			dimensionsInputs.push({
				directionOptions: [
					{value: 'front', icon: <MoveDownLeftIcon />},
					{value: 'back', icon: <MoveUpRightIcon />},
					{value: 'frontAndBack', icon: <MoveDiagonalIcon />},
				],
				label: 'A/P',
				max: 100,
				min: 1,
				onChange: handleChangeDepth(),
				onChangeCommitted: handleChangeDepth({committed: true}),
				onDepthDirectionChange(direction) {
					setDigitalTwin({...selectedDigitalTwin, depthDirection: direction});
				},
				selectedDirection: selectedDigitalTwin.depthDirection,
				type: 'depth',
				value: selectedDigitalTwin.depth,
			});
		}

		if (isReamData(selectedDigitalTwin)) {
			dimensionsInputs.push({
				type: 'radius',
				label: 'Radius',
				max: 50,
				min: 1,
				onChange: handleChangeRadius(),
				onChangeCommitted: handleChangeRadius({committed: true}),
				value: selectedDigitalTwin.radius,
			});
		}
	}

	const positionInputs: Array<{
		axis: Axis;
		label: string;
		max: number;
		min: number;
	}> = [
		{
			axis: 'x',
			label: 'M/L',
			max: Math.floor(vtkState.current.bounds?.[1] ?? 0),
			min: Math.ceil(vtkState.current.bounds?.[0] ?? 0),
		},
		{
			axis: 'y',
			label: 'A/P',
			max: Math.floor(vtkState.current.bounds?.[3] ?? 0),
			min: Math.ceil(vtkState.current.bounds?.[2] ?? 0),
		},
		{
			axis: 'z',
			label: 'S/I',
			max: Math.floor(vtkState.current.bounds?.[5] ?? 0),
			min: Math.ceil(vtkState.current.bounds?.[4] ?? 0),
		},
	];

	const rotationInputs: Array<{axis: 'x' | 'y' | 'z'; label: string}> = [
		{
			axis: 'x',
			label: 'Ext/Flex',
		},
		{
			axis: 'y',
			label: 'Var/Val',
		},
		{
			axis: 'z',
			label: 'Int/Ext',
		},
	];

	const handleRevertDigitalTwinChanges = async () => {
		setState('updating');

		await delay(1);

		digitalTwins.forEach((digitalTwin) => {
			deleteDigitalTwin({
				areDigitalTwinsVisible: visibility.digitalTwins,
				areResectionPlanesVisible: resectionPlanes.visibility,
				bone: digitalTwin.bone,
				id: digitalTwin.id,
				vtkState,
				renderer: volumeViewport?.getRenderer(),
			});
		});

		setDigitalTwins([]);

		const newDigitalTwins: DigitalTwinPolygonData[] = [];

		scan.digitalTwins.forEach(async (digitalTwin) => {
			const newDigitalTwin = createDigitalTwin({
				areDigitalTwinsVisible: visibility.digitalTwins,
				areResectionPlanesVisible: resectionPlanes.visibility,
				position: digitalTwin.position,
				vtkState,
				renderer: volumeViewport?.getRenderer(),
				type: digitalTwin.type,
			});

			newDigitalTwins.push(newDigitalTwin);

			setSelectedDigitalTwinId(newDigitalTwin.id);
		});

		setDigitalTwins(newDigitalTwins);

		volumeViewport?.render();

		setState('ready');
	};

	return (
		<Stack spacing={4}>
			{/* Dirty digital twins alert / actions */}
			{areDigitalTwinsDirty && (
				<Alert>
					<Stack spacing={2}>
						<span>You&rsquo;ve made changes to the Digital Twins.</span>

						<Stack direction="row" spacing={2}>
							<Button
								color="danger"
								onClick={async () => {
									await handleRevertDigitalTwinChanges();
								}}
								size="sm"
								variant="outlined"
							>
								Revert Changes
							</Button>
							<Button onClick={onSave} size="sm">
								Save Changes
							</Button>
						</Stack>
					</Stack>
				</Alert>
			)}
			{/* Mode */}
			<Stack spacing={1}>
				<Typography level="title-md">Mode</Typography>

				<ToggleButtonGroup<DigitalTwinMode>
					disabled={digitalTwins.length < 1 || state !== 'ready'}
					onChange={handleChangeMode}
					value={mode}
				>
					{digitalTwinModes.map((mode) => {
						let icon: ReactNode;

						if (mode === 'remaining') {
							icon = <ResectionRemainingIcon />;
						} else if (mode === 'removed') {
							icon = <ResectionRemovedIcon />;
						}

						return (
							<Button key={mode} startDecorator={icon} value={mode}>
								{capitalCase(mode)}
							</Button>
						);
					})}
				</ToggleButtonGroup>
			</Stack>

			{/* Digital twins object list */}
			<Stack spacing={1}>
				<Typography level="title-md">Digital Twins</Typography>

				<List>
					{digitalTwins.map((digitalTwin) => {
						const selected = digitalTwin.id === selectedDigitalTwinId;

						return (
							<ListItem key={digitalTwin.id}>
								<ListItemButton
									onClick={async () =>
										handleChangeSelectedDigitalTwin(digitalTwin.id)
									}
									selected={selected}
								>
									<ListItemDecorator>
										<PenSquareIcon />
									</ListItemDecorator>

									<Stack
										direction="row"
										flexGrow={1}
										alignItems="center"
										justifyContent="space-between"
									>
										{digitalTwin.label}
										<Tooltip color="primary" title="Delete Digital Twin">
											<IconButton
												onClick={async (event) => {
													event.stopPropagation();
													await handleDeleteDigitalTwin(digitalTwin.id);
												}}
											>
												<Trash2Icon />
											</IconButton>
										</Tooltip>
									</Stack>
								</ListItemButton>
							</ListItem>
						);
					})}

					{/* Add digital twin */}
					<ListItem>
						<Dropdown>
							<MenuButton slots={{root: ListItemButton}}>
								<ListItemDecorator>
									<PlusIcon />
								</ListItemDecorator>
								Add Digital Twin
							</MenuButton>

							<Menu placement="bottom-start">
								<MenuItem onClick={handleClickAddReam}>
									<ListItemDecorator>
										<SphereIcon />
									</ListItemDecorator>
									Add Ream
								</MenuItem>
								<MenuItem onClick={handleClickAddDrill}>
									<ListItemDecorator>
										<CylinderIcon />
									</ListItemDecorator>
									Add Drill
								</MenuItem>
								<MenuItem onClick={handleClickAddResect}>
									<ListItemDecorator>
										<BoxIcon />
									</ListItemDecorator>
									Add Resect
								</MenuItem>
							</Menu>
						</Dropdown>
					</ListItem>
				</List>
			</Stack>

			{/* Selected digital twin settings */}
			{selectedDigitalTwin && vtkState.current.bounds && landmarks && (
				<Stack spacing={4}>
					{/* Name */}
					<FormControl>
						<FormLabel>Name</FormLabel>
						<Input
							onChange={handleChangeLabel}
							value={selectedDigitalTwin.label}
						/>
					</FormControl>

					{/* Bone */}
					<FormControl>
						<FormLabel>Bone</FormLabel>

						<Select<Bone>
							onChange={handleChangeAffectedBone}
							value={selectedDigitalTwin.bone}
						>
							{bones.map((bone) => (
								<Option key={bone} value={bone}>
									{capitalCase(bone)}
								</Option>
							))}
						</Select>

						<FormHelperText>
							Bone which the digital twin should apply to
						</FormHelperText>
					</FormControl>

					{/* Snap to landmark */}
					<FormControl>
						<Dropdown>
							<MenuButton>
								<MagnetIcon /> &nbsp; Snap to landmark
							</MenuButton>

							<Menu>
								{landmarks.primary
									.filter(({id}) => id.includes(selectedDigitalTwin.bone))
									.map((landmark) => (
										<MenuItem
											key={landmark.id}
											onClick={handleSelectLandmark({id: landmark.id})}
										>
											{capitalCase(landmark.id)}
										</MenuItem>
									))}
							</Menu>

							<FormHelperText>
								Snap digital twin position to landmark
							</FormHelperText>
						</Dropdown>
					</FormControl>

					<Divider />

					{/* Positions */}
					<Typography level="title-md" startDecorator={<Move3dIcon />}>
						Positions
					</Typography>

					{positionInputs.map(({axis, label, max, min}) => (
						<Stack key={axis} spacing={1}>
							<Typography level="title-sm">{label}</Typography>

							<SliderAndNumberInput
								endDecorator="mm"
								formatOptions={{
									maximumFractionDigits: 0,
								}}
								label={label}
								max={max}
								min={min}
								onNumberInputChange={handleChangePosition({
									axis,
									committed: true,
								})}
								onSliderChange={handleChangePosition({axis})}
								onSliderChangeCommitted={handleChangePosition({
									axis,
									committed: true,
								})}
								testId={`position-${label}`}
								track={false}
								value={selectedDigitalTwin.position[axis]}
							/>
						</Stack>
					))}

					{/* Rotations */}
					{(selectedDigitalTwin.type === 'drill' ||
						selectedDigitalTwin.type === 'resect') && (
						<>
							<Divider />

							<Typography level="title-md" startDecorator={<Rotate3dIcon />}>
								Rotations
							</Typography>

							{rotationInputs.map(({axis, label}) => (
								<Stack key={axis} spacing={1}>
									<Typography level="title-sm">{label}</Typography>

									<SliderAndNumberInput
										endDecorator="&deg;"
										formatOptions={{
											maximumFractionDigits: 0,
										}}
										label={label}
										max={90}
										min={-90}
										onNumberInputChange={handleChangeRotation({
											axis,
											committed: true,
										})}
										onSliderChange={handleChangeRotation({axis})}
										onSliderChangeCommitted={handleChangeRotation({
											axis,
											committed: true,
										})}
										testId={`rotation-${label}`}
										track={false}
										value={selectedDigitalTwin.rotation[axis]}
									/>
								</Stack>
							))}
						</>
					)}

					<Divider />

					{/* Dimensions */}
					<Typography level="title-md" startDecorator={<RulerIcon />}>
						Dimensions
					</Typography>

					{dimensionsInputs.map((input) => (
						<Stack key={input.label} spacing={1}>
							<Typography level="title-sm">{input.label}</Typography>
							{input.directionOptions && (
								<DirectionToggleButtonGroup
									disabled={!selectedDigitalTwin}
									options={input.directionOptions}
									selectedDirection={input.selectedDirection!}
									onHeightDirectionChange={input.onHeightDirectionChange}
									onWidthDirectionChange={input.onWidthDirectionChange}
									onDepthDirectionChange={input.onDepthDirectionChange}
								/>
							)}
							<SliderAndNumberInput
								endDecorator="mm"
								formatOptions={{maximumFractionDigits: 0}}
								label={input.label}
								max={input.max}
								min={input.min}
								onNumberInputChange={input.onChangeCommitted}
								onSliderChange={input.onChange}
								onSliderChangeCommitted={input.onChangeCommitted}
								testId={`dimension-${input.label}`}
								value={input.value}
							/>
						</Stack>
					))}

					<Divider />

					{/* Opacity */}
					<Stack spacing={1}>
						<Typography level="title-sm">Opacity</Typography>

						<SliderAndNumberInput
							endDecorator="%"
							formatOptions={{
								maximumFractionDigits: 0,
							}}
							label="Opacity"
							max={100}
							min={0}
							onChange={handleChangeOpacity}
							value={selectedDigitalTwin.opacity * 100}
						/>
					</Stack>
				</Stack>
			)}
		</Stack>
	);
}
