import {BrushTool} from '@cornerstonejs/tools';

import {eraseInsideCircle} from './strategies/eraseCircle';

export default class BrushEraseTool extends BrushTool {
	static toolName = 'BrushEraseTool';
	constructor(
		toolProps = {},
		defaultToolProps = {
			supportedInteractionTypes: ['Mouse', 'Touch'],
			configuration: {
				strategies: {
					/* eslint-disable-next-line @typescript-eslint/naming-convention --
					 * we don't have control over naming here
					 */
					ERASE_INSIDE: eraseInsideCircle,
				},
				defaultStrategy: 'ERASE_INSIDE',
				activeStrategy: 'ERASE_INSIDE',
				brushSize: 5,
			},
		},
	) {
		super(toolProps, defaultToolProps);
	}
}
