import {List, ListItem, Stack} from '@mui/joy';
import React from 'react';

import {Checkbox} from '@/components';
import {useCustomDefaultsState} from '@/hooks';
import {type Scan} from '@/library/models';
import {type VtkStateRef} from '@/types';

import {LoadingListItem} from './components';
import {
	useDigitalTwins,
	useLandmarks,
	useMeshes,
	usePointClouds,
	useResectionPlanes,
	useSegmentationOverlays,
} from './hooks';

type Props = {
	scan: Scan;
	vtkState: VtkStateRef;
};

export default function Visibility({scan, vtkState}: Readonly<Props>) {
	const customDefaultsState = useCustomDefaultsState();
	const digitalTwins = useDigitalTwins({vtkState});
	const landmarks = useLandmarks({vtkState});
	const meshes = useMeshes({
		vtkState,
	});
	const pointClouds = usePointClouds({vtkState});
	const resectionPlanes = useResectionPlanes({vtkState});
	const segmentationOverlays = useSegmentationOverlays();

	return (
		<Stack spacing={2}>
			<List sx={{'--List-nestedInsetStart': '2rem'}}>
				{scan.hasReachedMilestone('kneeSegmented') && (
					<>
						{/* Segmentation overlays */}
						<ListItem>
							<Checkbox
								checked={segmentationOverlays.visibility}
								label="Segmentation Overlays"
								onChange={segmentationOverlays.handleVisibilityChange}
							/>
						</ListItem>

						{/* All meshes */}
						<ListItem>
							<Checkbox
								checked={meshes.all.visibility === 'visible'}
								disabled={!meshes.loaded}
								indeterminate={meshes.all.visibility === 'mixed'}
								label="Meshes"
								onChange={({target: {checked: visibility}}) => {
									meshes.all.setVisibility(visibility);
								}}
							/>
						</ListItem>

						{/* Individual meshes */}
						<ListItem nested>
							<List>
								{meshes.individual.length > 0 ? (
									meshes.individual.map(({color, id, visibility, labels}) => (
										<ListItem key={id}>
											<Checkbox
												customColor={color}
												label={labels.humanReadable}
												checked={visibility}
												onChange={({target: {checked: visibility}}) => {
													meshes.setVisibility({id, visibility});
												}}
											/>
										</ListItem>
									))
								) : (
									<LoadingListItem />
								)}
							</List>
						</ListItem>

						{/* All point clouds */}
						<ListItem>
							<Checkbox
								checked={pointClouds.all.isVisible}
								disabled={
									!pointClouds.areLoaded || customDefaultsState !== 'inactive'
								}
								indeterminate={pointClouds.all.isIndeterminate}
								label="Point Clouds"
								onChange={pointClouds.all.handleVisibilityChange}
							/>
						</ListItem>

						{/*	Individual point clouds */}
						{customDefaultsState === 'inactive' && (
							<ListItem nested>
								<List>
									{pointClouds.individual.length > 0 ? (
										pointClouds.individual.map(
											({handleVisibilityChange, id, isVisible, label}) => (
												<ListItem key={id}>
													<Checkbox
														checked={isVisible}
														label={label}
														onChange={handleVisibilityChange}
													/>
												</ListItem>
											),
										)
									) : (
										<LoadingListItem />
									)}
								</List>
							</ListItem>
						)}
					</>
				)}

				{scan.hasReachedMilestone('landmarked') && (
					<>
						{/*	Landmarks */}
						<ListItem>
							<Checkbox
								checked={landmarks.visibility}
								label="Landmarks"
								onChange={landmarks.handleVisibilityChange}
							/>
						</ListItem>

						{/*	Resection planes */}
						<ListItem>
							<Checkbox
								checked={resectionPlanes.visibility}
								disabled={!resectionPlanes.ready}
								label="Resection Planes"
								onChange={resectionPlanes.handleVisibilityChange}
							/>
						</ListItem>

						{/* Digital Twins */}
						{digitalTwins.exist && (
							<ListItem>
								<Checkbox
									checked={digitalTwins.visibility}
									disabled={digitalTwins.disabled}
									label="Digital Twins"
									onChange={digitalTwins.handleVisibilityChange}
								/>
							</ListItem>
						)}
					</>
				)}
			</List>
		</Stack>
	);
}
