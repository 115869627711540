import {
	utilities as csUtils,
	triggerEvent,
	eventTarget,
} from '@cornerstonejs/core';
import {utilities as csToolsUtils, Enums} from '@cornerstonejs/tools';
import {vec3} from 'gl-matrix';

const {transformWorldToIndex} = csUtils || {};
const {pointInShapeCallback, boundingBox, math} = csToolsUtils;

const {getBoundingBoxAroundShape} = boundingBox;
const {pointInEllipse, getCanvasEllipseCorners} = math.ellipse || {};
/* eslint-disable-next-line @typescript-eslint/naming-convention --
 * we don't have control over this
 */
const {Events} = Enums;

type OperationData = {
	volume: any;
	points: any[];
	segmentsLocked: any[];
	segmentIndex: number;
	segmentationId: string;
};

function triggerSegmentationDataModified(
	segmentationId: string,
	modifiedSlicesToUse: any[],
) {
	const eventDetail = {
		segmentationId,
		modifiedSlicesToUse,
	};

	triggerEvent(eventTarget, Events.SEGMENTATION_DATA_MODIFIED, eventDetail);
}

function eraseCircle(
	enabledElement: any,
	operationData: OperationData,
	inside = true,
) {
	const {
		volume: segmentationVolume,
		points,
		segmentsLocked,
		segmentIndex,
		segmentationId,
	} = operationData;
	const {imageData, dimensions, scalarData} = segmentationVolume;
	const {viewport} = enabledElement;

	// Average the points to get the center of the ellipse
	const center = vec3.fromValues(0, 0, 0);
	points.forEach((point) => {
		vec3.add(center, center, point);
	});
	vec3.scale(center, center, 1 / points.length);

	const canvasCoordinates = points.map((p: any) => viewport.worldToCanvas(p));

	// 1. From the drawn tool: Get the ellipse (circle) topLeft and bottomRight
	// corners in canvas coordinates
	const [topLeftCanvas, bottomRightCanvas] = getCanvasEllipseCorners(
		canvasCoordinates as any,
	);

	// 2. Find the extent of the ellipse (circle) in IJK index space of the image
	const topLeftWorld = viewport.canvasToWorld(topLeftCanvas);
	const bottomRightWorld = viewport.canvasToWorld(bottomRightCanvas);

	const ellipsoidCornersIjk = [
		transformWorldToIndex(imageData, topLeftWorld),
		transformWorldToIndex(imageData, bottomRightWorld),
	];

	const boundsIjk = getBoundingBoxAroundShape(ellipsoidCornersIjk, dimensions);

	if (boundsIjk.every(([min, max]) => min !== max)) {
		throw new Error('Oblique segmentation tools are not supported yet');
	}

	// Using circle as a form of ellipse
	const ellipseObj = {
		center,
		xRadius: Math.abs(topLeftWorld[0] - bottomRightWorld[0]) / 2,
		yRadius: Math.abs(topLeftWorld[1] - bottomRightWorld[1]) / 2,
		zRadius: Math.abs(topLeftWorld[2] - bottomRightWorld[2]) / 2,
	};
	const modifiedSlicesToUse = new Set();

	const callback = ({value, index, pointIJK}: any) => {
		// Do not erase locked segment
		if (segmentsLocked.includes(value)) {
			return;
		}

		// Do not erase non related segment
		if (scalarData[index] !== segmentIndex) {
			return;
		}

		scalarData[index] = 0;
		// Todo: I don't think this will always be index 2 in streamingImageVolume?
		modifiedSlicesToUse.add(pointIJK[2]);
	};

	pointInShapeCallback(
		imageData,
		(pointLPS: any, pointIJK: any) =>
			pointInEllipse(ellipseObj as any, pointLPS),
		callback,
		boundsIjk,
	);

	triggerSegmentationDataModified(segmentationId, [...modifiedSlicesToUse]);
}

export function eraseInsideCircle(
	enabledElement: any,
	operationData: OperationData,
) {
	eraseCircle(enabledElement, operationData, true);
}

export function eraseOutsideCircle(
	enabledElement: any,
	operationData: OperationData,
) {
	eraseCircle(enabledElement, operationData, false);
}
