import {Button, IconButton, Input, type InputProps} from '@mui/joy';
import {MinusIcon, PlusIcon} from 'lucide-react';
import React, {useRef, useState} from 'react';
import {
	type AriaNumberFieldProps,
	useButton,
	useLocale,
	useNumberField,
} from 'react-aria';
import {useNumberFieldState} from 'react-stately';

export type Props = AriaNumberFieldProps & Omit<InputProps, 'onChange'>;

/**
 * @todo Replace this with official
 *       [Joy UI `NumberInput`]{@link https://github.com/mui/material-ui/issues/38948}
 *       once it's released
 */
export default function NumberInput({
	endDecorator,
	formatOptions,
	disabled,
	id,
	label,
	maxValue,
	minValue,
	onChange,
	onInput,
	onKeyUp,
	slotProps,
	startDecorator,
	value,
	...joyInputProps
}: Props) {
	const ariaNumberFieldProps: AriaNumberFieldProps = {
		formatOptions,
		id,
		isDisabled: disabled,
		label,
		maxValue,
		minValue,
		onChange,
		onInput,
		onKeyUp,
		value,
	};

	const [dirty, setDirty] = useState(false);

	const handleChange: AriaNumberFieldProps['onChange'] = (value) => {
		setDirty(false);
		ariaNumberFieldProps?.onChange?.(value);
	};

	const handleInput: AriaNumberFieldProps['onInput'] = (event) => {
		setDirty(true);
		ariaNumberFieldProps?.onInput?.(event);
	};

	const handleKeyUp: AriaNumberFieldProps['onKeyUp'] = (event) => {
		if (event.key === 'Enter') setDirty(false);

		ariaNumberFieldProps?.onKeyUp?.(event);
	};

	const {locale} = useLocale();
	const numberFieldState = useNumberFieldState({
		...ariaNumberFieldProps,
		onChange: handleChange,
		locale,
	});

	const inputRef = useRef<HTMLInputElement>(null);
	const ariaNumberField = useNumberField(
		{
			...ariaNumberFieldProps,
			onChange: handleChange,
			onInput: handleInput,
			onKeyUp: handleKeyUp,
		},
		numberFieldState,
		inputRef,
	);

	const decrementButtonRef = useRef(null);
	const {buttonProps: decrementButtonProps} = useButton(
		ariaNumberField.decrementButtonProps,
		decrementButtonRef,
	);

	const incrementButtonRef = useRef(null);
	const {buttonProps: incrementButtonProps} = useButton(
		ariaNumberField.incrementButtonProps,
		incrementButtonRef,
	);

	return (
		<Input
			endDecorator={
				<>
					{endDecorator}
					{dirty ? (
						// This button works by blurring the input, which triggers a change
						<Button disabled={disabled}>Apply</Button>
					) : (
						<IconButton
							// @ts-expect-error -- Not sure why this is a problem
							color="neutral"
							ref={incrementButtonRef}
							variant="soft"
							{...incrementButtonProps}
						>
							<PlusIcon />
						</IconButton>
					)}
				</>
			}
			slotProps={{
				endDecorator: {
					sx: {gap: 1},
				},
				input: {
					...ariaNumberField.inputProps,
					ref: inputRef,
				},
				...slotProps,
			}}
			startDecorator={
				<>
					{!dirty && (
						<IconButton
							// @ts-expect-error -- Not sure why this is a problem
							color="neutral"
							ref={decrementButtonRef}
							variant="soft"
							{...decrementButtonProps}
						>
							<MinusIcon />
						</IconButton>
					)}
					{startDecorator}
				</>
			}
			{...joyInputProps}
		/>
	);
}
