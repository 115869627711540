import {type Scan} from '@/library/models';
import {type VtkStateRef} from '@/types';

import {useSetPosition} from './hooks';

export default function useSetAdjustments({
	scan,
	vtkState,
}: {
	scan: Scan;
	vtkState: VtkStateRef;
}) {
	useSetPosition({scan, vtkState});
}
