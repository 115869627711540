import {Box, CircularProgress, Sheet, Stack} from '@mui/joy';
import React, {type CSSProperties, useRef, useState} from 'react';

import {
	useFetchScanData,
	useLoadAnnotations,
	useLoadDigitalTwins,
	useLoadLandmarks,
	useLoadResectionPlanes,
	useLoadMasks,
	useLoadMeshes,
	useLoadPointClouds,
	useViewportLoadingProgress,
} from '@/hooks';
import {isDefined} from '@/library';
import {
	elements as cornerstoneElements,
	viewportElementKeys,
	type ViewportElementKey,
} from '@/library/cornerstone';
import {type Scan} from '@/library/models';
import {useGlobalState} from '@/state';
import {type VtkState} from '@/types';

import {
	ScreenshotButton,
	SidePanel,
	SnapCameraDropdown,
	SurgicalPlanOverlay,
	ViewportLoadingSpinner,
} from './components';
import {useInitializeViewport, useSetCustomDefaults} from './hooks';

function Viewer({scan}: {scan: Scan}) {
	const {
		densityMap,
		digitalTwins,
		meshes,
		resectionPlanes: resectionPlanesState,
		tools: {
			lasso: {enabled: isLassoActive},
		},
		viewports: {volumeViewport},
		visibility,
	} = useGlobalState();
	const [are2dViewportsLoaded, setAre2dViewportsLoaded] = useState(false);
	const [isVolumeViewportLoaded, setIsVolumeViewportLoaded] = useState(false);

	const {error: fetchScanDataError} = useFetchScanData({scan});

	const surgicalPlanRef = useRef<HTMLDivElement>(null);

	const {dicomImageLoadingProgress, volumeId} = useInitializeViewport({
		scan,
	});

	const vtkState = useRef<VtkState>({
		bounds: undefined,
		digitalTwinActors: [],
		digitalTwinMode: 'remaining',
		landmarkActors: [],
		meshActors: [],
		pointCloudActors: [],
		pointCloudActorsBackup: [],
		resectionPlaneActors: [],
		selectedResectionPlanePair: 'anatomical',
	});

	useSetCustomDefaults({scan, vtkState});

	const {isLoaded: areMasksLoaded} = useLoadMasks({
		scan,
		volumeId,
	});

	useLoadMeshes({
		scan,
		vtkState,
	});

	useLoadPointClouds({
		scan,
		vtkState,
	});

	useLoadResectionPlanes({
		arePointCloudsLoaded: densityMap.state === 'ready',
		scan,
		vtkState,
	});

	const [maximizedViewport, setMaximizedViewport] = useState<
		ViewportElementKey | undefined
	>(undefined);

	const {isLoaded: areLandmarksLoaded} = useLoadLandmarks({
		arePointCloudsLoaded: densityMap.state === 'ready',
		scan,
		vtkState,
	});

	useLoadAnnotations({
		scan,
	});

	useLoadDigitalTwins({
		arePointCloudsLoaded: densityMap.state === 'ready',
		scan,
		vtkState,
	});

	const viewportLoadingProgress = useViewportLoadingProgress({
		areLandmarksLoaded,
		areMasksLoaded,
		densityMapState: densityMap.state,
		dicomImageLoadingProgress,
		digitalTwinsState: digitalTwins.state,
		resectionPlanesState: resectionPlanesState.state,
		scan,
	});

	function handleViewportDoubleClick(viewport: ViewportElementKey) {
		const isViewportAlreadyMaximized = viewport === maximizedViewport;

		setMaximizedViewport(isViewportAlreadyMaximized ? undefined : viewport);
	}

	function handleViewportClick({
		event,
		viewport,
	}: {
		event: React.MouseEvent;
		viewport: ViewportElementKey;
	}) {
		const isDoubleClick = event.detail === 2;

		if (isDoubleClick) handleViewportDoubleClick(viewport);
	}

	return (
		<Stack
			direction="row"
			sx={{
				backgroundColor: 'primary.700',
				height: '100%',
				padding: 0.5,
				width: '100%',
			}}
		>
			{/* Container for the viewport grid */}
			<Box
				sx={{
					paddingRight: 0.25,
					position: 'relative',
					width: '75%',
					height: '100%',
				}}
			>
				{visibility.surgicalPlan && isDefined(scan.resectionPlanes) && (
					<SurgicalPlanOverlay
						componentRef={surgicalPlanRef}
						resectionPlanes={scan.resectionPlanes}
					/>
				)}

				{/* Viewport grid */}
				<Box
					sx={{
						display: 'grid',
						gap: 0.5,
						gridTemplateColumns: 'repeat(2, 1fr)',
						height: '100%',
					}}
				>
					{viewportElementKeys.map((key) => {
						const isThisViewportMaximized = maximizedViewport === key;
						const isOtherViewportMaximized =
							maximizedViewport && !isThisViewportMaximized;
						const isVolumeViewport = key === 'volume';
						const isVolumeViewportUpdating =
							isVolumeViewport &&
							((scan.hasReachedMilestone('kneeSegmented') &&
								densityMap.state === 'updating') ||
								(scan.hasReachedMilestone('landmarked') &&
									(resectionPlanesState.state === 'updating' ||
										digitalTwins.state === 'updating')));

						let cursor: CSSProperties['cursor'];
						let activeCursor: CSSProperties['cursor'];
						let isLoading = false;
						let loadingProgress = 0;
						let onComplete = () => {
							/* Do nothing */
						};

						if (isVolumeViewport) {
							if (isLassoActive) {
								cursor = 'url(/cursors/lasso.svg) 8 8, default';
							} else {
								cursor = 'grab';
								activeCursor = 'grabbing';
							}

							if (scan.hasReachedMilestone('kneeSegmented')) {
								loadingProgress = viewportLoadingProgress.volumeViewport;
								onComplete = () => {
									setIsVolumeViewportLoaded(true);
								};

								isLoading = !isVolumeViewportLoaded;
							}
						} else {
							loadingProgress = viewportLoadingProgress.twoDimensionalViewport;
							onComplete = () => {
								setAre2dViewportsLoaded(true);
							};

							isLoading = !are2dViewportsLoaded;
						}

						return (
							<Box
								data-testid={`viewport:${key}`}
								key={key}
								onClick={(event) => {
									handleViewportClick({event, viewport: key});
								}}
								onContextMenu={(event) => {
									event.preventDefault();
								}}
								ref={(ref: HTMLDivElement) => {
									cornerstoneElements[key] = ref;
								}}
								sx={{
									backgroundColor: 'neutral.900',
									borderRadius: 'sm',
									// `!important` is necessary because Cornerstone sets the
									// cursor via the `style` attribute
									cursor:
										cursor === undefined ? undefined : `${cursor} !important`,
									display: isOtherViewportMaximized ? 'none' : undefined,
									gridColumn: `span ${isThisViewportMaximized ? 2 : 1}`,
									gridRow: `span ${isThisViewportMaximized ? 2 : 1}`,
									overflow: 'hidden',
									position: 'relative',
									// eslint-disable-next-line @typescript-eslint/naming-convention
									'&:active': {
										cursor:
											activeCursor === undefined
												? undefined
												: `${activeCursor} !important`,
									},
								}}
							>
								<Box
									id={`viewport:${key}`}
									sx={{
										display: 'flex',
										flexDirection: 'column',
										gap: 1,
										left: 8,
										position: 'absolute',
										top: 8,
										zIndex: 1,
									}}
								>
									<ScreenshotButton viewportKey={key} />
									{isVolumeViewport && (
										<SnapCameraDropdown
											volumeViewport={volumeViewport}
											vtkState={vtkState}
										/>
									)}
								</Box>
								{(isLoading || isVolumeViewportUpdating) && (
									<ViewportLoadingSpinner
										onComplete={onComplete}
										targetProgress={
											isVolumeViewportUpdating ? undefined : loadingProgress
										}
									/>
								)}
							</Box>
						);
					})}
				</Box>
			</Box>

			{/* Side panel */}
			<Box
				sx={{
					paddingLeft: 0.25,
					width: '25%',
				}}
			>
				<Sheet
					sx={{
						borderRadius: 'sm',
						height: '100%',
						overflow: 'auto',
						padding: 2,
						position: 'relative',
					}}
				>
					{cornerstoneElements.volume === undefined ? (
						<Box
							sx={{
								alignItems: 'center',
								display: 'flex',
								height: '100%',
								left: 0,
								justifyContent: 'center',
								position: 'absolute',
								top: 0,
								width: '100%',
							}}
						>
							<CircularProgress />
						</Box>
					) : (
						<SidePanel
							scan={scan}
							surgicalPlanRef={surgicalPlanRef}
							unrecoverableError={fetchScanDataError ?? meshes.error}
							vtkState={vtkState}
						/>
					)}
				</Sheet>
			</Box>
		</Stack>
	);
}

export default Viewer;
