import React, {useEffect} from 'react';
import {
	type LoaderFunction,
	Navigate,
	Outlet,
	redirect,
	useLocation,
} from 'react-router-dom';

import {useFirebaseAuthState, useRedirectPath, useUser} from '@/hooks';
import {firebase} from '@/library';
import {useGlobalState} from '@/state';

// Check the authentication state in both the loader and the component because
// the loaders of nested routes are executed before <Private> is rendered, but
// after this loader is executed
export const loader: LoaderFunction = async () => {
	const currentUser = await firebase.auth.getCurrentUser();

	if (currentUser) {
		return null;
	}

	localStorage.setItem('redirectPath', JSON.stringify(location.pathname));

	return redirect('/log-in');
};

// https://stackoverflow.com/a/66289280/4411309
export default function Private() {
	const [firebaseAuthUser, isFirebaseAuthLoading, firebaseAuthError] =
		useFirebaseAuthState();
	const {
		user: {setUser: setGlobalUser},
	} = useGlobalState();
	const location = useLocation();
	const [, setRedirectPath] = useRedirectPath();
	const [firestoreUser, isFirestoreUserLoading, firestoreUserError] = useUser({
		id: firebaseAuthUser?.uid,
	});

	useEffect(() => {
		if (!firebaseAuthUser && !isFirebaseAuthLoading && !firebaseAuthError) {
			// Firebase Auth state check is complete, and the user isn't logged in
			// Set the redirect path for the user to return to after they've logged in
			setRedirectPath(location.pathname);
		}
	}, [firebaseAuthUser, isFirebaseAuthLoading, firebaseAuthError]);

	useEffect(() => {
		if (firestoreUser) setGlobalUser(firestoreUser);
	}, [firestoreUser]);

	if (isFirebaseAuthLoading || isFirestoreUserLoading) return null;
	if (firebaseAuthError) throw firebaseAuthError;
	if (firestoreUserError) throw firestoreUserError;
	if (firebaseAuthUser) return <Outlet />;

	return <Navigate to="/log-in" replace />;
}
