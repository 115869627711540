import React from 'react';

import {useGlobalState} from '@/state';

import LassoClass, {type Props as LassoClassProps} from './LassoClass';

export type Props = Omit<LassoClassProps, 'globalState'>;

/**
 * Function component wrapper for {@link LassoClass}
 *
 * This component exists because {@link LassoClass} is a class component, so it
 * can't use hooks
 *
 * @todo Move {@link LassoClass} class component code into this component, then
 *       delete {@link LassoClass}
 */
export default function Lasso(props: Readonly<Props>) {
	return <LassoClass globalState={useGlobalState()} {...props} />;
}
