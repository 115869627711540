import {type VolumeViewport3D} from '@cornerstonejs/core/dist/cjs/RenderingEngine';
import {vec3} from 'gl-matrix';

import {getCenterOfBounds, getMaxValueBy} from '@/library';
import {CameraView, type Bounds, type Vector3} from '@/types';

function isOpposite(vecA: Vector3, vecB: Vector3): boolean {
	if (vecA.length !== 3 || vecB.length !== 3) {
		throw new Error('cant tell if vectors are opposite');
	}

	const negateVecA = vec3.create();

	vec3.negate(negateVecA, vec3.fromValues(...vecA));

	return vec3.equals(negateVecA, vec3.fromValues(...vecB));
}

function getCameraCenteredProperties(
	viewPlaneNormal: number[],
	bounds: Bounds,
) {
	const centerOfBounds = getCenterOfBounds(bounds);
	const position: Vector3 = [0, 0, 0];
	const focalPoint = centerOfBounds;

	for (let index = 0; index < viewPlaneNormal.length; index++) {
		const valueVpn = viewPlaneNormal[index];

		// Position should be point of center
		if (valueVpn === 0) {
			position[index] = centerOfBounds[index];
		} else {
			position[index] = getMaxValueBy(bounds, index);
		}
	}

	return {
		focalPoint,
		position,
	};
}

/**
 * It sets the camera position based on the steps:
 *
 * - move the focal point of the camera to the center of bounding box
 * - set camera on isometric projection
 * @param volumeViewport
 * @param bounds
 * @returns
 */
export function setIsometricProjectionBy(
	volumeViewport: VolumeViewport3D,
	bounds: Bounds,
) {
	// @ts-expect-error -- `getVtkActiveCamera()` is a protected method
	const vtkActiveCamera = volumeViewport.getVtkActiveCamera();
	const viewAngle = vtkActiveCamera.getViewAngle();
	const viewPlaneNormal = vtkActiveCamera.getViewPlaneNormal() as Vector3;
	const directionOfProjection =
		vtkActiveCamera.getDirectionOfProjection() as Vector3;

	// Cut in case view angle is not 90 or it's a oblique projection.
	if (viewAngle !== 90 || !isOpposite(viewPlaneNormal, directionOfProjection)) {
		return;
	}

	// @ts-expect-error -- `getVtkActiveCamera()` is a protected method
	const camera = volumeViewport.getVtkActiveCamera();
	const cameraCenteredProperties = getCameraCenteredProperties(
		viewPlaneNormal,
		bounds,
	);

	// Set position and focal point of camera to be centered by bounds
	// scale the amount that will contain the volume on an isometric projection
	const cameraProperties = {
		...cameraCenteredProperties,
		parallelScale: camera.getParallelScale() * Math.sqrt(2), // 141% far due to the fact it will be isometric projection
	};
	volumeViewport.setCamera(cameraProperties);

	// Set isometric projection
	camera.roll(45);
	camera.elevation(-35.264);
}

export function snapCameraToView(
	volumeViewport: any,
	bounds: Bounds,
	view: CameraView,
) {
	const camera = volumeViewport.getVtkActiveCamera();

	if (!bounds) {
		throw new Error('No bounds found');
	}

	const center = [
		(bounds[0] + bounds[1]) / 2,
		(bounds[2] + bounds[3]) / 2,
		(bounds[4] + bounds[5]) / 2,
	];

	const distance = Math.sqrt(
		(bounds[1] - bounds[0]) ** 2 +
			(bounds[3] - bounds[2]) ** 2 +
			(bounds[5] - bounds[4]) ** 2,
	);

	let position;
	let focalPoint;
	let viewUp;

	switch (view) {
		case CameraView.AxialSuperior:
			position = [center[0], center[1], center[2] + distance];
			focalPoint = center;
			viewUp = [0, -1, 0];
			break;
		case CameraView.AxialInferior:
			position = [center[0], center[1], center[2] - distance];
			focalPoint = center;
			viewUp = [0, 1, 0];
			break;
		case CameraView.SagittalMedial:
			position = [center[0] - distance, center[1], center[2]];
			focalPoint = center;
			viewUp = [0, 0, 1];
			break;
		case CameraView.SagittalLateral:
			position = [center[0] + distance, center[1], center[2]];
			focalPoint = center;
			viewUp = [0, 0, 1];
			break;
		case CameraView.CoronalAnterior:
			position = [center[0], center[1] - distance, center[2]];
			focalPoint = center;
			viewUp = [0, 0, 1];
			break;
		case CameraView.CoronalPosterior:
			position = [center[0], center[1] + distance, center[2]];
			focalPoint = center;
			viewUp = [0, -1, 1];
			break;
		case CameraView.IsometricAnterolateral:
			position = [
				center[0] - distance,
				center[1] - distance,
				center[2] + distance,
			];
			focalPoint = center;
			viewUp = [0, 0, 1];
			break;
		case CameraView.IsometricAnteromedial:
			position = [
				center[0] + distance,
				center[1] - distance,
				center[2] + distance,
			];
			focalPoint = center;
			viewUp = [0, 0, 1];
			break;
		default:
			position = [
				center[0] + distance,
				center[1] + distance,
				center[2] + distance,
			];
			focalPoint = center;
			viewUp = [0, 0, 1];
			break;
	}

	camera.setPosition(...position);
	camera.setFocalPoint(...focalPoint);
	camera.setViewUp(...viewUp);

	const desiredZ = Math.max(...bounds.map(Math.abs)) * 5;
	camera.setClippingRange(desiredZ / 2, desiredZ);
	camera.setThicknessFromFocalPoint(desiredZ / 4);

	volumeViewport.render();
}
