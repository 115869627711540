import {useCallback, useEffect} from 'react';

import {cornerstone} from '@/library';
import {useGlobalState} from '@/state';

export default function useAnnotations() {
	const {
		annotations: {currentTool},
	} = useGlobalState();

	useEffect(() => {
		enableAnnotations();
	}, [currentTool]);

	const enableAnnotations = useCallback(() => {
		cornerstone.toolGroups.setAnnotationsEnabled(currentTool);
	}, [currentTool]);

	const disableAnnotations = useCallback(() => {
		cornerstone.toolGroups.setAnnotationsDisabled();
	}, []);

	return {enableAnnotations, disableAnnotations};
}
