import {
	addBounds as addBoundsVTK,
	getCenter as getCenterVTK,
	getLengths as getLengthsVTK,
	getMaxPoint as getMaxPointVTK,
} from '@kitware/vtk.js/Common/DataModel/BoundingBox';

import {type Bounds, type Vector3} from '../types/3d';

const getEquidistantBounds = (
	min: number,
	max: number,
	maxLength: number,
): [number, number] => {
	const currentLength = max - min;
	const hafDiff = (maxLength - currentLength) / 2;

	return [min - hafDiff, max + hafDiff];
};

/**
 * Returns a circumscribed bounds that contains both boundsA and boundsB.
 * @returns a bounding box cube that contains A and B.
 */
export function getCircumscribedBounds(
	boundsA: Bounds,
	boundsB: Bounds,
): Bounds {
	const result = addBoundsVTK(boundsA, ...boundsB);
	const lengths = getLengthsVTK(result);
	const maxLength = Math.max(...lengths);
	const equidistantBounds = [
		...getEquidistantBounds(result[0], result[1], maxLength),
		...getEquidistantBounds(result[2], result[3], maxLength),
		...getEquidistantBounds(result[4], result[5], maxLength),
	];

	return equidistantBounds as Bounds;
}

export function getLengthOfBounds(bounds: Bounds): Vector3 {
	return getLengthsVTK(bounds);
}

export function getCenterOfBounds(bounds: Bounds): Vector3 {
	return getCenterVTK(bounds);
}

export function getMaxValueBy(boundingBox: Bounds, index: number): number {
	const maxPoint = getMaxPointVTK(boundingBox);

	return maxPoint[index];
}

export function encapsulateBounds(bounds: Bounds[]): Bounds | undefined {
	if (bounds.length === 0) {
		return undefined; // No bounds to encapsulate
	}

	// Initialize the final bounds with the first actor's bounds
	const finalBounds = [...bounds[0]];

	bounds.forEach((bounds) => {
		// Ensure the bounds has the correct length
		if (bounds.length !== 6) {
			throw new Error('Invalid bounds length');
		}

		// Iterate over each pair (min-max) of the bounds
		for (let i = 0; i < 3; i++) {
			// Update min value (x, y, z)
			finalBounds[i * 2] = Math.min(finalBounds[i * 2], bounds[i * 2]);

			// Update max value (x, y, z)
			finalBounds[i * 2 + 1] = Math.max(
				finalBounds[i * 2 + 1],
				bounds[i * 2 + 1],
			);
		}
	});

	return finalBounds as Bounds;
}
