import delay from 'delay';

import {updateDigitalTwinsVisibility} from '@/library';
import {useGlobalState} from '@/state';
import {type CheckboxChangeHandler, type VtkStateRef} from '@/types';

export default function useDigitalTwinsVisibility({
	vtkState,
}: {
	vtkState: VtkStateRef;
}): {
	disabled: boolean;
	exist: boolean;
	handleVisibilityChange: CheckboxChangeHandler;
	visibility: boolean;
} {
	const {
		digitalTwins,
		resectionPlanes,
		viewports: {volumeViewport},
		visibility: globalVisibility,
	} = useGlobalState();

	const handleVisibilityChange: CheckboxChangeHandler = async ({
		target: {checked: visibility},
	}) => {
		digitalTwins.setState('updating');

		await delay(0);

		updateDigitalTwinsVisibility({
			areResectionPlanesVisible: resectionPlanes.visibility,
			isVisible: globalVisibility.digitalTwins,
			vtkState,
		});

		volumeViewport?.render();

		digitalTwins.setState('ready');
		globalVisibility.setVisibility('digitalTwins', visibility);
	};

	return {
		visibility: globalVisibility.digitalTwins,
		exist: vtkState.current.digitalTwinActors.length > 0,
		disabled: digitalTwins.state !== 'ready',
		handleVisibilityChange,
	};
}
