import {Chip as JoyChip, type ChipProps as JoyChipProps} from '@mui/joy';
import React from 'react';

import {StatusIcon} from '@/components';

export type Props = JoyChipProps & {loading?: boolean; showIcon?: boolean};

/**
 * Enhanced version of Joy UI's Chip with the following features:
 * - Automatically adds icon based on `color` or `loading`
 */
export default function Chip({loading = false, showIcon, ...props}: Props) {
	if (showIcon) {
		props.startDecorator = <StatusIcon color={props.color} loading={loading} />;
	}

	return <JoyChip {...props} />;
}
