import {create} from 'zustand';
import {immer} from 'zustand/middleware/immer';

import {
	type PointCloudColorScheme,
	getPointCloudColorScheme,
} from '@/library/vtk/point-cloud-color-schemes';
import {type PointCloud} from '@/types';

type Threshold = {
	lower: number;
	lut?: any;
	max: number;
	min: number;
	upper: number;
};

type State = {
	arePointCloudsDirty: boolean;
	colorScheme: PointCloudColorScheme;
	opacity: number;
	newPointCloudProgress?: number;
	pointClouds: PointCloud[];
	state: 'ready' | 'loading' | 'updating' | 'error';
	threshold: Threshold;
};

type Actions = {
	addPointCloud: (pointCloud: PointCloud) => void;
	deletePointCloud: (parameters: {id: string}) => void;
	setArePointCloudsDirty: (arePointCloudsDirty: boolean) => void;
	setColorScheme: (colorScheme: PointCloudColorScheme) => void;
	setNewPointCloudProgress: (progress: number | undefined) => void;
	setOpacity: (opacity: number) => void;
	setPointClouds: (pointClouds: PointCloud[]) => void;
	setState: (state: State['state']) => void;
	updatePointCloud: (
		properties: Required<Pick<PointCloud, 'id'>> &
			Partial<Omit<PointCloud, 'id'>>,
	) => void;
	updateThreshold: (properties: Partial<Threshold>) => void;
};

export const useDensityMapStore = create(
	immer<State & Actions>((set) => ({
		addPointCloud(pointCloud: PointCloud) {
			set((state) => {
				state.pointClouds.push(pointCloud);
			});
		},
		arePointCloudsDirty: false,
		colorScheme: getPointCloudColorScheme({id: 'nativeBone'}),
		deletePointCloud({id}) {
			set((state) => {
				const index = state.pointClouds.findIndex((cloud) => cloud.id === id);

				if (index < 0) {
					throw new Error(`Failed to find point cloud. [id=${id}]`);
				}

				state.pointClouds.splice(index, 1);
			});
		},
		opacity: 100,
		pointClouds: [],
		setArePointCloudsDirty(arePointCloudsDirty: boolean) {
			set((state) => {
				state.arePointCloudsDirty = arePointCloudsDirty;
			});
		},
		setColorScheme(colorScheme: PointCloudColorScheme) {
			set((state) => {
				state.colorScheme = colorScheme;
			});
		},
		setNewPointCloudProgress(progress) {
			set((state) => {
				state.newPointCloudProgress = progress;
			});
		},
		setOpacity(opacity: number) {
			set((state) => {
				state.opacity = opacity;
			});
		},
		setPointClouds(pointClouds) {
			set((state) => {
				state.pointClouds = pointClouds;
			});
		},
		setState(newState: State['state']) {
			set((state) => {
				state.state = newState;
			});
		},
		state: 'loading',
		threshold: {
			lower: 0,
			max: 100,
			min: 0,
			upper: 100,
		},
		updatePointCloud({id, ...properties}) {
			set((state) => {
				const index = state.pointClouds.findIndex((cloud) => cloud.id === id);

				state.pointClouds[index] = {
					...state.pointClouds[index],
					...properties,
				};
			});
		},
		updateThreshold(properties) {
			set((state) => {
				state.threshold = {
					...state.threshold,
					...properties,
				};
			});
		},
	})),
);
