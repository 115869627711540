import ky from 'ky';

import {getConfigPath} from '../api/urls';
import {firebase, type task} from './index';

const api = ky.create({
	prefixUrl: '/api',
	timeout: 30000,
});

export const config = {
	async get({
		scanId,
		userId,
		version,
	}: {
		scanId: string;
		userId: string;
		version: number;
	}) {
		const firebaseAuthIdToken = await firebase.auth.getIdToken();

		return api
			.get(getConfigPath({scanId, userId, version}), {
				headers: {
					firebaseAuthIdToken,
				},
			})
			.json();
	},
};

export const slack = {
	async post({webhook, message}: {webhook: string; message: string}) {
		const firebaseAuthIdToken = await firebase.auth.getIdToken();

		return api
			.post('slack/post', {
				headers: {
					firebaseAuthIdToken,
				},
				json: {
					webhook,
					message,
				},
			})
			.json();
	},
};

export const tasks = {
	async create({scanId, type}: {scanId: string; type: task.Type}) {
		const firebaseAuthIdToken = await firebase.auth.getIdToken();

		return api
			.post('tasks/create', {
				headers: {
					firebaseAuthIdToken,
				},
				json: {
					scanId,
					type,
				},
			})
			.json<{taskId: string}>();
	},
};
