import React, {type ReactNode} from 'react';
import {Button, ToggleButtonGroup} from '@mui/joy';
import {
	type DepthDirection,
	type HeightDirection,
	type WidthDirection,
} from '@/types';

const isHeightDirection = (direction: any): direction is HeightDirection =>
	['up', 'down', 'upAndDown'].includes(direction);

const isWidthDirection = (direction: any): direction is WidthDirection =>
	['left', 'right', 'leftAndRight'].includes(direction);

const isDepthDirection = (direction: any): direction is DepthDirection =>
	['front', 'back', 'frontAndBack'].includes(direction);

const DirectionToggleButtonGroup: React.FC<{
	disabled: boolean;
	options: Array<{
		value: HeightDirection | WidthDirection | DepthDirection;
		icon: ReactNode;
	}>;
	selectedDirection: HeightDirection | WidthDirection | DepthDirection;
	onHeightDirectionChange?: (newDirection: HeightDirection) => void;
	onWidthDirectionChange?: (newDirection: WidthDirection) => void;
	onDepthDirectionChange?: (newDirection: DepthDirection) => void;
}> = ({
	disabled,
	options,
	selectedDirection,
	onHeightDirectionChange,
	onWidthDirectionChange,
	onDepthDirectionChange,
}) => (
	<ToggleButtonGroup<HeightDirection | WidthDirection | DepthDirection>
		disabled={disabled}
		onChange={(event, newDirection) => {
			if (!newDirection) {
				return;
			}

			if (isHeightDirection(newDirection)) {
				onHeightDirectionChange?.(newDirection);
			} else if (isWidthDirection(newDirection)) {
				onWidthDirectionChange?.(newDirection);
			} else if (isDepthDirection(newDirection)) {
				onDepthDirectionChange?.(newDirection);
			}
		}}
		value={selectedDirection}
	>
		{options.map(({value, icon}) => (
			<Button key={value} value={value} name={value}>
				{icon}
			</Button>
		))}
	</ToggleButtonGroup>
);

export default DirectionToggleButtonGroup;
