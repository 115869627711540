import {volumeLoader} from '@cornerstonejs/core';
import {segmentation, Enums as csToolsEnums} from '@cornerstonejs/tools';

import {cornerstone} from '@/library';

import {instance as SegmentationService} from './SegmentationService';

export default async function loadAndEnableSegmentation(
	bonemaskBuffer: ArrayBuffer,
	volumeId: string,
	segmentationId: string,
): Promise<void> {
	const segmentationVolume = await volumeLoader.createAndCacheDerivedVolume(
		volumeId,
		{
			volumeId: segmentationId,
		},
	);

	const scalarData: Uint8Array | Float32Array =
		SegmentationService.getSegmentationFromBuffer(bonemaskBuffer);
	const segmentIdsSet = new Set<number>();
	scalarData.forEach((value: number) => {
		if (value) {
			segmentIdsSet.add(value);
		}
	});

	segmentationVolume.scalarData.set(scalarData);

	segmentation.addSegmentations([
		{
			segmentationId,
			representation: {
				type: csToolsEnums.SegmentationRepresentations.Labelmap,
				data: {
					volumeId: segmentationId,
				},
			},
		},
	]);

	await segmentation.addSegmentationRepresentations(
		cornerstone.toolGroups.ctViewport.id,
		[
			{
				segmentationId,
				type: csToolsEnums.SegmentationRepresentations.Labelmap,
			},
		],
	);
}
