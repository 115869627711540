import html2canvas from 'html2canvas';

import {
	elements as cornerstoneElements,
	type ViewportElementKey,
} from '@/library/cornerstone';

const useCaptureScreenshot = () => {
	const toggleVisibility = (
		element: HTMLElement,
		visibility: 'hidden' | 'visible',
	) => {
		element.style.visibility = visibility;
	};

	const captureScreenshot = async ({
		viewportKey,
	}: {
		viewportKey: ViewportElementKey;
	}): Promise<string | undefined> => {
		const elementId = `viewport:${viewportKey}`;

		const viewportElement = cornerstoneElements[viewportKey];
		if (!viewportElement) {
			throw new Error(`Viewport element with key ${viewportKey} not found`);
		}

		const elementToHide = document.getElementById(elementId);
		if (!elementToHide) {
			throw new Error(`Element with id ${elementId} not found`);
		}

		toggleVisibility(elementToHide, 'hidden');

		const canvas = await html2canvas(viewportElement, {
			onclone(document) {
				const clonedElementToHide = document.getElementById(elementId);

				if (!clonedElementToHide) {
					throw new Error(`Cloned element with id ${elementId} not found`);
				}

				toggleVisibility(clonedElementToHide, 'hidden');
			},
		});

		toggleVisibility(elementToHide, 'visible');

		return canvas.toDataURL();
	};

	return {captureScreenshot};
};

export default useCaptureScreenshot;
