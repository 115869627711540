import {List, ListItem, Typography} from '@mui/joy';
import is from '@sindresorhus/is';
import {capitalCase} from 'change-case';
import React from 'react';
import {type Entries} from 'type-fest';

import {isDefined, resectionPlaneAdjustmentLabels, settings} from '@/library';

export default function Diff() {
	const diff = settings.customDefault.getDiff();

	if (!isDefined(diff) || Object.keys(diff).length === 0) return;

	const densityMap = {
		show: isDefined(diff.densityMap) && !is.emptyObject(diff.densityMap),
	};

	const resectionPlanes = {
		show:
			isDefined(diff.resectionPlanes) && !is.emptyObject(diff.resectionPlanes),
	};

	const visibility = {
		show: isDefined(diff.visibility) && !is.emptyObject(diff.visibility),
		items: {
			hide: isDefined(diff.visibility)
				? Object.entries(diff.visibility)
						.filter(([, visibility]) => isDefined(visibility) && !visibility)
						.map(([layer]) => capitalCase(layer))
				: [],
			show: isDefined(diff.visibility)
				? Object.entries(diff.visibility)
						.filter(([, visibility]) => visibility)
						.map(([layer]) => capitalCase(layer))
				: [],
		},
	};

	return (
		<>
			<Typography level="title-md">Pending changes:</Typography>

			<List marker="disc">
				{/* Visibility */}
				{visibility.show && (
					<ListItem nested>
						<ListItem>Visibility:</ListItem>

						<List marker="disc">
							{visibility.items.show.length > 0 && (
								<ListItem>Show: {visibility.items.show.join(', ')}</ListItem>
							)}
							{visibility.items.hide.length > 0 && (
								<ListItem>Hide: {visibility.items.hide.join(', ')}</ListItem>
							)}
						</List>
					</ListItem>
				)}

				{/* Resection Planes */}
				{resectionPlanes.show && (
					<ListItem nested>
						<ListItem>Resection Planes:</ListItem>

						<List marker="disc">
							{isDefined(diff.resectionPlanes?.pair) && (
								<ListItem>
									Pair: {capitalCase(diff.resectionPlanes.pair)}
								</ListItem>
							)}

							{isDefined(diff.resectionPlanes?.plane) && (
								<ListItem>
									Plane: {capitalCase(diff.resectionPlanes.plane)}
								</ListItem>
							)}

							{isDefined(diff.resectionPlanes?.adjustments) && (
								<ListItem nested>
									<ListItem>Adjustments:</ListItem>

									<List marker="disc">
										{(
											Object.entries(
												diff.resectionPlanes.adjustments,
												// @ts-expect-error -- diff.resectionPlanes.adjustments
												//                     is defined
											) as Entries<typeof diff.resectionPlanes.adjustments>
										).map(
											([pair, pairAdjustments]) =>
												isDefined(pairAdjustments) && (
													<ListItem key={pair} nested>
														<ListItem>{capitalCase(pair)} pair:</ListItem>

														<List marker="disc">
															{(
																Object.entries(pairAdjustments) as Entries<
																	typeof pairAdjustments
																>
															).map(
																([plane, adjustments]) =>
																	isDefined(adjustments?.position?.z) && (
																		<ListItem key={plane}>
																			{capitalCase(plane)} plane:{' '}
																			{adjustments.position.z} mm{' '}
																			{
																				resectionPlaneAdjustmentLabels.position
																					.z.short
																			}
																		</ListItem>
																	),
															)}
														</List>
													</ListItem>
												),
										)}
									</List>
								</ListItem>
							)}
						</List>
					</ListItem>
				)}

				{/*	Density Map */}
				{densityMap.show && (
					<ListItem nested>
						<ListItem>Density Map:</ListItem>

						<List marker="disc">
							{isDefined(diff.densityMap?.colorScheme) && (
								<ListItem>
									Color Scheme: {capitalCase(diff.densityMap.colorScheme)}
								</ListItem>
							)}

							{isDefined(diff.densityMap?.opacity) && (
								<ListItem>Opacity: {diff.densityMap.opacity}%</ListItem>
							)}

							{isDefined(diff.densityMap?.threshold) && (
								<ListItem nested>
									<ListItem>Threshold</ListItem>

									<List marker="disc">
										{isDefined(diff.densityMap.threshold.lower) && (
											<ListItem>
												Minimum: {diff.densityMap.threshold.lower}
											</ListItem>
										)}

										{isDefined(diff.densityMap.threshold.upper) && (
											<ListItem>
												Maximum: {diff.densityMap.threshold.upper}
											</ListItem>
										)}
									</List>
								</ListItem>
							)}
						</List>
					</ListItem>
				)}
			</List>
		</>
	);
}
